import useBuyPopupStore from "../stores/buyPopupStore.js";
import useCartStore from "../stores/cartStore.js";
import { buy } from "../utils/cartUtils.js";
import CustomBlueButton from "./CustomBlueButton.jsx";
import useAuthStore from "../stores/userStore.js";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
function CartSummary({
  amount,
  subTotal,
  lvlDisc,
  refDiscount,
  cart,
  setIsVoucherPopupOpen,
  voucherValue,
  voucherPrice,
  voucherInfo,
  inputVoucher,
  openNotification,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const cardIds = cart.map((card) => card.id);
  const { key } = useAuthStore((state) => ({
    key: state.key,
  }));
  const buyCards = async () => {
    setIsLoading(true);
    const vaucherForBuy =
      voucherValue.value.length != 0 ? voucherValue.value : inputVoucher;
    const res = await buy(cardIds, vaucherForBuy, key);
    if (res.ok === true) {
      setIsLoading(false);
      if (location.pathname === "/cart") {
        cardIds.map((id, index) => useCartStore.getState().removeFromCart(id));
      }
      useAuthStore.getState().changeBalance(res.summary);
      // setIsOpened(false);
      navigate("/orders");
    } else {
      setIsLoading(false);
      openNotification("Purchase error", res.error);
    }
  };

  return (
    <div className="cartSummary">
      <div className="cartSummary_item cardsAmount">
        <span>{t("Purchase summary")}</span>
        <span>
          {amount} {t("cards")}
        </span>
      </div>

      <div className="cartSummary_item total">
        <span>{t("Total")}</span>

        <span>{subTotal.toFixed(2)} $</span>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <CustomBlueButton
          isLoading={isLoading}
          color={"blue"}
          label={t("Confirm purchase")}
          height={"35px"}
          borderRadius={"10px"}
          fontSize={"16px"}
          width={"100%"}
          onClick={() => {
            buyCards();
          }}
        />
      </div>
    </div>
  );
}

export default CartSummary;
