import axios from "axios";


import {
    API_URL
} from "../apiConf";


export const get_base_prices = async ({
    queryKey
}) => {
    const [key, id] = queryKey;
    try {
        const response = await axios.get(`${API_URL}/api/seller/base_price/?key=${key}&id=${id}`);
        console.log(response);
        return response.data
    } catch (error) {
        console.error("Wrong search:", error.response ? error.response.data : error);
        throw error;
    }
};

export const updatePrices = async (key, id, prices) => {
    try {
        let url = `${API_URL}/api/seller/base_price/?key=${key}&id=${id}`;
        const response = await axios.post(url, {
            prices
        });

        return response.data;
    } catch (error) {
        console.error("Update prices error:", error.response ? error.response.data : error);
        throw error;
    }
}