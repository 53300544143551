import React from "react";
import { RiArrowUpSFill, RiArrowDownSFill } from "react-icons/ri";

const SortString = ({ label, sortOrder, onSort, text }) => {
  const handleSort = () => {
    let nextSortOrder;
    if (sortOrder === null) {
      nextSortOrder = "asc";
    } else if (sortOrder === "asc") {
      nextSortOrder = "desc";
    } else {
      nextSortOrder = null;
    }

    onSort(label, nextSortOrder);
  };

  return (
    <div className={`sort ${sortOrder ? "active" : ""}`} onClick={handleSort}>
      <span>{text}</span>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <RiArrowUpSFill
          size={"20px"}
          //   style={{ display: sortOrder === "asc" ? "block" : "none" }}
          //   color={"#307bc4"}
          color={sortOrder === "asc" ? "#307bc4" : "#b0b0b0"}
        />
        <RiArrowDownSFill
          size={"20px"}
          //   style={{ display: sortOrder === "desc" ? "block" : "none" }}
          //   color={"#307bc4"}
          color={sortOrder === "desc" ? "#307bc4" : "#b0b0b0"}
        />
      </div>
    </div>
  );
};

export default SortString;
