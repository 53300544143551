import CustomTable from "../components/CustomTable/CustomTable.jsx";
// import preorderColumns from "../components/CustomTable/preorderColumns.jsx";
import cardsColumns from "../components/CustomTable/cardsColumns.jsx";
import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { getCards } from "../utils/baseInfoPageUtils.js";
import useAuthStore from "../stores/userStore.js";
import { useParams } from "react-router-dom";

// fetchUsersListInfo
function CardsTable() {
  let { id } = useParams();

  console.log("id", id);

  const { key } = useAuthStore((state) => ({ key: state.key }));
  const { data, isLoading: isLoading } = useQuery({
    queryKey: [key, id, "basecardinfo"],
    queryFn: getCards,
  });
  console.log(data);
  // console.log(data.card_list);
  return <CustomTable data={data?.card_list} columns={cardsColumns} />;
}

export default CardsTable;
