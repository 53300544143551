import React from "react";
import CustomBlueButton from "./CustomBlueButton";
import { Rate } from "antd";
import useAuthStore from "../stores/userStore.js";
import { fetchRatings } from "../utils/ordersUtils";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

function FullInfoRating({
  choosedCard,
  billRate,
  moneyBalRate,
  priceRate,
  isRated,
  setisRated,
}) {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const { key } = useAuthStore((state) => ({ key: state.key }));
  const [getRate, setgetRate] = React.useState({
    getBillRate: 0,
    getMoneyBalRate: 0,
    getPriceRate: 0,
  });

  const handleBillRateChange = (value) => {
    setgetRate((prevState) => ({
      ...prevState,
      getBillRate: value,
    }));
  };

  const handleMoneyBalRateChange = (value) => {
    setgetRate((prevState) => ({
      ...prevState,
      getMoneyBalRate: value,
    }));
  };

  const handlePriceRateChange = (value) => {
    setgetRate((prevState) => ({
      ...prevState,
      getPriceRate: value,
    }));
  };

  const sendRatings = async (rates, id) => {
    console.log(rates, id);
    const res = await fetchRatings(rates, key, id);
    if (res.ok === true) {
      queryClient.invalidateQueries("orders");
      setgetRate({
        getBillRate: res.billing_information_rate,
        getMoneyBalRate: res.money_balance_rate,
        getPriceRate: res.price_rate,
      });
      setisRated(true);
      console.log(
        res.billing_information_rate,
        res.money_balance_rate,
        res.price_rate
      );
    }
  };

  if (billRate === "-" && moneyBalRate === "-" && priceRate === "-") {
    return (
      <div className="full_info_rate__cont">
        {!isRated && (
          <div className="sendRates">
            <p>{t('Please send your ratings')}</p>
          </div>
        )}
        <span className="full_info_rate">
          {t("Billing Information Rate")}
          <Rate
            className="star"
            // allowHalf
            value={getRate.getBillRate}
            onChange={handleBillRateChange}
          />
        </span>
        <span className="full_info_rate">
          {t("Money Balance Rate")}
          <Rate
            className="star"
            // allowHalf
            value={getRate.getMoneyBalRate}
            onChange={handleMoneyBalRateChange}
          />
        </span>
        <span className="full_info_rate">
          {t("Price Rate")}
          <Rate
            className="star"
            // allowHalf
            value={getRate.getPriceRate}
            onChange={handlePriceRateChange}
          />
        </span>

        {!isRated && (
          <div className="sendRates">
            <CustomBlueButton
              label={t("Send")}
              width={"150px"}
              fontSize={"17px"}
              height={"30px"}
              borderRadius={"10px"}
              onClick={() => sendRatings(getRate, choosedCard.hid)}
            />
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="full_info_rate__cont">
        <div className="sendRates">
          <p>{t('Ratings')}</p>
        </div>
        <span className="full_info_rate">
        {t("Billing Information Rate")}
          <div className="rates_group">
            <Rate allowHalf defaultValue={billRate} className="star" />
            {billRate}
          </div>
        </span>
        <span className="full_info_rate">
          {t("Money Balance Rate")}

          <div className="rates_group">
            <Rate allowHalf defaultValue={moneyBalRate} className="star" />
            {moneyBalRate}
          </div>
        </span>
        <span className="full_info_rate">
          {t("Price Rate")}

          <div className="rates_group">
            <Rate allowHalf defaultValue={priceRate} className="star" />
            {priceRate}
          </div>
        </span>
      </div>
    );
  }
}

export default FullInfoRating;
