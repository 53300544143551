import React from "react";
import CustomPopup from "./CustomPopup";
import "../styles/components/HistoryFullInfoPopup.scss";
import CardBrandIcon from "./CardBrandIcon";
import FullInfoRating from "./FullInfoRating";
import useBasesFiltersStore from "../stores/basesFiltersStore.js";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import CountryFlagIcon from "./CountryFlagIcon";
import { Divider } from "antd";

function HistoryFullInfoPopup({ isPopupOpen, setIsPopupOpen, choosedCard }) {
  const navigate = useNavigate();
  const { setFilters: setBasesFilters } = useBasesFiltersStore();
  const [isRated, setisRated] = React.useState(false);
  // React.useEffect(() => {
  //   return () => {
  //     setisRated(false);
  //   };
  // }, [choosedCard]);
  const redirectToBase = (baseName) => {
    setBasesFilters({ bases: [baseName] });
    navigate("/bases");
  };
  return (
    <CustomPopup
      isOpened={isPopupOpen}
      label={"Full Info"}
      setIsOpened={setIsPopupOpen}
    >
      <p className="fullInfoHead">
        <CardBrandIcon brand={choosedCard.card_info.binlist_brand} />
        <span className="cardNum">
          {choosedCard.card_info.list.card_number}
        </span>
      </p>
      <div className="info_cont">
        <div className="info_cont_leftside">
          <div className="info_cont_leftside_bin">
            <span style={{ fontWeight: "700" }}>BIN</span>
            <span>{choosedCard.card_info.bin}</span>
          </div>
          <div className="info_cont_leftside_section">
            <Divider
              style={{
                margin: "3px 0 0 0",
                background: "rgba(228, 228, 228, 1)",
              }}
            />

            {/* <div className="info_cont_leftside_item">
              <span>Name</span>
              <span>Oleele eeele lelevich</span>
            </div> */}
            <div className="info_cont_leftside_item">
              <span style={{ fontWeight: "700" }}>Card number</span>
              <span>{choosedCard.card_info.list.card_number}</span>
            </div>
            <div className="info_cont_leftside_item">
              <span style={{ fontWeight: "700" }}>Exp</span>
              <span>{choosedCard.card_info.list.exp}</span>
            </div>
            <div className="info_cont_leftside_item">
              <span style={{ fontWeight: "700" }}>CVC</span>
              <span>{choosedCard.card_info.list.cvv}</span>
            </div>
            <Divider
              style={{
                margin: "3px 0 0 0",
                background: "rgba(228, 228, 228, 1)",
              }}
            />
          </div>
          <div className="info_cont_leftside_section">
            <div className="info_cont_leftside_item">
              <span style={{ fontWeight: "700" }}>Type</span>
              <span>{choosedCard.card_info.binlist_type}</span>
            </div>
            <div className="info_cont_leftside_item">
              <span style={{ fontWeight: "700" }}>Level</span>
              <span>{choosedCard.card_info.binlist_level}</span>
            </div>
            <div className="info_cont_leftside_item">
              <span style={{ fontWeight: "700" }}>Bank</span>
              <span>{choosedCard.card_info.binlist_bank}</span>
            </div>
            <div className="info_cont_leftside_item">
              <span style={{ fontWeight: "700" }}>Country</span>
              <CountryFlagIcon
                countryName={choosedCard.card_info.binlist_country}
              />
            </div>
            <Divider
              style={{
                margin: "3px 0 0 0",
                background: "rgba(228, 228, 228, 1)",
              }}
            />
          </div>
          <div className="info_cont_leftside_section">
            <div className="info_cont_leftside_item">
              <span style={{ fontWeight: "700" }}>Base</span>
              <span
                style={{
                  color: "#2590ee",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() =>
                  redirectToBase(choosedCard.card_info.list.base_name)
                }
              >
                {choosedCard.card_info.list.base_name}
              </span>
            </div>
            <div className="info_cont_leftside_item">
              <span style={{ fontWeight: "700" }}>Seller</span>
              <Link
                style={{
                  color: "#2590ee",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                to={`/sellers/seller-info/${choosedCard.card_info.seller_id}/${choosedCard.card_info.seller}`}
              >
                {choosedCard.card_info.seller}
              </Link>
            </div>
            <div className="info_cont_leftside_item">
              <span style={{ fontWeight: "700" }}>Price</span>
              <span style={{ color: "#199f2e" }}>${choosedCard.price}</span>
            </div>
            <Divider style={{ margin: "3px 0 0 0" }} />
          </div>
          <div className="info_cont_leftside_section">
            <div className="info_cont_leftside_item">
              <span style={{ fontWeight: "700" }}>Order</span>
              <span>{choosedCard.hid}</span>
            </div>
            <div className="info_cont_leftside_item">
              <span style={{ fontWeight: "700" }}>Created</span>
              {dayjs(choosedCard.card_info.create_date).format(
                "YYYY-MM-DD HH:mm:ss"
              )}
            </div>
          </div>
        </div>
        <div className="info_cont_rightside">
          <div className="sectionName">
            <span style={{ fontWeight: "700", color: "#3d3d3d" }}>
              BILLING INFO
            </span>
          </div>
          <Divider style={{ margin: "10px 0", background: "#2590ee" }} />

          <div className="info_cont_rightside_item">
            <span style={{ fontWeight: "700" }}>ZIP</span>
            <span>
              {choosedCard.card_info.list.zip == null
                ? "—"
                : choosedCard.card_info.list.zip}
            </span>
          </div>
          <div className="info_cont_rightside_item">
            <span style={{ fontWeight: "700" }}>State</span>
            <span>
              {choosedCard.card_info.list.state == null
                ? "—"
                : choosedCard.card_info.list.state}
            </span>
          </div>
          <div className="info_cont_rightside_item">
            <span style={{ fontWeight: "700" }}>City</span>
            <span>
              {choosedCard.card_info.list.city == null
                ? "—"
                : choosedCard.card_info.list.city}
            </span>
          </div>
          <div className="info_cont_rightside_item">
            <span style={{ fontWeight: "700" }}>Address</span>
            <span>
              {choosedCard.card_info.list.address == null
                ? "—"
                : choosedCard.card_info.list.address}
            </span>
          </div>
          <Divider
            style={{ margin: "10px 0", background: "rgba(228, 228, 228, 1)" }}
          />

          <span style={{ display: "block", height: "7px" }}> </span>
          <div className="sectionName">
            <span style={{ fontWeight: "700", color: "#3d3d3d" }}>
              SHIPPING INFO
            </span>
          </div>
          <div className="info_cont_rightside_item">
            <span
              style={{ color: "rgba(209, 209, 209, 1)", fontWeight: "600" }}
            >
              Same as billing
            </span>
          </div>
          <Divider style={{ margin: "10px 0", background: "#2590ee" }} />

          <div className="info_cont_leftside_section">
            <div className="info_cont_leftside_item">
              <span style={{ fontWeight: "700" }}>Phone</span>
              <span>
                {choosedCard.card_info.list.phone == null
                  ? "—"
                  : choosedCard.card_info.list.phone}
              </span>
            </div>
            <div className="info_cont_leftside_item">
              <span style={{ fontWeight: "700" }}>Email</span>
              <span>
                {choosedCard.card_info.list.email == null
                  ? "—"
                  : choosedCard.card_info.list.email}
              </span>
            </div>
          </div>
          <div className="info_cont_leftside_section">
            <div className="info_cont_leftside_item">
              <span style={{ fontWeight: "700" }}>SSN</span>
              <span>
                {choosedCard.card_info.list.ssn == null
                  ? "—"
                  : choosedCard.card_info.list.ssn}
              </span>
            </div>
            <div className="info_cont_leftside_item">
              <span style={{ fontWeight: "700" }}> DOB</span>
              <span>
                {choosedCard.card_info.list.dob == null
                  ? "—"
                  : choosedCard.card_info.list.dob}
              </span>
            </div>
            <div className="info_cont_leftside_item">
              <span style={{ fontWeight: "700" }}>DL</span>
              <span>
                {choosedCard.card_info.list.dl == null
                  ? "—"
                  : choosedCard.card_info.list.dl}
              </span>
            </div>
            <div className="info_cont_leftside_item">
              <span style={{ fontWeight: "700" }}>Holder</span>
              <span>
                {choosedCard.card_info.list.holder == null
                  ? "—"
                  : choosedCard.card_info.list.holder}
              </span>
            </div>
          </div>
        </div>
      </div>
      <FullInfoRating
        isRated={isRated}
        setisRated={setisRated}
        choosedCard={choosedCard}
        billRate={choosedCard.billing_information_rate}
        moneyBalRate={choosedCard.money_balance_rate}
        priceRate={choosedCard.price_rate}
      />
    </CustomPopup>
  );
}

export default HistoryFullInfoPopup;
