import "../styles/pages/BasesPage.scss";
import "../styles/pages/ProfilePage.scss";
import "../styles/pages/BaseInfoPage.scss";
import "../styles/pages/PriceSettingPage.scss";
import React, { useState, useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { get_base_prices, updatePrices } from "../utils/priceUtils";
import { useParams } from "react-router-dom";
import useAuthStore from "../stores/userStore.js";
import CustomBlueButton from "../components/CustomBlueButton";
import CustomTextarea from "../components/CustomTextarea";
import MultiSelectEb from "../components/MultiSelectEb.jsx";
import Loader from "../components/Loader";

function BasePricePage() {
  const { key } = useAuthStore((state) => ({ key: state.key }));
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery({
    queryKey: [key, id, "baseprices"],
    queryFn: get_base_prices,
  });
  if (isLoading) {
    <Loader />;
  }
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      bin: [],
      level: [],
      bank: [],
      country: [],
      type: [],
    },
  });
  const [isError, setIsError] = useState(false);
  const [afterMessage, setafterMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const {
    fields: binFields,
    append: appendBin,
    remove: removeBin,
  } = useFieldArray({ control, name: "bin" });
  const {
    fields: levelFields,
    append: appendLevel,
    remove: removeLevel,
  } = useFieldArray({ control, name: "level" });
  const {
    fields: bankFields,
    append: appendBank,
    remove: removeBank,
  } = useFieldArray({ control, name: "bank" });
  const {
    fields: countryFields,
    append: appendCountry,
    remove: removeCountry,
  } = useFieldArray({ control, name: "country" });
  const {
    fields: typeFields,
    append: appendType,
    remove: removeType,
  } = useFieldArray({ control, name: "type" });

  useEffect(() => {
    if (data) {
      reset(data.prices_set);
      console.log("Form values after reset:", control._formValues);
    }
  }, [data, reset, control]);

  const onSubmit = async (formData) => {
    try {
      const response = await updatePrices(key, id, formData);
      if (response.ok) {
        queryClient.invalidateQueries(["baseprices"]);
        setafterMessage("Price settings change successfully");
        setShowMessage(true);
        setTimeout(3);
        window.location.reload();
      } else {
        setIsError(true);
        setafterMessage(response.error);
        console.error("Update prices error:", response.error);
      }
    } catch (error) {
      console.error("An error occurred while updating prices:", error);
    }
  };

  if (isLoading) return <div>Loading...</div>;

  const renderSelect = (options, value, onChange) => (
    <MultiSelectEb
      options={options}
      isMulti={false}
      value={
        options.find((option) => option.value === value) || {
          value: "",
          label: "Select...",
        }
      }
      onChange={onChange}
    />
  );

  const renderTextarea = (value, onChange) => (
    <CustomTextarea
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder="Price"
      width="85%"
      height="40px"
    />
  );

  return (
    <div className="BasePricePage">
      <h1
        style={{
          display: "flex",
          flex: "row",
          fontSize: "23px",
          fontWeight: "800",
          color: "#3d3d3d",
          marginBottom: "20px",
        }}
      >
        Base Price Settings
      </h1>
      {/* 1 {
  font-size: 23px;
  font-weight: 800;
  color: #3d3d3d;
} */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="settings-form"
        style={{ marginBottom: "30px" }}
      >
        <div className="form-sections">
          {["bin", "level", "bank", "country", "type"].map((field) => (
            <div key={field} className="price-section">
              <h2>{field.toUpperCase()}</h2>
              {field === "type" ? (
                typeFields.map((item, index) => (
                  <div key={item.id} className="price-item">
                    <div className="bbb">
                      <Controller
                        name={`type.${index}.name`}
                        control={control}
                        render={({ field }) =>
                          renderSelect(
                            data?.prices.type.map((option) => ({
                              value: option.name,
                              label: option.name,
                            })),
                            field.value,
                            field.onChange
                          )
                        }
                      />
                    </div>
                    <div className="bbb">
                      <Controller
                        name={`type.${index}.price`}
                        control={control}
                        render={({ field }) =>
                          renderTextarea(field.value, field.onChange)
                        }
                      />
                      <div className="table_red-action-btns">
                        <button onClick={() => removeType(index)}>
                          <img
                            src="/assets/icons/icon-circle-close-red.svg"
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  {field === "bin" &&
                    binFields.map((item, index) => (
                      <div key={item.id} className="price-item">
                        <div className="bbb">
                          <Controller
                            name={`bin.${index}.name`}
                            control={control}
                            render={({ field }) =>
                              renderSelect(
                                data?.prices.bin.map((option) => ({
                                  value: option.name,
                                  label: option.name,
                                })),
                                field.value,
                                field.onChange
                              )
                            }
                          />
                        </div>
                        <div className="bbb">
                          <Controller
                            name={`bin.${index}.price`}
                            control={control}
                            render={({ field }) =>
                              renderTextarea(field.value, field.onChange)
                            }
                          />
                          <div className="table_red-action-btns">
                            <button onClick={() => removeBin(index)}>
                              <img
                                src="/assets/icons/icon-circle-close-red.svg"
                                alt=""
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  {field === "level" &&
                    levelFields.map((item, index) => (
                      <div key={item.id} className="price-item">
                        <div className="bbb">
                          <Controller
                            name={`level.${index}.name`}
                            control={control}
                            render={({ field }) =>
                              renderSelect(
                                data?.prices.level.map((option) => ({
                                  value: option.name,
                                  label: option.name,
                                })),
                                field.value,
                                field.onChange
                              )
                            }
                          />
                        </div>
                        <div className="bbb">
                          <Controller
                            name={`level.${index}.price`}
                            control={control}
                            render={({ field }) =>
                              renderTextarea(field.value, field.onChange)
                            }
                          />
                          <div className="table_red-action-btns">
                            <button onClick={() => removeLevel(index)}>
                              <img
                                src="/assets/icons/icon-circle-close-red.svg"
                                alt=""
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  {field === "bank" &&
                    bankFields.map((item, index) => (
                      <div key={item.id} className="price-item">
                        <div className="bbb">
                          <Controller
                            name={`bank.${index}.name`}
                            control={control}
                            render={({ field }) =>
                              renderSelect(
                                data?.prices.bank.map((option) => ({
                                  value: option.name,
                                  label: option.name,
                                })),
                                field.value,
                                field.onChange
                              )
                            }
                          />
                        </div>
                        <div className="bbb">
                          <Controller
                            name={`bank.${index}.price`}
                            control={control}
                            render={({ field }) =>
                              renderTextarea(field.value, field.onChange)
                            }
                          />
                          <div className="table_red-action-btns">
                            <button onClick={() => removeBank(index)}>
                              <img
                                src="/assets/icons/icon-circle-close-red.svg"
                                alt=""
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}

                  {field === "country" &&
                    countryFields.map((item, index) => (
                      <div key={item.id} className="price-item">
                        <div className="bbb">
                          <Controller
                            name={`country.${index}.name`}
                            control={control}
                            render={({ field }) =>
                              renderSelect(
                                data?.prices.country.map((option) => ({
                                  value: option.name,
                                  label: option.name,
                                })),
                                field.value,
                                field.onChange
                              )
                            }
                          />
                        </div>
                        <div className="bbb">
                          <Controller
                            name={`country.${index}.price`}
                            control={control}
                            render={({ field }) =>
                              renderTextarea(field.value, field.onChange)
                            }
                          />

                          <div className="table_red-action-btns">
                            <button onClick={() => removeCountry(index)}>
                              <img
                                src="/assets/icons/icon-circle-close-red.svg"
                                alt=""
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              )}
              <CustomBlueButton
                type="button"
                color="blue"
                width="125px"
                height="40px"
                borderRadius="10px"
                onClick={() => {
                  if (field === "bin") appendBin({ name: "", price: "" });
                  if (field === "level") appendLevel({ name: "", price: "" });
                  if (field === "bank") appendBank({ name: "", price: "" });
                  if (field === "country")
                    appendCountry({ name: "", price: "" });

                  if (field === "type") appendType({ name: "", price: "" });
                }}
                label={`Add ${field.toUpperCase()}`}
              />
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // alignItems: "center",
            gap: "300px",
            marginTop: "90px",
            alignItems: "flex-end",
          }}
        >
          <CustomBlueButton
            type="submit"
            label="Save"
            color="blue"
            height="55px"
            width="125px"
            borderRadius="10px"
          />
          <span style={{ width: "35%" }}>
            {/* {showMessage === false ? (
                      <p className="errorChange" style={{fontSize: '33px', background: 'rgba(209, 28, 28, 0.2)', borderRadius: '10px', padding: '10px'}}>{isError ? afterMessage : ""}</p>
                  ) : (
                    <p className="succsessfullChange"  style={{fontSize: '33px', background: 'rgba(25, 159, 46, 0.2)', borderRadius: '10px', padding: '10px'}}>{afterMessage}</p>
                  )} */}
            <p
              className={
                showMessage === false ? "errorChange" : "succsessfullChange"
              }
              style={{
                fontSize: "33px",
                background:
                  showMessage === false
                    ? "rgba(209, 28, 28, 0.2)"
                    : "rgba(25, 159, 46, 0.2)",
                borderRadius: "10px",
                padding: afterMessage ? "10px" : "0px", // Условное применение отступов
              }}
            >
              {showMessage === false
                ? isError
                  ? afterMessage
                  : ""
                : afterMessage}
            </p>
          </span>
        </div>
      </form>
    </div>
  );
}

export default BasePricePage;
