import React from "react";
import { Link } from "react-router-dom";

import CustomBlueButton from "../CustomBlueButton";
const supportColumns = (archiveChat) => [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (text) => <span className="orderSpan_blue"> {text} </span>,
  },
  {
    title: "SUBJECT",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "LAST UPDATED",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "STATUS",
    dataIndex: "Active",
    key: "Active",
    // render: (_, record) =>
    //   record.last_user == "user" ? <>Sent</> : <>New message</>,
  },
  {
    title: "OPEN",
    dataIndex: "open",
    key: "open",
    render: (_, record) => (
      <Link to={`/support-chat/${record.id}`} className="openChatGreenBtn">
        Open
      </Link>
    ),
  },
  {
    title: "ARCHIVE",
    dataIndex: "archive",
    key: "archive",
    render: (_, record) =>
      record.status_noclose_noarhive || record.Active === "Archive" ? (
        <>-</>
      ) : (
        <CustomBlueButton
          label={"Archive"}
          borderRadius={"15px"}
          color={"lightBlue"}
          height={"27px"}
          fontSize={"15px"}
          onClick={() => archiveChat(record.id)}
          fontWeight={"600"}
        />
      ),
  },
];

export default supportColumns;
