import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./components/RoutingComponents/PrivateRoute";
import PreorderPage from "./pages/PreorderPage";
import LoginPage from "./pages/LoginPage";

import "./styles/main.scss";
import Layout from "./components/RoutingComponents/Layout";
import BuyCardsPage from "./pages/BuyCardsPage";
import BasesPage from "./pages/BasesPage";
import ProfilePage from "./pages/ProfilePage";
import SellersPage from "./pages/SellersPage";
import NewsPage from "./pages/NewsPage";
import OrdersPage from "./pages/OrdersPage";
import PayoutsPage from "./pages/PayoutsPage";
import StatisticPage from "./pages/StatisticPage";
import SupportPage from "./pages/SupportPage";
import RegPage from "./pages/RegPage";
import SellerInfoPage from "./pages/SellerInfoPage";
import RulesPage from "./pages/RulesPage";
import FaqPage from "./pages/FaqPage";
import ToolsPage from "./pages/ToolsPage";
import CartPage from "./pages/CartPage";
import UploadingPage from "./pages/UploadingPage";
import BaseInfoPage from "./pages/BaseInfoPage";
import useUserStore from "./stores/userStore";
import AlertsPage from "./pages/AlertsPage";
import PayoutsPageSeller from "./pages/PayoutsPageSeller";
import BasePricePage from "./pages/BasePricePage.jsx";
import ServicesPage from "./pages/ServicesPage";
import BinsPage from "./pages/BinsPage";
import CardCheckPage from "./pages/CardCheckPage";
import ChatPage from "./pages/ChatPage";
import InvitePage from "./pages/InvitePage";
import OnlyNewsPage from "./pages/OnlyNewsPage";
const App = () => {
  const { role } = useUserStore((state) => ({ role: state.role }));
  const { currentRole } = useUserStore((state) => ({
    currentRole: state.currentRole,
  }));
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegPage />} />

        <Route
          path="/"
          element={
            <PrivateRoute>
              <Layout>
                {currentRole === "seller" ? <BasesPage /> : <NewsPage />}
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/preorder"
          element={
            <PrivateRoute roleForRoute={"buyer"}>
              <Layout>
                <PreorderPage />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/withdrawal"
          element={
            <PrivateRoute roleForRoute={"seller"}>
              <Layout>
                <PayoutsPageSeller />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/bases"
          element={
            <PrivateRoute roleForRoute={"buyer"}>
              <Layout>
                <BasesPage />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path="/base_price_setting/:id"
          element={
            <PrivateRoute roleForRoute={"seller"}>
              <Layout>
                <BasePricePage />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute roleForRoute={"buyer"}>
              <Layout>
                <ProfilePage />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/sellers"
          element={
            <PrivateRoute roleForRoute={"buyer"}>
              <Layout>
                <SellersPage />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/sellers/seller-info/:id/:name"
          element={
            <PrivateRoute roleForRoute={"buyer"}>
              <Layout>
                <SellerInfoPage />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/cards/:base?"
          element={
            <PrivateRoute roleForRoute={"buyer"}>
              <Layout>
                <BuyCardsPage />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/orders"
          element={
            <PrivateRoute roleForRoute={"buyer"}>
              <Layout>
                <OrdersPage />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/deposit"
          element={
            <PrivateRoute roleForRoute={"buyer"}>
              <Layout>
                <PayoutsPage />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/statistic"
          element={
            <PrivateRoute>
              <Layout>
                <StatisticPage />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/support"
          element={
            <PrivateRoute>
              <Layout>
                <SupportPage />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/support-chat/:id"
          element={
            <PrivateRoute>
              <Layout>
                <ChatPage />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/tools"
          element={
            <PrivateRoute>
              <Layout>
                <ToolsPage />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/rules"
          element={
            <PrivateRoute roleForRoute={"all"}>
              <Layout>
                <RulesPage />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/faq"
          element={
            <PrivateRoute>
              <Layout>
                <FaqPage />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/news"
          element={
            <PrivateRoute roleForRoute={"all"}>
              <Layout>
                <OnlyNewsPage />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/cart"
          element={
            <PrivateRoute roleForRoute={"buyer"}>
              <Layout>
                <CartPage />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/upload"
          element={
            <PrivateRoute roleForRoute={"seller"}>
              <Layout>
                <UploadingPage />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/base-info/:id/"
          element={
            <PrivateRoute roleForRoute={"seller"}>
              <Layout>
                <BaseInfoPage />
              </Layout>
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/alerts"
          element={
            <PrivateRoute>
              <Layout>
                <AlertsPage />
              </Layout>
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/services"
          element={
            <PrivateRoute roleForRoute={"buyer"}>
              <Layout>
                <ServicesPage />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/bins"
          element={
            <PrivateRoute roleForRoute={"buyer"}>
              <Layout>
                <BinsPage />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/card-checker"
          element={
            <PrivateRoute roleForRoute={"buyer"}>
              <Layout>
                <CardCheckPage />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/invite"
          element={
            <PrivateRoute roleForRoute={"seller"}>
              <Layout>
                <InvitePage />
              </Layout>
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
