import styles from "../styles/pages/NewsPage.module.scss";

import axios from "axios";
import useAuthStore from "../stores/userStore.js";
import { API_URL } from "../apiConf";
import Container from "../components/Container/Container.jsx";
import { Link } from "react-router-dom";
import BasesCard from "../components/BasesCard.jsx";
import { useQuery } from "@tanstack/react-query";
import NewItemContent from "../components/NewItemContent.jsx";
import Loader from "../components/Loader.jsx";
import { useTranslation } from "react-i18next";
import { TiPin } from "react-icons/ti";

const fetchData = async (queryKey) => {
  const { data } = await axios.get(`${API_URL}/api/news_list/?key=${queryKey}`);
  return data;
};

function NewsPage() {
  const { t } = useTranslation();
  const { key } = useAuthStore((state) => ({ key: state.key }));

  const { data, isLoading } = useQuery({
    queryKey: ["fetchNews"],
    queryFn: () => fetchData(key),
  });

  if (isLoading) {
    return <Loader />;
  }
  if (data) {
    return (
      <main className={styles.page}>
        <h1>{t("Top Sellers")}</h1>
        <div className={styles.grid}>
          {data.top_sellers?.map((item) => (
            <Container
              style={{
                border: `${
                  item.topseller
                    ? "3px solid #BF00FF"
                    : "1px solid rgba(37, 144, 238, 1)"
                }  `,
                justifyContent: "center",
              }}
            >
              {item.topseller && (
                <div className={styles.topSellerStatus}>Top seller</div>
              )}

              <p
                className={item.topseller ? styles.topSellerName : styles.name}
              >
                {item.username}
              </p>
              <div className={styles.stat_item}>
                {t("Products")}:{" "}
                <span
                  className={
                    item.topseller
                      ? styles.stat_value_topSeller
                      : styles.stat_value
                  }
                >
                  {item.total}
                </span>
              </div>

              <div className={styles.stat_item}>
                {t("Total Sold")}:{" "}
                <span
                  className={
                    item.topseller
                      ? styles.stat_value_topSeller
                      : styles.stat_value
                  }
                >
                  {item.sold}
                </span>
              </div>
              <div style={{ marginTop: "20px" }} className={styles.stat_item}>
                {t("Rate")}:{" "}
                <span
                  className={
                    item.topseller
                      ? styles.stat_value_topSeller
                      : styles.stat_value
                  }
                >
                  {item.rate}/5
                </span>
              </div>
            </Container>
          ))}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link className={styles.link} to={"/sellers"}>
            {t("View more sellers")}
          </Link>
        </div>

        <h1>{t("Updates")}</h1>
        <div className={styles.grid} style={{ gap: "10px" }}>
          {data?.updates?.map((base) => (
            <BasesCard
              key={base.basename}
              rate={base.rate}
              name={base.basename}
              date={base.upload_date}
              seller={base.seller}
              sellerId={base.seller_id}
              sale={base.sale}
              soldProgress={base.sold_progress}
              validProgress={base.valid}
              price={base.price}
              info={base.info}
              total={base.count_card}
              country={base.country}
              status={base.ref}
            />
          ))}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link className={styles.link} to={"/bases"}>
            {t("View more updates")}
          </Link>
        </div>

        <h1>{t("News")}</h1>
        <div className={styles.container}>
          {data.news_list.map((item) => (
            <div className={styles.news_item}>
              {item.pin && (
                <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                  <TiPin size={25} />
                </div>
              )}

              <h2>{item.title}</h2>
              <NewItemContent text={item.content} />
              <p className={styles.date}>{item.date}</p>
            </div>
          ))}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link className={styles.link} to={"/news"}>
            {t("View more news")}
          </Link>
        </div>
      </main>
    );
  }
}

export default NewsPage;
