import React, { useState, useEffect } from "react";
import CustomPopup from "../components/CustomPopup";
import CustomTextarea from "../components/CustomTextarea";
import MultiSelect from "./MultiSelect";
import CustomBlueButton from "./CustomBlueButton";
import useAuthStore from "../stores/userStore.js";
import { useQueryClient } from "@tanstack/react-query";
import {
  createTicket,
  createUniqueOptions,
  createUniqueOptionsOrders,
  getTicketInfo,
} from "../services/supportService";
import { useTranslation } from "react-i18next";
import { notification } from "antd";

function NewTicketPopup({ isPopupOpen, setIsPopupOpen, fetchChats }) {
  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [newTicketInfo, setNewTicketInfo] = useState({
    subject: "",
    category: "",
    description: "",
    orderId: "",
  });
  const [categoryItems, setCategoryItems] = useState([]);
  const [ordersItems, setOrdersItems] = useState([]);

  const { key, currentRole } = useAuthStore((state) => ({
    key: state.key,
    currentRole: state.currentRole,
  }));

  useEffect(() => {
    if (isPopupOpen) {
      const fetchInfo = async () => {
        const res = await getTicketInfo(key, currentRole);
        if (res.ok) {
          setCategoryItems(createUniqueOptions(res.categories));
          setOrdersItems(createUniqueOptionsOrders(res.user_orders));
        }
      };

      fetchInfo();
    }
    return () => {
      setNewTicketInfo({
        subject: "",
        category: "",
        description: "",
        orderId: "",
      });
    };
  }, [isPopupOpen, key, currentRole]);

  const createChat = async () => {
    setIsLoading(true);
    const response = await createTicket(newTicketInfo, key, currentRole);
    if (response.ok) {
      setIsLoading(false);
      queryClient.invalidateQueries(["fetchChats"]);
      setNewTicketInfo({
        subject: "",
        category: "",
        description: "",
        orderId: "",
      });

      setIsPopupOpen(false);
    }
    console.log(response);
  };

  const isDisabled = () => {
    const { subject, description, category, orderId } = newTicketInfo;

    if (!subject || !description || !category) {
      return true;
    } else if (
      (category === "Order issues" && orderId == "") ||
      (category === "Order issues" && orderId == undefined)
    ) {
      return true;
    }
    return false;
  };

  return (
    <CustomPopup
      label={t("New Ticket")}
      isOpened={isPopupOpen}
      setIsOpened={setIsPopupOpen}
    >
      <div
        style={{
          display: "flex",
          gap: "20px",
          marginBottom: "15px",
          width: "100%",
        }}
      >
        <CustomTextarea
          label={t("Subject")}
          height="40px"
          width="100%"
          onChange={(e) =>
            setNewTicketInfo({ ...newTicketInfo, subject: e.target.value })
          }
          value={newTicketInfo.subject}
        />
        <MultiSelect
          placeholder={t("Category")}
          options={categoryItems}
          isMulti={false}
          value={newTicketInfo.category}
          onChange={(e) => setNewTicketInfo({ ...newTicketInfo, category: e })}
        />
      </div>
      {newTicketInfo.category === "Order issues" && (
        <div style={{ marginBottom: "15px", width: "100%", maxWidth: "650px" }}>
          <MultiSelect
            placeholder={t("Choose order")}
            options={ordersItems}
            isMulti={false}
            value={newTicketInfo.orderId}
            onChange={(e) => setNewTicketInfo({ ...newTicketInfo, orderId: e })}
          />
        </div>
      )}
      <CustomTextarea
        label={t(
          "Describe the problem in detail (tickets without a detailed description will simply be closed):"
        )}
        height="170px"
        width="100%"
        onChange={(e) =>
          setNewTicketInfo({ ...newTicketInfo, description: e.target.value })
        }
        value={newTicketInfo.description}
      />
      <div style={{ marginTop: "20px", display: "flex", gap: "20px" }}>
        <CustomBlueButton
          borderRadius="10px"
          color="lightBlue"
          height="40px"
          width="125px"
          onClick={() => setIsPopupOpen(false)}
          label={t("Cancel")}
          fontSize="17px"
        />
        <CustomBlueButton
          borderRadius="10px"
          isLoading={isLoading}
          color="blue"
          height="40px"
          width="125px"
          onClick={createChat}
          label={t("Create")}
          disabled={isDisabled()}
          fontSize="17px"
        />
      </div>
    </CustomPopup>
  );
}

export default NewTicketPopup;
