import React from "react";

import { Select } from "antd";
function MultiSelect({
  placeholder,
  options,
  isMulti = true,
  onChange,
  value,
  defaultValue,
  isDisabled = false,
  isClearable = true,
}) {
  return (
    <div style={{ width: "100%" }}>
      <p
        style={{
          marginBottom: "10px",
          color: "#3D3D3D",
          fontWeight: "700",
          fontSize: "17px",
        }}
      >
        {placeholder}
      </p>
      <Select
        mode={isMulti ? "multiple" : ""}
        // isMulti={isMulti} mode=multiple
        disabled={isDisabled}
        size={"large"}
        allowClear={isClearable}
        defaultValue={defaultValue}
        value={value}
        placeholder="All"
        onChange={onChange}
        style={{
          width: "100%",
          // minWidth: "170px",
          fontFamily: "Montserrat",
        }}
        options={options}
      />
    </div>
  );
}

export default MultiSelect;
