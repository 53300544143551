import React from "react";
import useAuthStore from "../stores/userStore.js";
import CustomBlueButton from "../components/CustomBlueButton";
import CustomTextarea from "../components/CustomTextarea";
import MultiSelect from "../components/MultiSelect";
import "../styles/pages/UploadingPage.scss";
import { getDelim, selectOptions, uploadCsv } from "../utils/uploadCardsUtils";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { notification } from "antd";
import { useQuery } from "@tanstack/react-query";

function UploadingPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { key } = useAuthStore((state) => ({ key: state.key }));
  const [isLoadingBtn, setIsLoadingBtn] = React.useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [csvValue, setCsvValue] = React.useState("");
  const [firstCsvLine, setfirstCsvLine] = React.useState([]);
  const [selectedValues, setSelectedValues] = React.useState([]);
  const [delimiter, setDelimiter] = React.useState("");
  const { data, isLoading } = useQuery({
    queryKey: [key],
    queryFn: getDelim,
  });

  const openNotificationError = (textError) => {
    api["error"]({
      message: "Error",
      description: textError,
      duration: 10,
    });
  };

  React.useEffect(() => {
    data?.delimiter !== null ? setDelimiter(data?.delimiter) : setDelimiter("");
    if (data?.fields) {
      const matchedFields = data.fields.map((field) => {
        const matchedOption = selectOptions.find(
          (option) => option.value === field
        );
        return matchedOption ? matchedOption.value : selectOptions[0].value;
      });
      setSelectedValues(matchedFields);
    } else {
      setSelectedValues([]);
    }
  }, [data]);

  const handleParseCsv = () => {
    if (!csvValue) return;
    const lines = csvValue.split("\n");
    const firstLine = lines[0];
    const firstLineArray = firstLine.split(delimiter);
    setfirstCsvLine(firstLineArray);
    // setSelectedValues(
    //   Array(firstLineArray.length).fill(selectOptions[0].value)
    // );
    if (selectedValues.length === 0) {
      setSelectedValues(
        Array(firstLineArray.length).fill(selectOptions[0].value)
      );
    }
  };
  const handleSelectChange = (value, index) => {
    const newValues = [...selectedValues];
    newValues[index] = value;
    setSelectedValues(newValues);
  };
  React.useEffect(() => {
    console.log(selectedValues);
  }, [selectedValues]);
  React.useEffect(() => {
    if (csvValue.length > 0 && delimiter.length > 0) {
      handleParseCsv();
    }
    return () => {
      setfirstCsvLine([]);
    };
  }, [delimiter, csvValue]);

  const handleUpload = async () => {
    setIsLoadingBtn(true);
    const res = await uploadCsv(key, delimiter, csvValue, selectedValues);
    if (res.ok === true) {
      navigate(`/base-info/${res.id}`);
    } else openNotificationError(res.error_text);

    setIsLoadingBtn(false);
  };

  return (
    <div className="UploadingPage_cont">
      <h1>{t("Upload")}</h1>
      {contextHolder}
      <div className="topPart_upload">
        <div style={{ display: "flex", gap: "20px", marginBottom: "15px" }}>
          <CustomTextarea
            width={"10%"}
            height={"40px"}
            placeholder={t("Delimiter")}
            value={delimiter}
            onChange={(e) => setDelimiter(e.target.value)}
          />
          <CustomBlueButton
            isLoading={isLoadingBtn}
            width={"10%"}
            height={"40px"}
            fontSize={"15px"}
            label={t("Load file")}
            onClick={handleUpload}
          />
        </div>
        <CustomTextarea
          width={"100%"}
          height={"270px"}
          placeholder={t("Put here your stuff")}
          value={csvValue}
          onChange={(e) => setCsvValue(e.target.value)}
        />
      </div>

      <div className="bottomPart_upload">
        <div className="showFirstString">
          <span>{t("Import")}:</span> {firstCsvLine.join(",")}
        </div>
        <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
          {firstCsvLine.map((str, index) => (
            <div className="selectForUpload" key={index}>
              {/* <span className="selectForUpload_span">{str}</span> */}
              <MultiSelect
                value={selectOptions.find(
                  (option) => option.value === selectedValues[index]
                )}
                placeholder={str}
                isMulti={false}
                options={selectOptions}
                onChange={(selectedOption) =>
                  handleSelectChange(selectedOption, index)
                }
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default UploadingPage;
