import React from "react";
import { sellerHeaderItems } from "./conf";
import styles from "./Header.module.scss";
import HeaderLinkItem from "./HeaderLinkItem";
import Cart from "../../assets/icons/Cart";
import User from "../../assets/icons/User";
import Seller from "../../assets/icons/Seller";
import useAuthStore from "../../stores/userStore";
import { Link, useNavigate } from "react-router-dom";
import LanguageChanger from "../LanguageChanger";
import { FiLogOut } from "react-icons/fi";
import { useTranslation } from "react-i18next";

export default function SellerHeader({ setIsOpen }) {
  const { t } = useTranslation();

  const { currentRole } = useAuthStore((state) => ({
    currentRole: state.currentRole,
  }));
  const navigate = useNavigate();
  const changeRole = () => {
    let newRole = currentRole === "buyer" ? "seller" : "buyer";
    useAuthStore.getState().changeCurrentRole(newRole);
    navigate("/");
  };
  const { balance, seller_balance } = useAuthStore((state) => ({
    balance: state.balance,
    seller_balance: state.seller_balance,
  }));
  return (
    <div className={styles.Header}>
      <img
        className={styles.burgerIcon}
        src="/assets/icons/icon-burger.svg"
        alt=""
        onClick={() => setIsOpen(true)}
      />
      <div className={styles.headerLinks}>
        {sellerHeaderItems.map((item) => (
          <HeaderLinkItem {...item} key={item.link} />
        ))}

        {/* btn for change role to seller */}
        <div className={styles.HeaderLinkItem} onClick={() => changeRole()}>
          <Seller />
          {currentRole === "buyer" ? t("Seller") : t("Buyer")}
        </div>
      </div>
      <div className={styles.HeaderRightSide}>
        <LanguageChanger />
        {/* <Link to={"/cart"}>
          <Cart />
        </Link>

        <Link to={"/profile"}>
          <User />
        </Link> */}

        <Link to={"/withdrawal"}>${seller_balance}</Link>

        <button
          className="drawer-list-item"
          onClick={() => useUserStore.getState().logout()}
        >
          <FiLogOut size={"16px"} color="white" />
        </button>
      </div>
    </div>
  );
}
