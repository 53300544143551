import React, { useEffect, useRef, useState } from "react";
import { QueryClient, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { API_URL } from "../apiConf";
import axios from "axios";
import CustomBlueButton from "../components/CustomBlueButton";
import ChangeSalePopup from "../components/ChangeSalePopup";
import useAuthStore from "../stores/userStore.js";
import CardsTable from "../components/CardsTable.jsx";
import ChangePriceBasePopup from "../components/ChangePriceBasePopup.jsx";
import CustomRedBtn from "../components/CustomRedBtn.jsx";
import { Link } from "react-router-dom";
import {
  getBase,
  editBase,
  editBaseStatus,
} from "../utils/baseInfoPageUtils.js";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { saveAs } from "file-saver";
import MultiSelect from "../components/MultiSelect.jsx";

import "../styles/pages/BasesPage.scss";
import "../styles/pages/ProfilePage.scss";
import "../styles/pages/BaseInfoPage.scss";
import Loader from "../components/Loader.jsx";

function BaseInfoPage() {
  let { id } = useParams();
  const useQueryClient1 = useQueryClient();
  const { key } = useAuthStore((state) => ({ key: state.key }));

  const [resetCounter2, setResetCounter2] = React.useState(0);
  const [balancePopup, addBalance] = useState(false);

  const [credsPriceSetPopup, setPriceSetPopup] = useState(false);
  const [prices, setPrices] = useState(false);
  const [prices_red, setPricesRed] = useState(true);

  const keyAuth = key;

  const { data, isLoading: isLoading } = useQuery({
    queryKey: [key, id, "baseinfo"],
    queryFn: getBase,
  });

  useEffect(() => {
    if (data && data.prices) {
      setPrices(data.prices);
      setPricesRed(data.prices_red);
    }
  }, [data]);

  console.log("data", data);
  //   console.log(base_info);
  if (isLoading) {
    return <Loader />;
  }
  const refOptions = [
    { value: "0", label: "NonRef" },
    { value: "1", label: "Ref" },
    { value: "2", label: "Avs" },
  ];
  const postData = async (key, id, value, type) => {
    const response = await editBase(key, id, type, value);
    if (response.ok) {
      useQueryClient1.invalidateQueries(["baseinfo"]);
    }
    console.log(response);
  };

  const handleOneSelectChange = (selectedOption) => {
    postData(keyAuth, id, refOptions[selectedOption].value, "ref");
  };

  const postData2 = async (key, id, admin) => {
    const responce = await editBaseStatus(key, id, admin);
    if (responce.ok === true) {
      useQueryClient1.invalidateQueries(["baseinfo"]);
    }
    console.log(responce);
  };
  const saleOptions = [
    { value: "10", label: "10" },
    { value: "20", label: "20" },
    { value: "30", label: "30" },
    { value: "40", label: "40" },
    { value: "50", label: "50" },
    { value: "60", label: "60" },
    { value: "70", label: "70" },
    { value: "80", label: "80" },
    { value: "90", label: "90" },
    { value: "0", label: "0" },
  ];
  const downloadFile = async (sold) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/admin/download_base/?key=${keyAuth}`,
        {
          params: { id, sold },
          responseType: "json",
        }
      );

      if (response.data.ok) {
        const fileData = response.data.data;
        const fileName = response.data.file;
        const binaryString = window.atob(fileData);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          const ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }

        const blob = new Blob([bytes], { type: "application/zip" });
        saveAs(blob, fileName);
      }
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  const handleDownloadSold = () => {
    downloadFile(true);
  };
  const handleDownloadAll = () => {
    downloadFile(false);
  };

  const handleAvsSelectChange = (value) => {
    if (value == 2) {
      value = 0;
    }
    postData(keyAuth, id, value, "avs");
  };

  const handleSaleChange = (newSaleValue) => {
    postData(keyAuth, id, newSaleValue, "sale");
  };
  const updateInfo = () => {
    useQueryClient1.invalidateQueries("baseinfo", "basecardinfo");
    // useQueryClient1.invalidateQueries();
  };
  return (
    <div className="ProfilePage__cont baseInfoPage">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1>Base info</h1>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <CustomBlueButton
            label={"Update Base Info"}
            height={"37px"}
            borderRadius={"10px"}
            onClick={() => updateInfo()}
            // disabled={selectedRowKeys.length == 0}
          />

          <Link
            to={`/base_price_setting/${id}`}
            style={{ textDecoration: "none" }}
          >
            <CustomRedBtn
              label={"Price Settings Table"}
              height={"35px"}
              // width={"175px"}
              // color="blue"
              color={prices_red == 1 ? "blue" : "red"}
              fontSize={"15px"}
              borderRadius={"10px"}
            />
          </Link>
        </div>
      </div>

      <div className="profileInfo_cont">
        <div className="left-side">
          <div className="info-box">
            <div className="info-box_head">
              <p>Info</p>
            </div>

            <div className="box-item">
              <span>Rate</span>
              <span className="box-item_bold">{data?.rate}</span>
            </div>
            <div className="box-item">
              <span>Price</span>
              <span className="balance-span">
                {/* <img src="/assets/icons/icon-wallet-green.svg" /> */}
                {data?.price}$
              </span>
            </div>
            <div className="box-item">
              <span>Basename</span>
              <span className="box-item_bold">{data?.basename}</span>
            </div>
            <div className="box-item">
              <span>Upload Date</span>
              <span className="box-item_bold">{data?.upload_date}</span>
            </div>
            <div className="box-item">
              <span>Country</span>
              <span className="box-item_bold">{data?.country}</span>
            </div>
            <div className="box-item">
              <span>Seller</span>
              <span className="box-item_bold">{data?.seller}</span>
            </div>
            <div className="box-item">
              <span>Count Card</span>
              <span className="box-item_bold">{data?.count_card}</span>
            </div>
            <div className="box-item">
              <span>Valid</span>
              <span className="box-item_bold">{data?.valid}</span>
            </div>
            <div className="box-item">
              <span>Sold Progress</span>
              <span className="box-item_bold">{data?.sold_progress}</span>
            </div>
            <div className="box-item">
              <span>Info</span>
              <span className="box-item_bold">{data?.info}</span>
            </div>
          </div>
        </div>

        <div className="right-side">
          {/* <EditUser id={id} /> */}
          <div className="info-box">
            <div className="info-box_head">
              <p>Change</p>

              {/* <CustomRedBtn
                label={"Download All"}
                height={"35px"}
                width={"155px"}
                color={"blue"}
                fontSize={"15px"}
                borderRadius={"10px"}
                onClick={handleDownloadAll}
              /> */}
              {/* <CustomRedBtn
                label={"Download sold"}
                height={"35px"}
                width={"155px"}
                // color="blue"
                color={"blue"}
                fontSize={"15px"}
                borderRadius={"10px"}
                onClick={handleDownloadSold}
              /> */}
            </div>

            <div className="box-item">
              <span>Ref</span>

              <span>
                {data?.ref == "0" && "NonRef"}
                {data?.ref == "1" && "Ref"}
              </span>
            </div>
            {/* {data?.ref == "2" || data?.ref == "1" ? (
              <div className="box-item">
                <span>Avs</span>

                <button
                  onClick={() => handleAvsSelectChange(data?.ref)}
                  className="btn-edit"
                  style={{
                    backgroundColor:
                      data?.ref == 2
                        ? "rgba(25, 159, 46, 0.15)"
                        : "rgba(209, 28, 28, 0.15)",
                    color:
                      data?.ref == 2
                        ? "rgba(25, 159, 46, 1)"
                        : "rgba(209, 28, 28, 1)",
                  }}
                >
                  {data?.ref == 2 ? "On" : "Off"}
                </button>
              </div>
            ) : (
              <></>
            )} */}
            <div
              className="box-item"
              style={{ display: "flex", gap: "10px", alignItems: "center" }}
            >
              <div style={{ display: "flex", gap: "10px", paddingTop: "10px" }}>
                <span>Sale:</span>
                {data?.sale && data.sale !== 0 ? data.sale + "%" : "-"}
              </div>

              <MultiSelect
                key={`multi-select-REF-${resetCounter2}`}
                // placeholder={"Sale"}
                options={saleOptions}
                isMulti={false}
                defaultValue={saleOptions.find(
                  (option) => option.value == data.sale
                )}
                onChange={handleSaleChange}
              />
            </div>

            {data?.can_start == false && data?.can_stop == false ? (
              <></>
            ) : (
              <div className="box-item">
                <span>
                  Sales{" "}
                  {data?.can_start == true && data?.can_stop == false
                    ? "Disabled"
                    : "Active"}
                </span>

                <button
                  onClick={() => postData2(key, id, "status")}
                  className="btn-edit"
                  style={{
                    backgroundColor:
                      data?.can_start == true && data?.can_stop == false
                        ? "rgba(25, 159, 46, 0.15)"
                        : "rgba(209, 28, 28, 0.15)",
                    color:
                      data?.can_start == true && data?.can_stop == false
                        ? "rgba(25, 159, 46, 1)"
                        : "rgba(209, 28, 28, 1)",
                  }}
                >
                  {data?.can_start == true && data?.can_stop == false
                    ? "Active sales"
                    : "Disable sales"}
                </button>
              </div>
            )}
          </div>
          <div className="info-box">
            <div className="info-box_head">Info</div>
            <div className="box-item">
              <span>Status</span>
              <span className="box-item_bold">{data?.status_user}</span>{" "}
            </div>
            <div className="box-item">
              <span>Comment</span>
              <span className="box-item_bold">{data?.comment_user}</span>{" "}
            </div>
          </div>
        </div>
      </div>

      <ChangeSalePopup
        balancePopup={balancePopup}
        addBalance={addBalance}
        id={id}
      />
      <ChangePriceBasePopup
        credsPriceSetPopup={credsPriceSetPopup}
        setPriceSetPopup={setPriceSetPopup}
        prices={prices}
        useQueryClient4={useQueryClient1}
        id={id}
      />
      <CardsTable />
    </div>
  );
}

export default BaseInfoPage;
