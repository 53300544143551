import LinearProgress from "@mui/material/LinearProgress";
import "../styles/components/BasesCard.scss";
import useCardsFiltersStore from "../stores/buyCardsFiltersStore";
import { useNavigate, Link } from "react-router-dom";
import CountryFlagIcon from "./CountryFlagIcon.jsx";
import { Tooltip } from "antd";
function BasesCard({
  name,
  date,
  seller,
  sale,
  soldProgress,
  validProgress,
  price,
  info,
  total,
  rate,
  country,
  sellerId,
  status,
}) {
  const { setFilters } = useCardsFiltersStore();
  const navigate = useNavigate();
  const renderStatus = () => {
    if (status == 0) {
      return (
        <span className="status" style={{ color: "rgba(209, 28, 28, 1)" }}>
          No
        </span>
      );
    } else if (status == 1) {
      return <span className="status">Yes</span>;
    } else {
      return (
        <span className="status" style={{ color: "rgba(209, 28, 28, 1)" }}>
          AVS
        </span>
      );
    }
  };
  const filterCardsByBases = (name) => {
    setFilters({ bases: [name] });
    const urlName = encodeURIComponent(name);
    navigate(`/cards/${urlName}`);
  };
  const countryList = country === "" ? [] : country?.split("/");

  return (
    <div className="base-card">
      <div className="card-header">
        {/* <div className="star_cont">
          <img src="/assets/icons/icon-star.svg" />
          <span>{rate}</span>
        </div> */}
        <div className="header_right-part">
          {/* <div className="right-part_top">
            {renderStatus()}
            <span className="date">{date}</span>
          </div> */}
          <p className="name" onClick={() => filterCardsByBases(name)}>
            {name}
          </p>
        </div>
      </div>
      <div className="card-body">
        <div className="seller">
          <p>
            Seller:
            <Link
              style={{
                // textDecoration: "none",
                marginLeft: "5px",
                color: "#2590ee",
              }}
              to={`/sellers/seller-info/${sellerId}/${seller}`}
            >
              {seller}
            </Link>
          </p>
          {/* <p>Total: {total}</p> */}
        </div>
        <p className="country">
          {countryList.map((item, index) => (
            <CountryFlagIcon countryCodeParam={item} key={index} />
          ))}
        </p>

        <div className="progressBar">
          <div className="label">
            <span>Valid: {validProgress}%</span>
          </div>
          <LinearProgress
            className="progress green"
            variant="determinate"
            value={validProgress}
          />
        </div>
        <div className="progressBar">
          <div className="label">
            <span>Sold: {soldProgress}%</span>
            {/* <span>{soldProgress}%</span> */}
          </div>
          <LinearProgress
            className="progress"
            variant="determinate"
            value={soldProgress}
          />
        </div>
        <Tooltip placement="top" title={info}>
          <p className="infoBasesCard">Info: {info}</p>
        </Tooltip>
        <p style={{ marginTop: "20px" }}>Price: $ {price} </p>
        <p>Refund: {renderStatus()} </p>
        <p>Total: {total} </p>

        {/* <p className="discount">Sale - {sale} </p> */}
      </div>
      {/* <button onClick={() => filterCardsByBases()}>GO BUY</button> */}
    </div>
  );
}

export default BasesCard;
