import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import useBasesFiltersStore from "../stores/basesFiltersStore.js";
import useAuthStore from "../stores/userStore.js";
import BasesCard from "../components/BasesCard";
import { fetchBases, filterData } from "../utils/basesUtils.js";
import BasesFilters from "../components/BasesFilters.jsx";
import { createUniqueOptions } from "../utils/filterUtils.js";
import { countryCodes } from "../utils/ordersUtils";
import Loader from "./Loader.jsx";
import { useTranslation } from "react-i18next";

function BasesViewBuyer() {
  const {t} = useTranslation()
  const [filteredData, setFilteredData] = useState(undefined);
  const [originalData, setOriginalData] = useState(undefined);
  const { filters, setFilters } = useBasesFiltersStore();
  const { key, role } = useAuthStore((state) => ({
    key: state.key,
    role: state.role,
  }));
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const { data, isLoading } = useQuery({
    queryKey: [key],
    queryFn: fetchBases,
  });
  // useEffect(() => {
  //   if (data && data.bases_list) {
  //     const filtered = filterData(data.bases_list, filters);
  //     setFilteredData(filtered);
  //   }
  // }, [filters, data]);
  useEffect(() => {
    if (data && data.bases_list) {
      const initialData = filterData(data.bases_list, filters);
      setOriginalData(initialData);
      setFilteredData(initialData);
    }
  }, [filters, data]);

  const applySort = (filtered, key, direction) => {
    if (!key || !direction) return filtered;
    return filtered.sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  if (isLoading || !data) {
    return <Loader />;
  }

  const handleFilterChange = (key, value) => {
    setFilters({ [key]: value });
  };

  const refOptions = [
    { value: "", label: "All" },
    { value: "0", label: "No" },
    { value: "1", label: "Yes" },
    { value: "2", label: "AVS" },
  ];

  const handleMultiSelectChange = (key) => (selectedOptions) => {
    const values = selectedOptions.map((option) => option);
    handleFilterChange(key, values);
  };
  const handleOneSelectChange = (key) => (selectedOption) => {
    const value = selectedOption;
    handleFilterChange(key, value);
  };
  const countryOptions = Object.entries(countryCodes).map(([label, value]) => {
    return { value, label };
  });
  const sellerOptions = data
    ? createUniqueOptions(data.bases_list, "seller")
    : [];
  const basesOptions = data
    ? createUniqueOptions(data.bases_list, "basename")
    : [];
  const handleSort = (key, direction) => {
    if (direction === null) {
      // Reset to original data when sort is cancelled
      setFilteredData(originalData);
    } else {
      const sortedData = applySort([...originalData], key, direction);
      setFilteredData(sortedData);
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="bases-page__container">
      <h1>{t("Bases")}</h1>
      <BasesFilters
        handleSort={handleSort}
        sortConfig={sortConfig}
        countryOptions={countryOptions}
        setFilteredData={setFilteredData}
        sellerOptions={sellerOptions}
        basesOptions={basesOptions}
        handleMultiSelectChange={handleMultiSelectChange}
        handleOneSelectChange={handleOneSelectChange}
        refOptions={refOptions}
      />
      <div className="bases-cards_cont">
        {filteredData?.length == 0 && <div className="noDataDiv">No data</div>}
        {filteredData?.map((base, index) => (
          <BasesCard
            key={index}
            rate={base.rate}
            name={base.basename}
            date={base.upload_date}
            seller={base.seller}
            sellerId={base.sellerId}
            sale={base.sale}
            soldProgress={base.sold_progress}
            validProgress={base.valid}
            price={base.price}
            info={base.info}
            total={base.count_card}
            country={base.country}
            status={base.ref}
          />
        ))}
      </div>
    </div>
  );
}

export default BasesViewBuyer;
