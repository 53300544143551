import axios from "axios";
import { API_URL } from "../apiConf";

export const fetchProfileInfo = async ({ queryKey }) => {
  const key = queryKey[1];
  const { data } = await axios.get(`${API_URL}/api/user_profile/?key=${key}`);
  return data;
};

export const changePassword = async (formData, key) => {
  try {
    const response = await axios.post(
      `${API_URL}/api/change_pass/?key=${key}`,
      {
        ...formData,
      }
    );

    console.log(response);
    return response;
  } catch (error) {
    console.error(
      "change pwd error:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const changeContacts = async (formData, key) => {
  try {
    const response = await axios.post(
      `${API_URL}/api/change_contacts/?key=${key}`,
      {
        ...formData,
      }
    );

    console.log(response);
    return response;
  } catch (error) {
    console.error(
      "change pwd error:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const makeCode = async (key, amount) => {
  const response = await axios.post(
    `${API_URL}/api/user_profile/?key=${key}&gift_amount=${amount}`
  );
  return response;
};
export const useCode = async (key, code) => {
  const response = await axios.post(
    `${API_URL}/api/user_profile/?key=${key}&gift_code=${code}`
  );
  return response;
};
