import axios from "axios";
import {
  API_URL
} from "../apiConf";

export const editBase = async (key, id, type, change) => {
  try {
    let changename, value;
    console.log("change", change);

    if (type === 'ref' && change) {
      changename = "ref";
      value = change;
      // value = change.value;
    } else if (type === 'sale') {
      changename = "sale";
      value = change;
    } else if (type === 'avs') {
      changename = "avs";
      value = change;
    } else {
      throw new Error("Unknown change type or undefined change value");
    }

    const response = await axios.post(`${API_URL}/api/seller/base_info/?key=${key}&id=${id}&${changename}=${value}`);
    return response.data;
  } catch (error) {
    console.error("change pwd error:", error.response ? error.response.data : error);
    throw error;
  }
};
export const getBase = async ({
  queryKey
}) => {
  const [key, id] = queryKey;
  try {
    const response = await axios.get(
      `${API_URL}/api/seller/base_info/?key=${key}&id=${id}`
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.error(
      "change pwd error:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const getCards = async ({
  queryKey
}) => {
  const [key, id] = queryKey;
  try {
    const response = await axios.get(
      `${API_URL}/api/seller/cards_list_base/?key=${key}&id=${id}`
    );
    console.log(response);
    console.log("llllll");
    return response.data;
  } catch (error) {
    console.error("id error:", error.response ? error.response.data : error);
    throw error;
  }
};

export const editBaseStatus = async (key, id, change) => {
  try {
    const response = await axios.post(
      `${API_URL}/api/seller/base_info/?key=${key}&id=${id}&post_status=${change}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "change pwd error:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};
export const updatePricesBase = async (key, prices, id) => {
  try {
    let url = `${API_URL}/api/seller/base_info/?key=${key}&id=${id}`;
    // const response = await axios.post(url, prices);
    const response = await axios.post(url, {
      prices
    });

    return response.data;
  } catch (error) {
    console.error("Update prices error:", error.response ? error.response.data : error);
    throw error;
  }
}