import {
    create
} from 'zustand';


const useOrdersFiltersStore = create((set) => ({
    filters: {
        updateFrom: null,
        updateTo: null,
        bins: [],
        sellers: [],
        bases: [],
        refund: [],
        countries: [],
    },
    setFilters: (newFilters) => set((state) => {
        console.log(newFilters)
        return {
            filters: {
                ...state.filters,
                ...newFilters
            }
        };
    }),

}))

export default useOrdersFiltersStore;