import React, { useEffect } from "react";
import useCardsFiltersStore from "../stores/buyCardsFiltersStore.js";
import CustomTextarea from "../components/CustomTextarea";
import MultiSelect from "../components/MultiSelect.jsx";
import CustomBlueButton from "../components/CustomBlueButton";
import CustomCheckBox from "./CustomCheckBox.jsx";
import { useTranslation } from "react-i18next";
function BuyCardsFilters({
  setFilteredData,
  handleInputChange,
  handleMultiSelectChange,
  bankOptions,
  typeOptions,
  vendorOptions,
  levelOptions,
  baseOptions,
  sellerOptions,
  countryOptions,
  cityOptions,
  cvvOptions,
  refOptions,
  stateOptions,
  handleOneSelectChange,
  filterData,
  cardsList,
}) {
  const { t } = useTranslation();
  const { filters, setFilters } = useCardsFiltersStore();

  const [resetCounter, setResetCounter] = React.useState(0);
  const resetFilters = () => {
    setFilters({
      bins: "",
      banks: [],
      types: [],
      countries: [],
      states: [],
      sellers: [],
      bases: [],
      zips: "",
      vendors: [],
      levels: [],
      cities: [],
      baseValidRange: [0, 100],
      priceFrom: "",
      priceTo: "",
      cvv: undefined,
      refund: [],
      address: false,
      phone: false,
      holder: false,
      snn: false,
      dob: false,
      dl: false,
      email: false,
      ip: false,
      ua: false,
      password: false,
    });
    setFilteredData(cardsList);
    setResetCounter((prev) => prev + 1);
  };

  const handleCheckboxChange = (key) => (event) => {
    setFilters({ ...filters, [key]: event.target.checked });
  };
  const handleValidChange = (newValue) => {
    setFilters({ baseValidRange: newValue });
  };

  return (
    <div className="BuyCards__cont">
      <div className="heading_filters deskFilters">
        <CustomTextarea
          key={`bins-textarea-${resetCounter}`}
          value={filters.bins}
          onChange={handleInputChange("bins")}
          label={"Bins"}
          placeholder={t("Enter")}
          width={"100%"}
          // width={"270px"}
          height={"100%"}
        />
        <div className="heading_filters__block">
          <MultiSelect
            key={`multi-select-banks-${resetCounter}`}
            placeholder={t("Banks")}
            options={bankOptions}
            onChange={handleMultiSelectChange("banks")}
          />
          <MultiSelect
            key={`multi-select-Type-${resetCounter}`}
            placeholder={t("Type")}
            options={typeOptions}
            onChange={handleMultiSelectChange("types")}
          />
          <MultiSelect
            key={`multi-select-Country-${resetCounter}`}
            placeholder={t("Country")}
            options={countryOptions}
            onChange={handleMultiSelectChange("countries")}
          />
          <MultiSelect
            key={`multi-select-State-${resetCounter}`}
            placeholder={t("State")}
            options={stateOptions}
            onChange={handleMultiSelectChange("states")}
          />
        </div>
        <CustomTextarea
          label={t("Zips")}
          key={`zips-textarea-${resetCounter}`}
          value={filters.zips}
          onChange={handleInputChange("zips")}
          placeholder={t("Enter")}
          width={"100%"}
          // width={"270px"}
          // height={"255px"}
        />
        <div className="heading_filters__block">
          <MultiSelect
            key={`multi-select-Seller-${resetCounter}`}
            placeholder={t("Seller")}
            options={sellerOptions}
            value={filters.sellers.map((seller) => ({
              value: seller,
              label: seller,
            }))}
            onChange={handleMultiSelectChange("sellers")}
          />
          <MultiSelect
            key={`multi-select-Base-${resetCounter}`}
            placeholder={t("Base")}
            value={filters.bases.map((base) => ({
              value: base,
              label: base,
            }))}
            options={baseOptions}
            onChange={handleMultiSelectChange("bases")}
          />
          <MultiSelect
            key={`multi-select-Vendor-${resetCounter}`}
            placeholder={t("Vendor")}
            options={vendorOptions}
            onChange={handleMultiSelectChange("vendors")}
          />
          <MultiSelect
            key={`multi-select-Level-${resetCounter}`}
            placeholder={t("Level")}
            options={levelOptions}
            onChange={handleMultiSelectChange("levels")}
          />
        </div>

        {/* <div className="heading_filters__block">
          
        </div> */}
        <div className="heading_filters__block">
          <MultiSelect
            key={`multi-select-City-${resetCounter}`}
            placeholder={t("City")}
            options={cityOptions}
            onChange={handleMultiSelectChange("cities")}
          />
          <div className="prices_block">
            <CustomTextarea
              label={t("Price from")}
              value={filters.priceFrom}
              onChange={handleInputChange("priceFrom")}
              placeholder={"$"}
              width={"100%"}
              height={"40px"}
            />
            <span
              style={{
                fontSize: "24px",
                lineHeight: "22px",
                fontWeight: "800",
                margin: "0 5px",
                paddingTop: "22px",
              }}
            >
              -
            </span>
            <CustomTextarea
              label={t("Price to")}
              value={filters.priceTo}
              onChange={handleInputChange("priceTo")}
              placeholder={"$"}
              width={"100%"}
              height={"40px"}
            />
          </div>
          <MultiSelect
            key={`multi-select-Refund-${resetCounter}`}
            placeholder={t("Refund")}
            options={refOptions}
            isMulti={false}
            isClearable={false}
            onChange={handleOneSelectChange("refund")}
          />
          <MultiSelect
            key={`multi-select-CVV-${resetCounter}`}
            placeholder={"CVV"}
            options={cvvOptions}
            isMulti={false}
            onChange={handleOneSelectChange("cvv")}
          />
        </div>
      </div>
      <div className="filters_phone">
        <div className="filters_phone__block">
          <CustomTextarea
            key={`bins-textarea-${resetCounter}`}
            value={filters.bins}
            onChange={handleInputChange("bins")}
            label={"Bins"}
            placeholder={t("Enter")}
            width={"170px"}
            height={"170px"}
          />
          <CustomTextarea
            label={"Zips"}
            value={filters.zips}
            onChange={handleInputChange("zips")}
            placeholder={t("Enter")}
            width={"170px"}
            height={"170px"}
          />
        </div>

        <div className="filters_phone__block">
          <MultiSelect
            key={`multi-select-banks-${resetCounter}`}
            placeholder={t("Banks")}
            options={bankOptions}
            onChange={handleMultiSelectChange("banks")}
          />
          <MultiSelect
            key={`multi-select-Type-${resetCounter}`}
            placeholder={t("Type")}
            options={typeOptions}
            onChange={handleMultiSelectChange("types")}
          />
        </div>
        <div className="filters_phone__block">
          <MultiSelect
            key={`multi-select-Country-${resetCounter}`}
            placeholder={t("Country")}
            options={countryOptions}
            onChange={handleMultiSelectChange("countries")}
          />
          <MultiSelect
            key={`multi-select-State-${resetCounter}`}
            placeholder={t("State")}
            options={stateOptions}
            onChange={handleMultiSelectChange("states")}
          />
        </div>
        <div className="filters_phone__block">
          <MultiSelect
            key={`multi-select-Seller-${resetCounter}`}
            placeholder={t("Seller")}
            options={sellerOptions}
            value={filters.sellers.map((seller) => ({
              value: seller,
              label: seller,
            }))}
            onChange={handleMultiSelectChange("sellers")}
          />
          <MultiSelect
            key={`multi-select-Base-${resetCounter}`}
            placeholder={t("Base")}
            value={filters.bases.map((base) => ({
              value: base,
              label: base,
            }))}
            options={baseOptions}
            onChange={handleMultiSelectChange("bases")}
          />
        </div>

        <div className="filters_phone__block">
          <MultiSelect
            key={`multi-select-Vendor-${resetCounter}`}
            placeholder={t("Vendor")}
            options={vendorOptions}
            onChange={handleMultiSelectChange("vendors")}
          />
          <MultiSelect
            key={`multi-select-Level-${resetCounter}`}
            placeholder={t("Level")}
            options={levelOptions}
            onChange={handleMultiSelectChange("levels")}
          />
        </div>
        <div className="filters_phone__block">
          <MultiSelect
            key={`multi-select-City-${resetCounter}`}
            placeholder={t("City")}
            options={cityOptions}
            onChange={handleMultiSelectChange("cities")}
          />
          <MultiSelect
            key={`multi-select-Refund-${resetCounter}`}
            placeholder={t("Refund")}
            options={refOptions}
            isMulti={false}
            isClearable={false}
            onChange={handleOneSelectChange("refund")}
          />
        </div>
        <div className="filters_phone__block filterOne">
          {/* <RangeProgressBar
            value={filters.baseValidRange}
            onChange={handleValidChange}
          /> */}
        </div>
        <div className="prices_block filters_phone__block">
          <CustomTextarea
            label={t("Price from")}
            value={filters.priceFrom}
            onChange={handleInputChange("priceFrom")}
            placeholder={"$"}
            width={"155px"}
            height={"85px"}
          />
          <span
            style={{
              fontSize: "24px",
              lineHeight: "22px",
              fontWeight: "800",
              margin: "0 5px",
            }}
          >
            -
          </span>
          <CustomTextarea
            label={t("Price to")}
            value={filters.priceTo}
            onChange={handleInputChange("priceTo")}
            placeholder={"$"}
            width={"155px"}
            height={"85px"}
          />
        </div>
      </div>
      <div className="checkBox_cont">
        <CustomCheckBox
          label={t("Address")}
          checked={filters.address}
          onChange={handleCheckboxChange("address")}
        />
        <CustomCheckBox
          label="SNN"
          checked={filters.snn}
          onChange={handleCheckboxChange("snn")}
        />
        <CustomCheckBox
          label="Email"
          checked={filters.email}
          onChange={handleCheckboxChange("email")}
        />
        {/* <CustomCheckBox
          label="NO CVV"
          checked={filters.cvv}
          onChange={handleCheckboxChange("cvv")}
        /> */}

        <CustomCheckBox
          label={t("Password")}
          checked={filters.password}
          onChange={handleCheckboxChange("password")}
        />
        <CustomCheckBox
          label={"DOB"}
          checked={filters.dob}
          onChange={handleCheckboxChange("dob")}
        />
        <CustomCheckBox
          label={"UA"}
          checked={filters.ua}
          onChange={handleCheckboxChange("ua")}
        />
        <CustomCheckBox
          label={"DL"}
          checked={filters.dl}
          onChange={handleCheckboxChange("dl")}
        />
        <CustomCheckBox
          label={"IP"}
          checked={filters.ip}
          onChange={handleCheckboxChange("ip")}
        />
        <CustomCheckBox
          label={t("Holder")}
          checked={filters.holder}
          onChange={handleCheckboxChange("holder")}
        />
        <CustomCheckBox
          label={t("Phone")}
          checked={filters.phone}
          onChange={handleCheckboxChange("phone")}
        />
        {/* <CustomCheckBox
          label={t("Discount")}
          onChange={() => {}}
          labelColor={"#FA6400"}
        /> */}
        {/* <CustomCheckBox
          label={"AVS"}
          onChange={() => {}}
          labelColor={"#E14E4E"}
        /> */}
      </div>
      <div className="filters_btns">
        <CustomBlueButton
          label={t("Clear")}
          onClick={() => resetFilters()}
          height={"37px"}
          width={"80px"}
          color="lightBlue"
          fontSize={"16px"}
          borderRadius={"10px"}
        />
        {/* <CustomBlueButton
          fontSize={"16px"}
          label={"Search"}
          onClick={() => setFilteredData(filterData(cardsList, filters))}
          height={"57px"}
          width={"270px"}
          color="blue"
        /> */}
      </div>
    </div>
  );
}

export default BuyCardsFilters;
