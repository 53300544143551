import React from "react";

export default function Rules() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.5 0.872131C3.50155 0.390201 3.89271 0 4.375 0H8.75C9.23229 0 9.62345 0.390201 9.625 0.872131H10.082C11.7388 0.872131 13.082 2.21528 13.082 3.87213V11C13.082 12.6569 11.7388 14 10.082 14H3C1.34315 14 0 12.6569 0 11V3.87213C0 2.21528 1.34315 0.872131 3 0.872131H3.5ZM3 1.87213H10.082C11.1865 1.87213 12.082 2.76756 12.082 3.87213V11C12.082 12.1046 11.1865 13 10.082 13H3C1.89543 13 1 12.1046 1 11V3.87213C1 2.76756 1.89543 1.87213 3 1.87213ZM3.5 6.5C3.22386 6.5 3 6.72386 3 7C3 7.27614 3.22386 7.5 3.5 7.5H9.8C10.0761 7.5 10.3 7.27614 10.3 7C10.3 6.72386 10.0761 6.5 9.8 6.5H3.5ZM3 9.8C3 9.52386 3.22386 9.3 3.5 9.3H9.8C10.0761 9.3 10.3 9.52386 10.3 9.8C10.3 10.0761 10.0761 10.3 9.8 10.3H3.5C3.22386 10.3 3 10.0761 3 9.8Z"
        fill="white"
      />
    </svg>
  );
}
