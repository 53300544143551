import Cards from "../../assets/icons/Cards";
import Deposit from "../../assets/icons/Deposit";
import News from "../../assets/icons/News";
import Seller from "../../assets/icons/Seller";
import Sellers from "../../assets/icons/Sellers";
import Staff from "../../assets/icons/Staff";
import Statistic from "../../assets/icons/Statistic";
import Tools from "../../assets/icons/Tools";
import Support from "../../assets/icons/Support";
import Info from "../../assets/icons/Info";
import Admin from "../../assets/icons/Admin";
import Upload from "../../assets/icons/Upload";
import Bases from "../../assets/icons/Bases";
import Rules from "../../assets/icons/Rules";
import { RiUserAddFill } from "react-icons/ri";

export const headerItems = [
  {
    link: "/",
    icon: <News />,
    title: "News",
  },
  {
    link: "/cards",
    icon: <Cards />,
    title: "Cards",
    dropDownItems: [
      { title: "Cards", link: "/cards" },
      { title: "Bases", link: "/bases" },
      { title: "Preorder", link: "/preorder" },
      { title: "History", link: "/orders" },
    ],
  },
  {
    link: "/sellers",
    icon: <Sellers />,
    title: "Sellers",
  },
  {
    link: "/card-checker",
    icon: <Tools />,
    title: "Tools",
    dropDownItems: [
      // { title: "Services", link: "/services" },
      { title: "Card Checker", link: "/card-checker" },
      { title: "BINs", link: "/bins" },
    ],
  },
  {
    link: "/support",
    icon: <Support />,
    title: "Support",
  },
  {
    link: "/statistic",
    icon: <Statistic />,
    title: "Statistic",
  },
  {
    link: "/deposit",
    icon: <Deposit />,
    title: "Deposit",
  },
  {
    link: "/faq",
    icon: <Info />,
    title: "Info",
    dropDownItems: [
      { title: "FAQ", link: "/faq" },
      { title: "Rules", link: "/rules" },
    ],
  },
  // {
  //   link: "/seller",
  //   icon: <Seller />,
  //   title: "Seller",
  // },
  // {
  //   link: "/admin",
  //   icon: <Admin />,
  //   title: "Admin",
  // },
  // {
  //   link: "/staff",
  //   icon: <Staff />,
  //   title: "Staff",
  // },
];

export const sellerHeaderItems = [
  {
    link: "/upload",
    icon: <Upload />,
    title: "Upload",
  },
  {
    link: "/",
    icon: <Bases />,
    title: "Bases",
  },
  {
    link: "/withdrawal",
    icon: <Deposit />,
    title: "Withdraw",
  },
  {
    link: "/support",
    icon: <Support />,
    title: "Support",
  },
  {
    link: "/invite",
    icon: <RiUserAddFill size={16} />,
    title: "Invite",
  },

  {
    link: "/rules",
    icon: <Rules />,
    title: "Rules",
  },
  {
    link: "/faq",
    icon: <Info />,
    title: "FAQ",
  },

  {
    link: "/statistic",
    icon: <Seller />,
    title: "Statistic",
  },
];

export const drawerItems = [
  {
    link: "/",
    icon: <News />,
    title: "News",
  },

  { title: "Cards", link: "/cards" },
  { title: "Bases", link: "/bases" },
  { title: "Preorder", link: "/preorder" },
  { title: "History", link: "/orders" },
  {
    link: "/sellers",
    icon: <Sellers />,
    title: "Sellers",
  },

  // { title: "Services", link: "/services" },
  { title: "Card Checker", link: "/card-checker" },
  { title: "BINs", link: "/bins" },
  {
    link: "/support",
    icon: <Support />,
    title: "Support",
  },
  {
    link: "/statistic",
    icon: <Statistic />,
    title: "Statistic",
  },
  {
    link: "/deposit",
    icon: <Deposit />,
    title: "Deposit",
  },
  { title: "FAQ", link: "/faq" },
  { title: "Rules", link: "/rules" },
];
