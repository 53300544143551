import React, { useState } from "react";
import CustomPopup from "../components/CustomPopup";
import { useForm } from "react-hook-form";
import "../styles/components/ChangePasswordPopup.scss";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import CustomBlueButton from "./CustomBlueButton";
import { changePassword, makeCode, useCode } from "../utils/profileUtils";
import useAuthStore from "../stores/userStore.js";
import { useTranslation } from "react-i18next";
function GiftCodesComponent({
  openNotification,
  isLoadingBtn,
  setLoadingForButton,
}) {
  const { t } = useTranslation();
  const { key } = useAuthStore((state) => ({ key: state.key }));
  const queryClient = useQueryClient();
  const [amount, setAmount] = useState("");
  const [code, setCode] = useState("");

  const fetchMakeCode = async (amount) => {
    let res = await makeCode(key, amount);
    setLoadingForButton(`makeCode`, true);
    if (res.data.ok === true) {
      queryClient.invalidateQueries(["call"]);
      setLoadingForButton(`makeCode`, false);
    } else {
      openNotification("error", "Make code error", res.data.error, 3000);
      setLoadingForButton(`makeCode`, false);
    }
    setAmount("");
  };

  const fetchUseCode = async (code) => {
    setLoadingForButton(`useCode`, true);
    let res = await useCode(key, code);
    if (res.data.ok === true) {
      queryClient.invalidateQueries(["call"]);
      setLoadingForButton(`useCode`, false);
    } else {
      openNotification("error", "Use code error", res.data.error, 3000);
      setLoadingForButton(`useCode`, false);
    }
    setCode("");
  };

  return (
    <div>
      <div className="info-box">
        <div className="box-item">
          <span
            className="box-item_bold"
            style={{ height: "26px", display: "flex", alignItems: "center" }}
          >
            {t("Create Gift Code")}
          </span>
        </div>
        <div className={"password_cont"}>
          <div className="password_cont-leftSide">
            <span>{t("Amount")} ($)</span>
          </div>
          <div className="codeInputs_cont">
            <input value={amount} onChange={(e) => setAmount(e.target.value)} />
            <CustomBlueButton
              label={t("Make code")}
              onClick={() => fetchMakeCode(amount)}
              height={"37px"}
              isLoading={isLoadingBtn[`makeCode`]}
              borderRadius={"10px"}
              disabled={amount.length == 0}
              width={"112px"}
            />
          </div>
        </div>
        <div className={"password_cont"}>
          <div className="password_cont-leftSide">
            <span>{t("Use code")}</span>
          </div>
          <div className="codeInputs_cont">
            <input value={code} onChange={(e) => setCode(e.target.value)} />
            <CustomBlueButton
              label={t("Use code")}
              isLoading={isLoadingBtn[`useCode`]}
              onClick={() => fetchUseCode(code)}
              disabled={code.length == 0}
              height={"37px"}
              borderRadius={"10px"}
              width={"112px"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GiftCodesComponent;
