import axios from "axios";
import { API_URL } from "../apiConf";

export const getCheckTable = async ({ queryKey }) => {
  const key = queryKey[1];
  const response = await axios.get(`${API_URL}/api/check_table/?key=${key}`);

  return response.data;
};
export const checkCard = async (formData, key) => {
  const response = await axios.get(
    `${API_URL}/api/check_form/?key=${key}&cc=${formData.cc}&cvv=${formData.cvv}&date=${formData.date}`
  );
  return response.data;
};
export const checkBins = async (bins, key) => {
  const response = await axios.get(
    `${API_URL}/api/bin_lookup/?key=${key}&bin=${bins}`
  );
  return response.data;
};
export const checkZips = async (zips, key) => {
  const response = await axios.get(
    `${API_URL}/api/zip_lookup/?key=${key}&zip=${zips}`
  );
  return response.data;
};
