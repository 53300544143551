import {
    create
} from 'zustand';


const useBasesFiltersStore = create((set) => ({
    filters: {
        updateFrom: null,
        updateTo: null,
        countries: [],
        sellers: [],
        bases: [],
        refund: [],

        cvv: false,
        address: false,
        phone: false,
        holder: false,
        snn: false,
        dob: false,
        dl: false,
        email: false,
        ip: false,
        ua: false,
        password: false,
    },
    setFilters: (newFilters) => set((state) => {
        console.log(newFilters)
        return {
            filters: {
                ...state.filters,
                ...newFilters
            }
        };
    }),

}))

export default useBasesFiltersStore;