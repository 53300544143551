import React from "react";
import "../styles/components/DatePicker.scss";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey(
  "df709ea9aed9c1b85cd478a86ebc6abaTz04NDkwNyxFPTE3NDAyNTE5NzkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
function DatePicker({ onDateChange }) {
  const [value, setValue] = React.useState([null, null]);
  const handleDateChange = (newValue) => {
    setValue(newValue);
    onDateChange(newValue);
  };
  return (
    <div className="DatePicker">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="labels_cont">
          <p>Updated from</p>
          <p style={{ marginLeft: "70px" }}>Updated to</p>
        </div>
        <img
          src="/assets/icons/icon-calendar.svg"
          className="iconCalendar left"
        />
        <img
          src="/assets/icons/icon-calendar.svg"
          className="iconCalendar right"
        />

        <DateRangePicker
          value={["MM/DD/YYYY", "MM/DD/YYYY"]}
          onChange={handleDateChange}
        />
      </LocalizationProvider>
    </div>
  );
}

export default DatePicker;
