function formatDate(dateString) {
  const date = new Date(dateString);
  const today = new Date();

  const isToday = date.toDateString() === today.toDateString();

  // if (isToday) {
  //   return date.toTimeString().split(":").slice(0, 2).join(":");
  // }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}/${month}/${day} ${hours}:${minutes}`;
}

function MessageComponent({ user, text, date, isNotSystem }) {
  const formattedDate = formatDate(date);

  return (
    <>
      {isNotSystem === true ? (
        <div
          className={
            user === "user" ? "messageItem userMessage" : "messageItem"
          }
        >
          <img
            src={
              user === "user"
                ? "/assets/icons/icon-profile.svg"
                : "/assets/icons/icon-support.svg"
            }
            alt="icon-user"
          />
          <div className="messege_text">
            <p>{text}</p>
            <span>{formattedDate}</span>
          </div>
        </div>
      ) : (
        <div className="systemMessage">
          <p>{text}</p>
        </div>
      )}
    </>
  );
}

export default MessageComponent;
