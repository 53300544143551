import React, { useEffect } from "react";
import "../styles/components/TermsPopup.scss";
function CustomPopup({ isOpened, setIsOpened, children, label }) {
  useEffect(() => {
    if (isOpened) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpened]);

  return (
    <div className={isOpened ? "popup opened" : "popup"}>
      <div className="overlay" onClick={() => setIsOpened(false)}></div>
      <div className="popup_content">
        <div className="popup_header">
          <h1 className="popupH1">{label}</h1>
          <button onClick={() => setIsOpened(false)}>
            <img src="/assets/icons/icon-close-cross.svg" alt="" />
          </button>
        </div>
        <div className="popup_text">{children}</div>
      </div>
    </div>
  );
}

export default CustomPopup;
