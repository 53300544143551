import React from "react";
import "../../styles/components/CustomTable.scss";
import { Table, ConfigProvider } from "antd";

const CustomTable = ({
  columns,
  data,
  rowSelection,
  pageSize,
  pagination = true,
  rowKey = "id",
}) => (
  <ConfigProvider
    theme={{
      components: {
        Table: {
          headerSplitColor: "transparent",
          // cellPaddingBlock: 10,
          // cellPaddingInline: 10,
          // cellHeight: 25,
        },
      },
    }}
  >
    <Table
      className="custom-table"
      columns={columns}
      dataSource={data}
      rowKey={rowKey}
      pagination={
        pagination
          ? {
              position: ["bottomLeft"],
              // showTotal: (total, range) => `Total: ${total} `,
              pageSize: pageSize,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
            }
          : false
      }
      rowSelection={rowSelection}
    />
  </ConfigProvider>
);
export default CustomTable;
