import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import CustomBlueButton from "../components/CustomBlueButton";
import { Link, useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCaptcha } from "../services/captchaService";
import "../styles/pages/LoginPage.scss";
import axios from "axios";
import { API_URL } from "../apiConf";
import { fetchRegister } from "../services/authService";
import CustomPopup from "../components/CustomPopup";
import Loader from "../components/Loader";
import logo from "./../assets/images/logo.png";
import NewItemContent from "../components/NewItemContent";

const getRules = async () => {
  const rulesData = await axios.get(`${API_URL}/api/get_rules/`);
  return rulesData;
};

function RegPage() {
  const [isOpened, setIsOpened] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    data: captchaData,
    isLoading,
    error,
    refetch: refetchCaptcha,
  } = useQuery({
    queryKey: ["captcha"],
    queryFn: fetchCaptcha,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    mode: "onChange",
  });

  const { data: rules } = useQuery({
    queryKey: ["getRules"],
    queryFn: getRules,
  });

  useEffect(() => {
    if (captchaData?.captcha_id) {
      setValue("captcha_id", captchaData.captcha_id);

      setValue("captcha", "");
    }
  }, [captchaData, setValue]);

  const onSubmit = async ({ repeatPassword, termsOfService, ...formData }) => {
    console.log(formData);
    try {
      clearErrors();
      await fetchRegister(formData);
      navigate("/", { replace: true });
      // reset();
    } catch (error) {
      // console.error("Ошибка при регистрации:", error.response.data);

      if (error.response && error.response.data.bad) {
        error.response.data.bad.forEach((fieldError) => {
          setError(fieldError, {
            type: "server",
            message: error.response.data.error,
          });
        });
      }
      if ((error.response.data.error = "Bad captcha")) {
        const newCaptchaData = {
          ...captchaData,
          ...error.response.data,
        };
        queryClient.setQueryData(["captcha"], newCaptchaData);

        setValue("captcha_id", newCaptchaData.captcha_id);
        setValue("captcha", "");
      }
    }
  };

  if (isLoading)
    return (
      <div className="loginPage">
        <Loader />
      </div>
    );
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <div className="loginPage">
      <img src={logo} alt="logo" width={135} />

      <div className="formContainer">
        <p>Register</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            minLength="3"
            {...register("username", {
              required: "This field is required",
              minLength: {
                value: 3,
                message: "Username must be at least 3 characters long",
              },
            })}
            className={errors.username ? "formInp errorInp" : "formInp"}
            placeholder="Username"
          />
          {errors.username && (
            <span className="errorSpan">{errors.username.message}</span>
          )}
          <input
            type="hidden"
            {...register("captcha_id")}
            value={captchaData?.captcha_id}
          />
          <input
            {...register("password", {
              required: "This field is required",
              minLength: {
                value: 8,
                message: "Password must be at least 8 characters long",
              },
            })}
            className={errors.password ? "formInp errorInp" : "formInp "}
            type="password"
            placeholder="Password"
          />
          {errors.password && (
            <span className="errorSpan">{errors.password.message}</span>
          )}
          <input
            {...register("repeatPassword", {
              required: "This field is required",

              validate: (value) =>
                value === getValues("password") || "The passwords do not match",
            })}
            className={errors.repeatPassword ? "formInp errorInp" : "formInp"}
            placeholder="Repeat Password"
            type="password"
          />
          {errors.repeatPassword && (
            <span className="errorSpan">{errors.repeatPassword.message}</span>
          )}
          <input
            {...register("jabber", {
              validate: {
                requiredIfTelegramEmpty: (value) =>
                  value || getValues("telegram")
                    ? true
                    : "Either Jabber or Telegram is required",
              },
            })}
            className={errors.jabber ? "formInp errorInp" : "formInp"}
            placeholder="Jabber"
          />
          {errors.jabber && (
            <span className="errorSpan">{errors.jabber.message}</span>
          )}
          <input
            {...register("telegram", {
              validate: {
                requiredIfJabberEmpty: (value) =>
                  value || getValues("jabber")
                    ? true
                    : "Either Telegram or Jabber is required",
              },
            })}
            className={errors.telegram ? "formInp errorInp" : "formInp"}
            placeholder="Telegram"
          />
          {errors.telegram && (
            <span className="errorSpan">{errors.telegram.message}</span>
          )}
          <input
            {...register("invcode", { required: false })}
            className={errors.invcode ? "formInp errorInp" : "formInp "}
            placeholder="Invite code"
          />
          {errors.invcode && (
            <span className="errorSpan">This field is required</span>
          )}

          <div className="captcha_block">
            <input
              {...register("captcha", { required: true })}
              className={errors.captcha ? "formInp errorInp" : "formInp "}
              placeholder="Enter Captcha"
              style={{ width: "100%", marginTop: "0" }}
            />
            <img
              className="captchaImg"
              src={`data:image/jpeg;base64, ${captchaData?.img}`}
              alt="captcha"
            />
          </div>
          <button
            type="button"
            className="refetchBtn"
            style={{
              marginTop: "10px",
              color: "rgba(37, 144, 238, 1)",
              fontWeight: "500",
              border: "none",
              fontSize: "15px",
            }}
            onClick={() => refetchCaptcha()}
          >
            reset captcha
          </button>
          {errors.captcha && (
            <span className="errorSpan">{errors.captcha.message}</span>
          )}
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div
              className={
                errors.termsOfService
                  ? "termsCheckbox errorCheck"
                  : "termsCheckbox"
              }
            >
              <input
                type={"checkbox"}
                {...register("termsOfService", {
                  required: "You must agree to the terms of service",
                })}
              />
              <span onClick={() => setIsOpened(true)}>
                Terms of service agreement
              </span>
            </div>
            <Link to={"/login"} className="btn_password_reset">
              Back to login page
            </Link>
          </div>

          <div className="form_btns_cont" style={{ marginTop: "15px" }}>
            <CustomBlueButton
              type="submit"
              // onClick={handleSubmit()}
              label={"Register"}
              color={"rgba(37, 144, 238, 1)"}
              height={"37px"}
              fontSize={"15px"}
              width={"100%"}
            />
          </div>
        </form>
      </div>
      <CustomPopup
        isOpened={isOpened}
        setIsOpened={setIsOpened}
        label={"Terms of service"}
      >
        {/* <span>{rules?.data.rules}</span> */}
        <NewItemContent text={rules?.data?.rules} />
      </CustomPopup>
    </div>
  );
}

export default RegPage;
