import "../styles/pages/LoginPage.scss";
import { useForm } from "react-hook-form";
import React, { useState, useEffect } from "react";
import { Checkbox } from "antd";
import { fetchCaptcha } from "../services/captchaService";
import CustomBlueButton from "../components/CustomBlueButton";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchLogin } from "../services/authService";
import Loader from "../components/Loader";
import logo from "./../assets/images/logo.png";

function LoginPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [captchaData, setCaptchaData] = useState({
    img: "",
    captcha_id: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (captchaData.captcha_id) {
      setValue("captcha_id", captchaData.captcha_id);

      setValue("captcha", "");
    }
  }, [captchaData, setValue]);

  const refetchCaptcha = async () => {
    const res = await fetchCaptcha();
    setCaptchaData({
      captcha_id: res.captcha_id,
      img: res.img,
    });
    // setValue("captcha_id", res.captcha_id);
    // setValue("captcha", "");
  };

  const onSubmit = async (formData) => {
    setIsLoading(true);
    try {
      clearErrors();
      await fetchLogin(formData);
      navigate("/", { replace: true });
      // reset();
    } catch (error) {
      setCaptchaData({
        captcha_id: error.response.data.captcha_id,
        img: error.response.data.img,
      });

      setIsLoading(false);

      // setValue("captcha_id", error.response.data.captcha_id);
      // setValue("captcha", "");

      if (error.response && error.response.data.bad) {
        error.response.data.bad.forEach((fieldError) => {
          setError(fieldError, {
            type: "server",
            message: error.response.data.error,
          });
        });
      }
    }
  };
  // if (isLoading)
  //   return (
  //     <div className="loginPage">
  //       <Loader />
  //     </div>
  //   );
  // if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <div className="loginPage">
      <img src={logo} alt="logo" width={135} />
      <div className="formContainer">
        <p>Login</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            minLength="3"
            {...register("username", {
              required: "This field is required",
            })}
            className={errors.username ? "formInp errorInp" : "formInp"}
            placeholder="Username"
          />

          <input
            type="hidden"
            {...register("captcha_id")}
            value={captchaData?.captcha_id}
          />

          <input
            {...register("password", {
              required: "This field is required",
            })}
            className={errors.password ? "formInp errorInp" : "formInp "}
            type={showPassword ? "text" : "password"}
            placeholder="Password"
          />

          {errors.password && (
            <span className="errorSpan">{errors.password.message}</span>
          )}
          <div
            style={{
              display: "flex",
              marginTop: " 10px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="showPassword">
              <Checkbox onChange={() => setShowPassword(!showPassword)}>
                <span style={{ fontSize: "16px" }}>Show password</span>
              </Checkbox>
              {/* <span>Show password</span> */}
            </div>
            {/* <Link className="btn_password_reset">Forgot Password?</Link> */}
          </div>
          {captchaData.img !== "" && (
            <>
              <div className="captcha_block">
                <input
                  {...register("captcha", { required: true })}
                  className={errors.captcha ? "formInp errorInp" : "formInp "}
                  placeholder="Enter Captcha"
                  style={{ marginTop: "0" }}
                />
                <img
                  className="captchaImg"
                  src={`data:image/jpeg;base64, ${captchaData.img}`}
                  alt="captcha"
                />
              </div>
              <button
                type="button"
                className="refetchBtn"
                style={{
                  display: "inline-flex",
                  cursor: "pointer",
                  color: "rgba(37, 144, 238, 1)",
                  fontWeight: "500",
                  border: "none",
                  fontSize: "15px",
                }}
                onClick={() => refetchCaptcha()}
              >
                reset captcha
              </button>
            </>
          )}

          {errors.captcha && (
            <span className="errorSpan">{errors.captcha.message}</span>
          )}
          <div
            className="form_btns_cont"
            // style={{ padding: "0 15px" }}
          >
            <CustomBlueButton
              isLoading={isLoading}
              type="submit"
              label={"Login"}
              disabled={isLoading}
              color={"blue"}
              height={"40px"}
              fontSize={"15px"}
              // onClick={handleLogin}
              width={"100%"}
            />
            <div>Not a member?</div>
            <Link
              to={"/register"}
              style={{ color: "rgba(37, 144, 238, 1)", fontWeight: "500" }}
            >
              Create account
            </Link>
          </div>
        </form>
      </div>
      {/* {isLoading && (
        <span style={{ maxHeight: "30px" }}>
          <Loader />{" "}
        </span>
      )} */}
    </div>
  );
}

export default LoginPage;
