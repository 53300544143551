import React, { useState, useEffect } from "react";
import CustomBlueButton from "./CustomBlueButton";

function RefundTimerButton({
  initialTimer,
  onClick,
  check,
  isLoadingBtn,
  record,
}) {
  const [timer, setTimer] = useState(initialTimer);
  useEffect(() => {
    setTimer(initialTimer);
  }, [initialTimer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.round(seconds % 60);
    return `${minutes} min ${remainingSeconds.toString().padStart(2, "0")} sec`;
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          clearInterval(interval);

          console.log("Timer completed");
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {timer > 0 ? (
        <button
          className="RefundTimerButton"
          disabled={timer === 0}
          onClick={onClick}
        >
          <>
            <span>Refund</span>
            <span> {formatTime(timer)}</span>
          </>
        </button>
      ) : (
        <CustomBlueButton
          height={"30px"}
          isLoading={isLoadingBtn[`check-${record.hid}`]}
          width={"100%"}
          borderRadius={"10px"}
          label={"Check"}
          color={"blue"}
          onClick={check}
        />
      )}
    </>
  );
}

export default RefundTimerButton;
