import "../styles/components/Drawer.scss";
import { Link, useNavigate } from "react-router-dom";
import { Drawer } from "antd";
import { FiUpload, FiLogOut } from "react-icons/fi";
import useUserStore from "../stores/userStore";
import { FaBusinessTime } from "react-icons/fa";
import {
  drawerItems,
  headerItems,
  sellerHeaderItems,
} from "../components/Header/conf";
import HeaderLinkItem from "./Header/HeaderLinkItem";
import { useTranslation } from "react-i18next";
import Seller from "../assets/icons/Seller";
function CustomDrawer({ setIsOpen, isOpen }) {
  const { role } = useUserStore((state) => ({ role: state.role }));
  const { currentRole } = useUserStore((state) => ({
    currentRole: state.currentRole,
  }));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const changeRole = () => {
    let newRole = currentRole === "buyer" ? "seller" : "buyer";
    useUserStore.getState().changeCurrentRole(newRole);
    navigate("/");
  };

  return (
    // <div className="drawer">
    <Drawer
      title={
        <span>Menu</span>
        // <Link to={"/"}>
        //   <img className="logo" src={"/assets/logo.svg"} alt="logo" />
        // </Link>
      }
      onClose={() => setIsOpen(false)}
      open={isOpen}
      placement={"left"}
    >
      <div className="drawer drawerPhone">
        {currentRole === "seller" ? (
          <ul className="drawer_list">
            {sellerHeaderItems.map((item) => (
              <Link to={item.link} key={item.link}>
                <li>{t(item.title)} </li>
              </Link>
            ))}

            <li
              // className={styles.HeaderLinkItem}
              onClick={() => changeRole()}
            >
              {currentRole === "buyer" ? t("Seller") : t("Buyer")}
            </li>
          </ul>
        ) : (
          <ul className="drawer_list">
            {drawerItems.map((item, index) => (
              <Link
                to={item.link}
                key={item.link}
                onClick={() => setIsOpen(false)}
              >
                <li>{t(item.title)} </li>
              </Link>
            ))}

            {role === "seller" && (
              <li
                // className={styles.HeaderLinkItem}
                onClick={() => changeRole()}
              >
                {currentRole === "buyer" ? t("Seller") : t("Buyer")}
              </li>
            )}
          </ul>
        )}
      </div>
    </Drawer>
    // </div>
  );
}

export default CustomDrawer;
