import React, { useState } from "react";
import useAuthStore from "../stores/userStore.js";
import { useForm } from "react-hook-form";
import CustomBlueButton from "./CustomBlueButton.jsx";
import { checkCard } from "../utils/toolsUtils.js";
import InputMask from "react-input-mask";
import { useTranslation } from "react-i18next";

function CheckCardForm({ updateTable, price, openNotification }) {
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const { t } = useTranslation();
  const { key } = useAuthStore((state) => ({ key: state.key }));
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
  });

  const onSubmit = async (formData) => {
    setIsLoadingBtn(true);
    formData.cc = formData.cc.replace(/\s/g, "");
    const response = await checkCard(formData, key);
    if (response.ok === true) {
      setIsLoadingBtn(false);
      reset();
      updateTable();
    } else {
      openNotification("Check error", response.error);
      setIsLoadingBtn(false);
      reset();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* <p style={{ fontWeight: "600", marginBottom: "10px", fontSize: "16px" }}>
        Check card
      </p> */}
      <div>
        <p>{t("Number")}</p>
        <InputMask
          mask="9999 9999 9999 9999"
          // placeholder="Card number"
          className={
            errors.cc ? "toolsInput input-error " : "toolsInput fullInput"
          }
          {...register("cc", {
            required: "This field is required",
            pattern: {
              value: /^\d{4}\s\d{4}\s\d{4}\s\d{4}$/,
              message: "Invalid card number",
            },
          })}
        />
      </div>

      <div className="ccv_exp">
        <div className="validExpInputs">
          <p>CVV:</p>
          <InputMask
            mask="999"
            // placeholder="Cvv"
            className={errors.cvv ? "toolsInput input-error " : "toolsInput"}
            {...register("cvv", {
              required: "This field is required",
              pattern: {
                value: /^\d{3}$/,
                message: "Invalid CVV",
              },
            })}
          />
        </div>
        <div className="validExpInputs">
          <p>Valid to:</p>
          <InputMask
            mask="99/99"
            // placeholder="exp"
            className={errors.date ? "toolsInput input-error" : "toolsInput"}
            {...register("date", {
              required: "This field is required",
              pattern: {
                value: /^(0[1-9]|1[0-2])\/[0-9]{2}$/,
                message: "Invalid expiration date",
              },
            })}
          />
        </div>
      </div>

      <div className="bins_botPart">
        <span>- {price}$</span>
        <CustomBlueButton
          isLoading={isLoadingBtn}
          color={"blue"}
          width={"165px"}
          height={"33px"}
          borderRadius={"10px"}
          type="submit"
          fontSize={"15px"}
          label={t("Submit")}
          disabled={!isValid}
        />
      </div>
    </form>
  );
}

export default CheckCardForm;
