const CustomLegendAndTitle = ({ title, labels, colors }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px",
      }}
    >
      <h2 style={{ margin: 0, fontWeight: "600", fontSize: "17px" }}>
        {title}
      </h2>
      <div>
        {labels.map((label, index) => (
          <div
            key={index}
            style={{
              display: "inline-block",
              marginRight: "15px",
              alignItems: "center",
            }}
          >
            <span
              style={{
                backgroundColor: colors[index],
                width: "14px",
                height: "14px",
                display: "inline-block",
                marginRight: "5px",
              }}
            ></span>
            {label}
          </div>
        ))}
      </div>
    </div>
  );
};
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Filler,
} from "chart.js";
import "chartjs-adapter-date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Filler // Make sure to register the TimeScale if you're using a time scale.
);
const Chart = ({ data, height }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,

    scales: {
      x: {
        type: "time",
        time: {
          parser: "dd.MM",
          tooltipFormat: "dd.MM",
          unit: "day",
          displayFormats: {
            day: "dd.MM",
          },
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: { display: false },
      title: { display: false },
    },
  };

  const chartData = {
    labels: data.dates,
    datasets: [
      {
        label: data.labels[0],
        data: data.sold,
        borderColor: "#307bc4",
        fill: true,
        backgroundColor: "#307bc466",
        // pointRadius: 0,
      },
      {
        label: data.labels[1],
        data: data.refunds,
        borderColor: "#199f2e",
        // borderColor: "#D11C1C",
        fill: true,
        backgroundColor: "#199f2e8c",
        // backgroundColor: "#ff0a0a8f",
        // pointRadius: 0,
      },
    ],
  };

  return (
    <div>
      <CustomLegendAndTitle
        title={data.chartTitle}
        labels={[data.labels[0], data.labels[1]]}
        colors={["#307bc4", "#199f2e"]}
      />
      <div style={{ height: height }}>
        <Line data={chartData} options={options} />
      </div>
    </div>
  );
};

export default Chart;
