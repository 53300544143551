import { API_URL } from "../apiConf";
import RulesItem from "../components/RulesItem.jsx";
import styles from "../styles/pages/RulesPage.module.scss";
import { useQuery } from "@tanstack/react-query";
import useAuthStore from "../stores/userStore.js";
import React, { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import useUserStore from "../stores/userStore";
import Loader from "../components/Loader.jsx";
import NewItemContent from "../components/NewItemContent.jsx";
import { useTranslation } from "react-i18next";

const fetchRules = async ({ queryKey }) => {
  const [key, selectedRole] = queryKey;
  const { data } = await axios.get(
    `${API_URL}/api/${selectedRole}/?key=${key}`
  );
  console.log(data);
  return data;
};

function RulesPage() {
  const { t } = useTranslation();
  const { currentRole } = useUserStore((state) => ({
    currentRole: state.currentRole,
  }));
  const [selectedRole, setSelectedRole] = useState("");

  const { key } = useAuthStore((state) => ({ key: state.key }));
  const rolePath = currentRole === "buyer" ? "get_rules" : "seller/get_rules";

  const { data, isLoading } = useQuery({
    queryKey: [key, rolePath, "rules"],
    queryFn: fetchRules,
  });
  if (isLoading) return <Loader />;
  if (data) {
    return (
      <main className={styles.page}>
        <h1>{t(`Rules for ${currentRole}`)} </h1>
        <div className={styles.container}>
          <NewItemContent text={data?.rules || data?.rules_seller} />
        </div>
      </main>
    );
  }
}

export default RulesPage;
