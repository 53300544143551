import React, { useState, useEffect } from "react";
import CustomPopup from "../components/CustomPopup";
import { useForm } from "react-hook-form";
import "../styles/components/ChangePasswordPopup.scss";
import CustomBlueButton from "./CustomBlueButton";
import { updatePricesBase } from "../utils/baseInfoPageUtils.js"; // Функция для отправки данных на бэк
import useAuthStore from "../stores/userStore.js";

function ChangePriceBasePopup({ credsPriceSetPopup, setPriceSetPopup, prices, useQueryClient4, id }) {
  const [afterMessage, setAfterMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [isError, setIsError] = useState(false);

  const { key } = useAuthStore((state) => ({ key: state.key }));

  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({
    mode: "onChange",
    defaultValues: prices
  });
  useEffect(() => {
    if (credsPriceSetPopup && prices) { // Добавлена зависимость credsPriceSetPopup
      reset(prices); // Использование reset
    }
  }, [credsPriceSetPopup, prices, reset]); // Добавлена зависимость credsPriceSetPopup

  console.log("Popup Prices");
  console.log(prices);

  const onSubmit = async (formData) => {
    console.log(formData);
    const response = await updatePricesBase(key, formData, id);
    if (response.ok === true) {
      reset();
      setAfterMessage("Prices updated successfully");
      setShowMessage(true);
      useQueryClient4.invalidateQueries(['baseinfo']);
      setTimeout(() => {
        setShowMessage(false);
        setAfterMessage("");
        setPriceSetPopup(false);
      }, 5000);
    } else {
      setIsError(true);
      setAfterMessage(response.error);
      reset();
    }
  };

  return (
    <CustomPopup
      label={"Edit Prices"}
      isOpened={credsPriceSetPopup}
      setIsOpened={setPriceSetPopup}
    >
      {showMessage === false ? (
        <form onSubmit={handleSubmit(onSubmit)}
        // style={{height: '70hw', overflowY: 'scroll'}}
        >
          {Object.keys(prices).map((priceKey) => (
            <div key={priceKey} className={errors[priceKey] ? "password_cont errorInp_cont" : "password_cont"}>
              <div className="password_cont-leftSide"><span>{priceKey}</span></div>
              <input
                style={{ width: "50%", textAlign: "right" }}
                type="text"
                placeholder={`Enter price for ${priceKey}`}
                {...register(priceKey, {
                  required: "This field is required",
                })}
              />
            </div>
          ))}
          
          <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
            <CustomBlueButton
              color={"lightBlue"}
              width={"125px"}
              height={"40px"}
              borderRadius={"10px"}
              onClick={() => {
                setPriceSetPopup(false);
              }}
              fontSize={"15px"}
              label={"Cancel"}
            />
            <CustomBlueButton
              color={"blue"}
              width={"125px"}
              height={"40px"}
              borderRadius={"10px"}
              type="submit"
              fontSize={"15px"}
              label={"Save"}
            />
          </div>
          <p className="errorChange">{isError ? afterMessage : ""}</p>
        </form>
      ) : (
        <p className="successfullChange">{afterMessage}</p>
      )}
    </CustomPopup>
  );
}

export default ChangePriceBasePopup;
