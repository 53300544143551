import React from "react";

export default function Support() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <rect width="16" height="16" fill="url(#pattern0_8_739)" />
      <defs>
        <pattern
          id="pattern0_8_739"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use href="#image0_8_739" transform="scale(0.0111111)" />
        </pattern>
        <image
          id="image0_8_739"
          width="90"
          height="90"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEO0lEQVR4nO2cTahVVRTHF0IaWYIRiKZQWubEkQNroFI4cKBO+4AGGj5Kn00MGmZCKQ5Ks+ThwJciUuMaGDRoEJokvhdEkaFhjdJ4Imapz+cvNncJImefj969++y9z/rBnVzuOee/1t1nn7XXWvuIGIZhGIZhGIZhGPUBFgEvAbuAz4Ex4DwwAdzUz4R+dxb4DHhXj1nY4FLdApgBrAVGgHNMn1/0XC+4c0vXARYCu4E/GBy/A+93cqQDTwD7gX8Jh5tujgJLJXeAh4CdanRb3NI/+RHJEWCD3saxcBFYL7kAzNIRdKeBE25rNOGOew1Yo9PNXOAB/czV79bob/ZrdOKOrYvT9CEwUzJ42H1f0+gp4ASwyTlxGtd8FNgMfKXnrMNpYIGkCLBMb88q/gY+ApYMQMNTwAHgeqUK+A14RlICWAFcrjBsCjgcYiQBjwOjNUb4JaddUgB4GvizwqALwOoWtD2ri5ky3ABZJgnMyVXTxagL81rUOBs4UmMaWRBzdFH24LsN7JBIAN6qiFBORxmN6AOtzMmvSmQALwKTJbo/kJgANpbEyVPOIIkU4JWSh+SdaBY1uqx2c5qPHRI5wNsl+t1q9uEYRO4tEfmpJAK9hJOPPW2LW6JJmiJ+jWIk1MQlmbSQUIRLgj0pbQEcKpnbnpPEAFaVPGtG2oyZb6Y+ZdwPcMxj0w23wpTQaGWkiH+iDfbrDyBfQeI9aaHG58stfyyJQ6/eWMTFoDVILaT6FiaLJXGApSWx9fMx/ONfSyYA33hs/CSkCBe6FbFZMgEY8tj4c8jmliJcWPSYZAIwryTUG3z0AbzsufgPkhnAjx5bB5+70darIg5IZgAHPba+E+LirheuiNclM4BtHluPh7i4awNoN+wJhPbuFXEmxMV9KdGrwElgq+u7kEQBZgLDaouzqYgLIYT8RTVnU1yG06uWj9ew73IIMXX75pyzZ0kiAA9qp1MdboQQ1ITtkgjAm00MCyGoCackEehVvZN19DVJBHqtack6evCCcrUrOkG52hWdoFztik5QrnZFJyhXu6ITlKtd0QnK1a7oBOVqV3SCcrXLrfaiEtQnGvr5avdyArnmcKLLcrXj6OFQe1XGO+zosWB7W7QRsJazJRGo7+SwHaU6sre7+arsASmJgJ9rWj8cjnKXlmEYhmEYhlFE6uFpMmCODoM5OgDAnNbSoV0CWNnqxqCuAOzzOPoL6RL0ElguD/6d9shNaKJnaLqJHmB5yZbkN6QrUN0c7pw/fxpO9r1sazLFZvpBFxlO1R3Z7h0i+lq2ffrGAh8HpSvQsGzWR664DZ3SFWhYCO4T7sUB66RL0LC1oU9Ozm6/ZGyOvtK5kRx46pjUrcjdmZPvR4u//cbdJT8BX7ppojMhXI3wbqyf4Z1RvmAZq3Dy/1qwGP792Xd7SNwS/Ftgi41kwzAMwzAMuYf/AC09jcB2oSNQAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
}
