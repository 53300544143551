import "../styles/pages/SellersPage.scss";
import GreySectionRating from "../components/GreySectionRating";
import CustomBlueButton from "../components/CustomBlueButton";
import Chart from "../components/Chart";
import { API_URL } from "../apiConf";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import useAuthStore from "../stores/userStore.js";
import axios from "axios";
import useCardsFiltersStore from "../stores/buyCardsFiltersStore";
import { useNavigate } from "react-router-dom";
import useBasesFiltersStore from "../stores/basesFiltersStore";
import Loader from "../components/Loader.jsx";

const fetchSellerStat = async ({ queryKey }) => {
  const [_, key, sellerId] = queryKey;
  const { data } = await axios.get(
    `${API_URL}/api/seller_stat/?key=${key}&id=${sellerId}`
  );
  console.log("data", data);
  return data;
};
function SellerInfoPage() {
  const navigate = useNavigate();
  const { setFilters: setBasesFilters } = useBasesFiltersStore();
  const { filters, setFilters } = useCardsFiltersStore();
  const { id, name } = useParams();
  const { key } = useAuthStore((state) => ({ key: state.key }));
  const { data, isLoading } = useQuery({
    queryKey: ["data", key, id],
    queryFn: fetchSellerStat,
  });

  if (isLoading || !data) return <Loader />;

  // Разбор данных графиков
  const parseDate = (date) => {
    const [day, month] = date.split(".").map(Number);
    return new Date(2023, month - 1, day);
  };

  const redirectToBase = (sellerName) => {
    setBasesFilters({ sellers: [sellerName] });
    navigate("/bases");
  };
  const redirectToCards = (sellerName) => {
    setFilters({ sellers: [sellerName] });
    navigate("/cards");
  };

  const ref_grafic = Object.keys(data.ref_grafic).reduce((acc, key) => {
    acc[parseDate(key).toISOString()] = data.ref_grafic[key];
    return acc;
  }, {});

  const sold_grafic = Object.keys(data.sold_grafic).reduce((acc, key) => {
    acc[parseDate(key).toISOString()] = data.sold_grafic[key];
    return acc;
  }, {});

  const allDates = Array.from(
    new Set([...Object.keys(ref_grafic), ...Object.keys(sold_grafic)])
  )
    .sort((a, b) => new Date(a) - new Date(b))
    .map((isoDate) => new Date(isoDate));

  // Формирование данных для графика
  const formattedDates = allDates.map(
    (date) =>
      `${date.getDate().toString().padStart(2, "0")}.${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}`
  );
  const refunds = allDates.map((date) => ref_grafic[date.toISOString()] || 0);
  const sold = allDates.map((date) => sold_grafic[date.toISOString()] || 0);

  const chartData = {
    chartTitle: "Recent Sales and Refunds",
    dates: formattedDates,
    sold,
    refunds,
    labels: ["Sold", "Refunds"],
  };

  return (
    <div className="SellersPage__cont">
      <h1>Sellers</h1>
      <p className="underH">
        <span className="name">{name}</span>
        <span className="hearts">
          <img src="/assets/icons/icon-heart.svg" />
          {data?.subs}
        </span>
      </p>
      <div className="sellers_content">
        <GreySectionRating
          customerRates={data.stats?.count}
          stars={data.stats?.stars}
          sumRate1={data.stats?.sum_rate1}
          sumRate2={data.stats?.sum_rate2}
          sumRate3={data.stats?.sum_rate3}
          sumRates={data.stats?.sum_rates}
        />
        <div className="sellersRightSide">
          <div className="rightSide_child">
            <div className="info_item_rightside">
              <div className="iconText">
                <img src="/assets/icons/icon-vrGreen.svg" alt="" />
                <span style={{ color: "#199f2e" }}>VR</span>
              </div>
              <span>{data?.vr}</span>
            </div>
            <div className="info_item_rightside">
              <div className="iconText">
                <img src="/assets/icons/icon-bases2.svg" alt="" />
                <span>Bases</span>
              </div>
              <span>{data?.bases}</span>
            </div>
            <CustomBlueButton
              color={"blue"}
              height="57px"
              width={"270px"}
              onClick={() => redirectToBase(name)}
              label={"All Base"}
            />
          </div>
          <div className="rightSide_child">
            <div className="info_item_rightside">
              <div className="iconText">
                <img src="/assets/icons/icon-sold.svg" alt="" />
                <span>Sold</span>
              </div>
              <span>{data?.sold}</span>
            </div>
            <div className="info_item_rightside">
              <div className="iconText">
                <img src="/assets/icons/icon-card.svg" alt="" />
                <span>In sale</span>
              </div>
              <span>{data?.insale}</span>
            </div>
            <CustomBlueButton
              color={"blue"}
              height="57px"
              width={"270px"}
              onClick={() => redirectToCards(name)}
              label={"All Cards"}
            />
          </div>
        </div>
        <div className="chart_container">
          <Chart height={"355px"} data={chartData} />
        </div>
      </div>
    </div>
  );
}

export default SellerInfoPage;
