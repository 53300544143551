import React, { useState } from "react";
import useBuyPopupStore from "../stores/buyPopupStore";
import CustomPopup from "./CustomPopup";
import "../styles/components/BuyPopup.scss";
import CustomBlueButton from "./CustomBlueButton";
import MultiSelect from "./MultiSelect";
import useAuthStore from "../stores/userStore.js";
import { useQuery } from "@tanstack/react-query";
import { fetchProfileInfo } from "../utils/profileUtils";
import { buy, checkVoucher } from "../utils/cartUtils";
import { useLocation, useNavigate } from "react-router-dom";
import useCartStore from "../stores/cartStore";
import { notification } from "antd";
import { duration } from "@mui/material";
function BuyPopup() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { key } = useAuthStore((state) => ({
    key: state.key,
  }));

  const { isOpened, setIsOpened, cardsForBuy } = useBuyPopupStore();
  const totalSum = cardsForBuy.reduce((acc, card) => acc + card.price, 0);
  const cardIds = cardsForBuy.map((card) => card.id);
  const { data } = useQuery({
    queryKey: ["profileInfo", key],
    queryFn: fetchProfileInfo,
  });
  const [inputVoucher, setInputVoucher] = React.useState("");
  const [voucherValue, setVoucherValue] = React.useState({
    value: "",
    label: "",
  });

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (text, errorText) => {
    api["error"]({
      message: text,
      description: errorText,
      placement: "top",
      duration: 5000,
    });
  };

  const [voucherPrice, setvoucherPrice] = React.useState(0);
  const [isVoucher, setIsVoucher] = React.useState(false);
  // const [totalWithVoucher, setTotalWithVoucher] = React.useState("");

  // const getVoucherPrice=()=> {
  //   if
  // }

  React.useEffect(() => {
    if (inputVoucher != "" && inputVoucher.length < 16) {
      setIsVoucher(false);
    } else if (voucherValue.value != "") {
      priceFromSelect();
    } else {
      setIsVoucher(false);
    }
  }, [inputVoucher, voucherValue]);

  const promoOptions =
    data?.promo?.map((item) => ({
      value: item.id.toString(),
      label: `${item.token} (-${item.value})`,
    })) || [];

  promoOptions.unshift({ value: "", label: "" });

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setVoucherValue(selectedOption);
    } else {
      setVoucherValue({ value: "", label: "" });
    }
  };

  const buyCards = async () => {
    setIsLoading(true);
    const vaucherForBuy =
      voucherValue.value.length != 0 ? voucherValue.value : inputVoucher;
    const res = await buy(cardIds, vaucherForBuy, key);
    if (res.ok === true) {
      setIsLoading(false);
      if (location.pathname === "/cart") {
        useCartStore.getState().clearCart();
      }
      useAuthStore.getState().changeBalance(res.summary);
      setIsOpened(false);
      navigate("/orders");
    } else {
      setIsLoading(false);
      openNotification("Purchase error", res.error);
      setIsOpened(false);
    }
  };

  const handleCheckVoucher = async () => {
    const result = await checkVoucher(inputVoucher, key);
    if (result.ok === true) {
      if (result.info.active === "Expired") {
        openNotification("Error", "Voucher Expired");
        setInputVoucher("");
      } else {
        openNotification("Info", "Voucher Activated");
        setvoucherPrice(result.info.value);
        setIsVoucher(true);
      }
    }
  };

  const priceFromSelect = () => {
    console.log(voucherValue);
    const itemFromSelect = data?.promo?.find(
      (item) => item.id.toString() === voucherValue.value
    );
    console.log(itemFromSelect);
    if (itemFromSelect) {
      setvoucherPrice(itemFromSelect.value);
      setIsVoucher(true);
    }
  };

  return (
    <CustomPopup
      isOpened={isOpened}
      setIsOpened={setIsOpened}
      label={"Confirm purchase"}
    >
      {contextHolder}
      <div className="byupopup_cont">
        <div className="byupopup_item">
          <span>Purchase summary</span>
          <span>{cardsForBuy.length} cards</span>
        </div>
        <div className="byupopup_sum">
          <span>Total</span>
          <span>$ {totalSum}</span>
        </div>
      </div>

      <CustomBlueButton
        isLoading={isLoading}
        color={"blue"}
        label={"Buy"}
        height={"35px"}
        borderRadius={"10px"}
        fontSize={"16px"}
        width={"100%"}
        onClick={() => {
          buyCards();
        }}
      />
    </CustomPopup>
  );
}

export default BuyPopup;
