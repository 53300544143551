import React from "react";
const payoutsColumns = [
  {
    title: "Currency",
    dataIndex: "coin",
    key: "coin",
  },

  {
    title: "TX",
    dataIndex: "tx",
    key: "tx",
  },
  {
    title: "USD",
    dataIndex: "usd",
    key: "usd",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },

  {
    title: "Created",
    dataIndex: "date",
    key: "date",
  },
];

export default payoutsColumns;
