import { useTransition } from "react";
import "../styles/pages/ServicesPage.scss";
import { useTranslation } from "react-i18next";
const cardsInfo = [
  {
    title: "Forums",
    links: [
      { title: "SameForum1", link: "", isTor: true },
      { title: "SameForum2", link: "", isTor: true },
      { title: "SameForum3", link: "" },
      { title: "SameForum4", link: "" },
    ],
  },
  {
    title: "Security Tools",
    links: [
      { title: "SameTool1", link: "" },
      { title: "Sametool2", link: "" },
      { title: "Sametool3", link: "" },
      { title: "Sametool4", link: "" },
    ],
  },
  {
    title: "Security Tools",
    links: [
      { title: "Sametool1", link: "" },
      { title: "Sametool2", link: "" },
      { title: "Sametool3", link: "" },
      { title: "Sametool4", link: "" },
    ],
  },
];
function ServicesPage() {
  const { t } = useTranslation();
  return (
    <div className="services_page">
      <h1>{t("Services")}</h1>
      <div className="services-cards_cont">
        {cardsInfo.map((card, index) => (
          <div className="services_card" key={index}>
            <p>{t(card.title)}:</p>
            <div>
              {card.links.map((forum, index) => (
                <div className="card_item" key={index}>
                  <a href={forum.link}>{forum.title}</a>
                  <div className="card_item-rightside">
                    {forum.isTor && <span className="spanTor">Tor</span>}
                    <span>{t("Available")}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ServicesPage;
