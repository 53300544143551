import axios from 'axios';

import {
    API_URL
} from '../apiConf';
import useUserStore from '../stores/userStore';

const fetchLogin = async (formData) => {
    try {
        const response = await axios.post(`${API_URL}/api/auth/`, {
            ...formData
        });
        const {
            username,
            ...rest
        } = formData;
        const {
            key,
            role,

        } = response.data;
        useUserStore.getState().setUser({
            username,
            role,

            key
        });
        console.log('username=', username, 'role=', role, 'key', key)
        return {
            username,
            role,
            key
        };
    } catch (error) {
        console.error("Ошибка при логине:", error.response ? error.response.data : error);
        throw error;
    }
};

const fetchRegister = async (formData) => {

    try {
        const response = await axios.post(`${API_URL}/api/reg/`, formData);
        const {
            username,
            ...rest
        } = formData;
        const {
            key,
            role
        } = response.data;
        useUserStore.getState().setUser({
            username,
            role,
            key
        });
        console.log('username=', username, 'role=', role, 'key', key)

        return {
            username,
            role,
            key
        };
    } catch (error) {

        throw error;
    }
};

export {
    fetchLogin,
    fetchRegister
};