import React from "react";
import { headerItems } from "./conf";
import styles from "./Header.module.scss";
import HeaderLinkItem from "./HeaderLinkItem";
import Cart from "../../assets/icons/Cart";
import User from "../../assets/icons/User";
import Seller from "../../assets/icons/Seller";
import useAuthStore from "../../stores/userStore";
import { Link, useNavigate } from "react-router-dom";
import LanguageChanger from "../LanguageChanger";
import { FiLogOut } from "react-icons/fi";
import MiniCart from "../MiniCart";
import { useTranslation } from "react-i18next";
import { BsTelegram } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";

export default function Header3({ setIsOpen }) {
  const { t } = useTranslation();
  const { role } = useAuthStore((state) => ({ role: state.role }));
  const { currentRole } = useAuthStore((state) => ({
    currentRole: state.currentRole,
  }));
  const navigate = useNavigate();
  const changeRole = () => {
    let newRole = currentRole === "buyer" ? "seller" : "buyer";
    useAuthStore.getState().changeCurrentRole(newRole);
    navigate("/");
  };
  const { balance } = useAuthStore((state) => ({ balance: state.balance }));
  return (
    <div className={styles.Header}>
      <img
        className={styles.burgerIcon}
        src="/assets/icons/icon-burger.svg"
        alt=""
        onClick={() => setIsOpen(true)}
      />
      <div className={styles.headerLinks}>
        {headerItems.map((item, index) => (
          <HeaderLinkItem {...item} key={index} />
        ))}

        {/* btn for change role to seller */}
        {role === "seller" && (
          <div className={styles.HeaderLinkItem} onClick={() => changeRole()}>
            <Seller />
            {currentRole === "buyer" ? t("Seller") : t("Buyer")}
          </div>
        )}
        <a
          href="https://t.me/pickup_cc"
          target="blanc"
          className={styles.HeaderLinkItem}
        >
          <BsTelegram size={"19px"} style={{ color: "white" }} />
          Telegram
        </a>
      </div>
      <div className={styles.HeaderRightSide}>
        <LanguageChanger />
        <MiniCart />

        <Link to={"/profile"}>
          <User />
        </Link>

        <Link to={"/deposit"}>${balance}</Link>

        <button onClick={() => useAuthStore.getState().logout()}>
          <FiLogOut size={"16px"} color="white" />
        </button>
      </div>
    </div>
  );
}
