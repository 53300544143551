import React from "react";

export default function Deposit() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <rect width="16" height="16" fill="url(#pattern0_8_745)" />
      <defs>
        <pattern
          id="pattern0_8_745"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use href="#image0_8_745" transform="scale(0.0111111)" />
        </pattern>
        <image
          id="image0_8_745"
          width="90"
          height="90"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAADK0lEQVR4nO3cy2oUQRTG8SaKUYMIrjRBvKx8AgUfxQcQ0ST6FsaNCy+YjeADGEXQJxC8Yi4YRUV33nDhJYkuIvqXYkqRgU6qquvMTHW+H2STwDlnPnq6p3uqUlUiIiIiIiIiIiIishZgKzAJ3AeWkb++A498NsONjiJgDFj4V1rqzAGjqSEPK+Qos0lHNnAmro8Ap1KCducfiXM/JWh3spc4yylBx/hWtRSRn7RSGti+ZQoBPBykoMerlgImBiVo9xlyS9VSdD7mzvU7aDfAWNVydG7cgsJOKV7HXRzuudNFm4/kbu61+te85qOIKla2Qg0BI8AxYAZYdJ9w/M+i/53720gf5mpH0MBmf3fqQl3PV+A0sKmH85UfNDAKPCHeY2BPj2YsO2jgIPCGdK9djR7MWW7QdM7Hz2nuFbDDeNaig75GPleNZy0zaOAI+R01nLfYoG8bBH3LcN7yggYOAL8NgnY197cqaH/ndA54D7wDpkLvHIET2DkeOEPU/LH5VLkK+cG6TQX2uoGd64EzRM1f1yykV6NC/kjo9iGw11PsLATOEDV/XbOQXo0K+bdbt7eBvT5h52PgDFHz1zUL6dWoUM1b72xgr5/YWQ2cIWr+umYhvRoV8heTKX9kxF4Ml7AT9H1m7Pyx+VTmhcJ6vTSLGV4YzZwnn2yFwnrdNIsZZoxmzpNPtkL9XxU1bjRznnyyFQrrtQ/4ZRCyq7nXaObygnaAOwZB61lHN+Bw5ucdq8Chykhd0/4Vius5nTHo88azFh30NuBuhpAfANuNZy03aAfY2XAB/DNgV2XMPGhgxR8xE433bpS3NGzSv/aVXgS9UZeEzYcEklKciLCHW34kz4eGkdIgRpuX7U7GBJHSIIYWovco6Pi9G4XoxdYKy2fEbbWUErRbByw92P4WtXdD0jd0Ru3dEGaTd0D4dcoKe31uLffupJBr9m7U3npuQMt+L8/JjbSXR0RERFrBff8GXPYbJ6XjC3DJfc85qN9Ot810rpCHgB/9fjUDzP1LpCEFXUrQ/qjWqaPelSwh/7ew5aK/AEjHZ+BC1ouhiIiIiIiIiIiISNVafwDZdpdK+PybWAAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  );
}
