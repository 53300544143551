export const statisticColumns = [
  {
    title: "Bin",
    dataIndex: "bin",
    key: "bin",
  },
  {
    title: "Brand",
    dataIndex: "brand",
    key: "brand",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },

  {
    title: "LVL",
    dataIndex: "level",
    key: "level",
  },
  {
    title: "Bank",
    dataIndex: "bank",
    key: "bank",
  },
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
  },
];
