import React from "react";

export default function Container({ children, style }) {
  return (
    <div
      style={{
        width:'auto',
        display: "flex",
        flexDirection: "column",
        borderRadius: "10px",
        backgroundColor: "white",
        padding: "20px 30px",
        ...style
      }}
    >
      {children}
    </div>
  );
}
