import React from "react";
import { Link } from "react-router-dom";
import CardBrandIcon from "../CardBrandIcon";
const cardsColumns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
    render: (text) => <span className="orderSpan_blue"> {text} </span>,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Card",
    dataIndex: "card",
    key: "card",
  },
  {
    title: "Exp",
    dataIndex: "exp",
    key: "exp",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "CVV",
    dataIndex: "cvv",
    key: "cvv",
  },
  {
    title: "City",
    dataIndex: "city",
    key: "city",
  },
  {
    title: "State",
    dataIndex: "state",
    key: "state",
  },
  {
    title: "Zip",
    dataIndex: "zip",
    key: "zip",
  },
  // {
  //   title: "Basename",
  //   dataIndex: "basename",
  //   key: "basename",
  // },
  // {
  //   title: "Seller",
  //   dataIndex: "seller",
  //   key: "seller",
  // },
  {
    title: "Bank",
    dataIndex: "binlist_bank",
    key: "binlist_bank",
  },
  {
    title: "Brand",
    dataIndex: "binlist_brand",
    key: "binlist_brand",
    render: (_, record) => <CardBrandIcon brand={record.binlist_brand} />,
  },
  {
    title: "Type",
    dataIndex: "binlist_type",
    key: "binlist_type",
  },
  {
    title: "Level",
    dataIndex: "binlist_level",
    key: "binlist_level",
  },
  {
    title: "Country",
    dataIndex: "binlist_country",
    key: "binlist_country",
  },
  // {
  //   title: "Create Date",
  //   dataIndex: "create_date",
  //   key: "create_date",
  // },
  {
    title: "Bin",
    dataIndex: "bin",
    key: "bin",
  },
  // {
  //   title: "Info",
  //   dataIndex: "list",
  //   key: "list",
  // },
];

export default cardsColumns;
