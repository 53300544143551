import styles from "../styles/pages/StatisticPage.module.scss";
import React from "react";
import axios from "axios";
import useAuthStore from "../stores/userStore.js";
import { API_URL } from "../apiConf.js";
import Container from "./Container/Container.jsx";
import { Link } from "react-router-dom";
import BasesCard from "./BasesCard.jsx";
import { useQuery } from "@tanstack/react-query";
import NewItemContent from "./NewItemContent.jsx";
import { startOfSecond } from "date-fns";
import CustomBlueButton from "./CustomBlueButton.jsx";
import { Flex } from "antd";
import CustomTable from "./CustomTable/CustomTable.jsx";
import { statisticColumns } from "./CustomTable/statisticPageColumns.jsx";
import Loader from "./Loader.jsx";
import { useTranslation } from "react-i18next";

const fetchData = async (queryKey) => {
  const { data } = await axios.get(
    `${API_URL}/api/seller/stats/?key=${queryKey}`
  );
  return data;
};

export default function StatisticSellerView() {
  const { t } = useTranslation();
  const { key } = useAuthStore((state) => ({ key: state.key }));
  const allKeys = ["today", "week", "mount", "total"];
  const { data, isLoading } = useQuery({
    queryKey: ["fetchStatsSeller"],
    queryFn: () => fetchData(key),
  });

  if (isLoading) {
    return <Loader />;
  }

  if (data) {
    return (
      <>
        <h1>{t("Your Statistic")}</h1>
        <div className={styles.grid}>
          <Container
            style={{
              border: "1px solid rgba(37, 144, 238, 1)",
              justifyContent: "center",
            }}
          >
            <div className={styles.stat_item}>
              {t("Today Sold")}:{" "}
              <span className={styles.stat_value}>{data?.today?.sold}</span>
            </div>

            <div style={{ marginTop: "20px" }} className={styles.stat_item}>
              {t("Today Refunds")}:{" "}
              <span className={styles.stat_value}>{data?.today?.refunds}</span>
            </div>
            <div style={{ marginTop: "20px" }} className={styles.stat_item}>
              {t("Today Profit")}:{" "}
              <span className={styles.stat_value}>{data?.today?.profit.toFixed(2)}</span>
            </div>
          </Container>
          <Container
            style={{
              border: "1px solid rgba(37, 144, 238, 1)",
              justifyContent: "center",
            }}
          >
            <div className={styles.stat_item}>
              {t("Weekly Sold")}:{" "}
              <span className={styles.stat_value}>{data?.week?.sold}</span>
            </div>

            <div style={{ marginTop: "20px" }} className={styles.stat_item}>
              {t("Weekly Refunds")}:{" "}
              <span className={styles.stat_value}>{data?.week?.refunds}</span>
            </div>
            <div style={{ marginTop: "20px" }} className={styles.stat_item}>
              {t("Weekly Profit")}:{" "}
              <span className={styles.stat_value}>{data?.week?.profit.toFixed(2)}</span>
            </div>
          </Container>
          <Container
            style={{
              border: "1px solid rgba(37, 144, 238, 1)",
              justifyContent: "center",
            }}
          >
            <div className={styles.stat_item}>
              {t("Monthly Sold")}:{" "}
              <span className={styles.stat_value}>{data?.month?.sold}</span>
            </div>

            <div style={{ marginTop: "20px" }} className={styles.stat_item}>
              {t("Monthly Refunds")}:{" "}
              <span className={styles.stat_value}>{data?.month?.refunds}</span>
            </div>
            <div style={{ marginTop: "20px" }} className={styles.stat_item}>
              {t("Monthly Profit")}:{" "}
              <span className={styles.stat_value}>{data?.month?.profit.toFixed(2)}</span>
            </div>
          </Container>
          <Container
            style={{
              border: "1px solid rgba(37, 144, 238, 1)",
              justifyContent: "center",
            }}
          >
            <div className={styles.stat_item}>
              {t("Total Sold")}:{" "}
              <span className={styles.stat_value}>{data?.total?.sold}</span>
            </div>

            <div style={{ marginTop: "20px" }} className={styles.stat_item}>
              {t("Total Refunds")}:{" "}
              <span className={styles.stat_value}>{data?.total?.refunds}</span>
            </div>
            <div style={{ marginTop: "20px" }} className={styles.stat_item}>
              {t("Total Profit")}:{" "}
              <span className={styles.stat_value}>{data?.total?.profit.toFixed(2)}</span>
            </div>
          </Container>
        </div>

        <h1>{t("Top BINs")}</h1>
        <CustomTable columns={statisticColumns} data={data?.top_bins} />
      </>
    );
  }
}
