import styles from "../styles/pages/StatisticPage.module.scss";
import React from "react";
import useAuthStore from "../stores/userStore.js";

import StatisticSellerView from "../components/StatisticSellerView.jsx";
import StatisticBuyerView from "../components/StatisticBuyerView.jsx";

function StatisticPage() {
  const { currentRole } = useAuthStore((state) => ({
    currentRole: state.currentRole,
  }));
  switch (currentRole) {
    case "seller":
      return (
        <main className={styles.page}>
          <StatisticSellerView />
        </main>
      );

    case "buyer":
      return (
        <main className={styles.page}>
          <StatisticBuyerView />
        </main>
      );

    default:
      return <>Invalid Role</>;
  }
}

export default StatisticPage;
