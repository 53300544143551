import React, { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useAuthStore from "../stores/userStore.js";
import PreorderSelection from "../components/PreorderSelection";
import CustomTable from "../components/CustomTable/CustomTable";
import preorderColumns from "../components/CustomTable/preorderColumns.jsx";
import historyPreorderColumns from "../components/CustomTable/historyPreorderColumns.jsx";
import { fetchPreordersList } from "../utils/ordersUtils";
import { deletePreorder, getSellersList } from "../utils/preorderUtils.js";
import ChangePreorderPrice from "../components/ChangePreorderPrice.jsx";
import { API_URL } from "../apiConf.js";
import axios from "axios";
import { notification } from "antd";
import useOrdersPageStore from "../stores/ordersHistStore.js";
import { useNavigate } from "react-router-dom";
import "../styles/components/PreorderSelection.scss";
import { useTranslation } from "react-i18next";
function PreorderPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [activePreorders, setActivePreorders] = useState(true);
  const [changePricePopup, setChangePricePopup] = useState(false);
  const [preorderForPrice, setPreorderForPrice] = useState("");
  const { activeHistory, setActiveHistory } = useOrdersPageStore();
  const { key } = useAuthStore((state) => ({ key: state.key }));
  const { data } = useQuery({
    queryKey: [key, "preorderPage"],
    queryFn: fetchPreordersList,
  });
  const { data: sellers } = useQuery({
    queryKey: [key, "sellers"],
    queryFn: getSellersList,
  });
  //snackbar for errors
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (text, errorText) => {
    api.info({
      message: text,
      description: errorText,
      placement: "top",
    });
  };
  //
  const verifyKey = async () => {
    try {
      const responce = await axios.post(`${API_URL}/api/check/`, { key });
      const { balance } = responce.data;
      useAuthStore.getState().setUser({ balance });
    } catch (error) {
      console.error("Ошибка при проверке ключа:", error);
    }
  };

  const delPreorder = async (key, id) => {
    try {
      const response = await deletePreorder(key, id);
      console.log(data);
      if (response.ok === true) {
        queryClient.invalidateQueries("preorderPage");
        verifyKey();
      }
    } catch (error) {
      console.error("delete preorder error:", error);
    }
  };
  const openChangePricePopup = (preorder) => {
    setChangePricePopup(true);
    setPreorderForPrice(preorder);
  };

  const redirectToPreorderPage = () => {
    setActiveHistory(false);
    navigate("/orders");
  };
  return (
    <div className="preorder_page">
      <h1>{t("Preorder")}</h1>
      {contextHolder}
      <PreorderSelection
        openNotification={openNotification}
        verifyKey={verifyKey}
        sellers={sellers?.seller_list}
        opened={data?.active_preorders?.length}
        closed={data?.preorders_history?.length}
        activePreorders={activePreorders}
        setActivePreorders={setActivePreorders}
      />
      <div style={{ marginTop: "20px" }}></div>
      {data?.active_preorders?.length != 0 && (
        <CustomTable
          data={data?.active_preorders}
          columns={preorderColumns(
            key,
            delPreorder,
            openChangePricePopup,
            redirectToPreorderPage
          )}
        />
      )}
      {/* {activePreorders ? (
        <CustomTable
          data={data?.active_preorders}
          columns={preorderColumns(
            key,
            delPreorder,
            openChangePricePopup,
            redirectToPreorderPage
          )}
        />
      ) : (
        <CustomTable
          data={data?.preorders_history}
          columns={historyPreorderColumns(redirectToPreorderPage)}
        />
      )} */}
      <ChangePreorderPrice
        openNotification={openNotification}
        setIsOpen={setChangePricePopup}
        preorder={preorderForPrice}
        isPopupOpen={changePricePopup}
        setIsPopupOpen={setChangePricePopup}
      />
    </div>
  );
}

export default PreorderPage;
