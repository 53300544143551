import React, { useState } from "react";
import LittleStarRating from "../LittleStarRating";
import { Link } from "react-router-dom";
import CustomBlueButton from "../CustomBlueButton";
import dayjs from "dayjs";
import CountryFlagIcon from "../CountryFlagIcon";
import { LuAlertCircle, LuCheckCircle2 } from "react-icons/lu";
import RefundTimerButton from "../RefundTimerButton";
import { Tooltip } from "antd";
import { FaMinus, FaCheck } from "react-icons/fa";
import RenderRefColumn from "../RenderRefColumn";

const purchasedCardsColumns = (
  handleOpenFullInfo,
  handleOpenRate,
  redirectToBase,
  open,
  refund,
  check,
  isLoadingBtn
) => [
  {
    title: "DATE",
    dataIndex: "date",
    key: "date",
    render: (value, record) => (
      <span>
        {dayjs(record.card_info.create_date).format("YYYY-MM-DD HH:mm:ss")}
      </span>
    ),
  },
  {
    title: "DATABASE",
    dataIndex: "base",
    key: "base",
    render: (value, record) => (
      <span
        className="sellerCellOrders"
        style={{ color: "#2590EE" }}
        onClick={() => redirectToBase(record.card_info.list.base_name)}
      >
        {record.card_info.list.base_name}
      </span>
    ),
  },
  {
    title: "DB type",
    dataIndex: "type",
    key: "type",
    render: (_, record) => (
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        {record.type === "nonref" ? (
          <div
            title="No refund"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span>Nonref</span> <FaMinus color={"red"} />
          </div>
        ) : (
          <div
            title="refundable"
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
          >
            <span>Ref</span> <FaCheck color={"green"} />
          </div>
        )}
      </div>
    ),
  },
  {
    title: "CC",
    dataIndex: "Number",
    key: "Number",

    render: (value, record) => (
      <Tooltip
        placement="top"
        title={
          <>
            <p>BIN: {record.card_info.bin}</p>
            <p>Bank: {record.card_info.binlist_bank}</p>
            <p>Brand: {record.card_info.binlist_brand}</p>
            <p>Level: {record.card_info.binlist_level}</p>
            <p>Type: {record.card_info.binlist_type}</p>
          </>
        }
      >
        <span
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: "#2590EE",
          }}
        >
          {record.card_info.list.card_number}
        </span>
      </Tooltip>
    ),
  },
  {
    title: "EXP",
    dataIndex: "exp",
    key: "exp",
    render: (value, record) => <span>{record.card_info.list.exp}</span>,
  },
  {
    title: "CVV",
    dataIndex: "cvv",
    key: "cvv",
    render: (_, record) => (
      <p>
        {record.card_info.list.cvv ? (
          <FaCheck fill="rgb(25, 159, 46)" />
        ) : (
          <FaMinus fill="#d11c1c" />
        )}
      </p>
    ),
  },
  {
    title: "BANK",
    dataIndex: "bank",
    key: "bank",
    render: (value, record) => <span>{record.card_info.binlist_bank}</span>,
  },
  {
    title: "COUNTRY",
    dataIndex: "country",
    key: "coyntry",
    render: (value, record) => (
      <span>
        <CountryFlagIcon
          showCountryCode={true}
          countryName={record.card_info.binlist_country}
        />
      </span>
    ),
  },
  {
    title: "CITY",
    dataIndex: "city",
    key: "city",
    render: (value, record) => (
      <span>
        {record.card_info.list.city ? record.card_info.list.city : "-"}
      </span>
    ),
  },
  {
    title: "ADDR",
    dataIndex: "address",
    key: "address",
    render: (_, record) => (
      <p>
        {record.card_info.list.address ? (
          <FaCheck fill="rgb(25, 159, 46)" />
        ) : (
          <FaMinus fill="#d11c1c" />
        )}
      </p>
    ),
  },
  {
    title: "HOLDER",
    dataIndex: "name",
    key: "name",
    render: (value, record) => (
      <span>
        {record.card_info.list.holder ? `${record.card_info.list.holder}` : "-"}
      </span>
    ),
  },
  {
    title: "STATE",
    dataIndex: "state",
    key: "state",
    render: (value, record) => (
      <span>
        {record.card_info.list.state ? record.card_info.list.state : "-"}
      </span>
    ),
  },
  {
    title: "ZIP",
    dataIndex: "zip",
    key: "zip",
    render: (_, record) => (
      <span>{record.card_info.list.zip ? record.card_info.list.zip : "-"}</span>
    ),
  },
  {
    title: "Info",
    dataIndex: "info",
    key: "info",
    render: (_, record) => (
      <Tooltip placement="top" title={record.list}>
        <p
          className="infoColumn"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "2px",
            textDecoration: "underline",
          }}
        >
          Info
        </p>
      </Tooltip>
    ),
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    render: (text) => <span className="priceSpan"> {text.toFixed(2)}$</span>,
  },
  {
    title: "Refund",
    dataIndex: "refund",
    key: "refund",
    align: "center",
    render: (value, record) => (
      <RenderRefColumn
        isLoadingBtn={isLoadingBtn}
        record={record}
        open={open}
        refund={refund}
        check={check}
      />
    ),
  },
  {
    title: "VIEW INFO",
    dataIndex: "fullInfo",
    key: "fullInfo",
    render: (_, record) => (
      <div>
        {record.billing_information_rate === "-" &&
        record.money_balance_rate === "-" &&
        record.price_rate === "-" ? (
          <div className="fullInfoCol">
            <CustomBlueButton
              onClick={() => handleOpenFullInfo(record)}
              height={"25px"}
              color={"lightBlue"}
              borderRadius={"15px"}
              label={"Full info"}
              fontSize={"11px"}
              width={"75px"}
            />
            <CustomBlueButton
              onClick={() => handleOpenRate(record)}
              height={"25px"}
              color={"blue"}
              borderRadius={"15px"}
              label={"Rate card"}
              fontSize={"11px"}
              width={"75px"}
            />
          </div>
        ) : (
          <CustomBlueButton
            onClick={() => handleOpenFullInfo(record)}
            height={"30px"}
            color={"lightBlue"}
            borderRadius={"15px"}
            label={"Full info"}
            fontSize={"12px"}
            width={"75px"}
          />
        )}
      </div>
    ),
  },
];

export default purchasedCardsColumns;
