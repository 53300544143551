import { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useAuthStore from "../stores/userStore.js";
import "../styles/pages/OrderPage.scss";
import StringWithIcon from "../components/StringWithIcon";
import CustomTable from "../components/CustomTable/CustomTable";
import purchasedCardsColumns from "../components/CustomTable/purchasedCardsColumns";
import HistoryFullInfoPopup from "../components/HistoryFullInfoPopup";
import OrdersFilters from "../components/OrdersFilters";
import {
  createUniqueOrdersBaseOptions,
  createUniqueOrdersOptions,
  fetchOrdersList,
  filterData,
  fetchPreordersList,
  fetchOpen,
  fetchRefund,
  createUniqueOrdersCountryOptions,
  fetchExportIds,
  fetchDeleteHistItem,
  fetchCheck,
} from "../utils/ordersUtils";
import useBasesFiltersStore from "../stores/basesFiltersStore.js";
import useOrdersFiltersStore from "../stores/ordersFiltersStore.js";
import historyPreorderColumns from "../components/CustomTable/historyPreorderColumns.jsx";
import { Button, notification } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../components/Loader.jsx";
import useOrdersPageStore from "../stores/ordersHistStore.js";
import CustomBlueButton from "../components/CustomBlueButton.jsx";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import { IoWarningOutline } from "react-icons/io5";
import { CiMoneyCheck1 } from "react-icons/ci";
import HistoryRatePopup from "../components/HistoryRatePopup.jsx";

function useOrders() {
  const queryClient = useQueryClient();
  const { key } = useAuthStore((state) => ({ key: state.key }));

  // const fetchOrdersList = async () => {
  //   const response = await fetch("/api/orders"); // Укажите правильный URL
  //   if (!response.ok) {
  //     throw new Error("Network response was not ok");
  //   }
  //   return response.json();
  // };

  // Основной запрос данных
  const { data, isLoading } = useQuery({
    queryKey: [key, "orders"],
    queryFn: fetchOrdersList,
  });

  // useEffect(() => {
  //   const handleFocus = () => {
  //     // Инвалидация и повторный запрос данных при фокусе
  //     queryClient.invalidateQueries([key, "orders"]);
  //   };

  //   // Добавление обработчика события при фокусировке на вкладку
  //   window.addEventListener("focus", handleFocus);

  //   return () => {
  //     // Удаление обработчика события при размонтировании компонента
  //     window.removeEventListener("focus", handleFocus);
  //   };
  // }, [queryClient]);

  return { data, isLoading };
}

function OrdersPage() {
  const [isLoadingBtn, setIsLoadingBtn] = useState({});
  const setLoadingForButton = (buttonId, isLoading) => {
    setIsLoadingBtn((prev) => ({
      ...prev,
      [buttonId]: isLoading,
    }));
  };

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { key } = useAuthStore((state) => ({ key: state.key }));
  const { data, isLoading } = useOrders();

  const { setFilters: setBasesFilters } = useBasesFiltersStore();
  const queryClient = useQueryClient();
  const [filteredData, setFilteredData] = useState(undefined);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { filters, setFilters } = useOrdersFiltersStore();
  const [api, contextHolder] = notification.useNotification();

  // const { data, isLoading } = useQuery({
  //   queryKey: [key, "orders"],
  //   queryFn: fetchOrdersList,
  // });

  const notificationInfo = (title, text) => {
    api["info"]({
      message: title,
      description: text,
      duration: 5000,
    });
  };

  const redirectToBase = (baseName) => {
    setBasesFilters({ bases: [baseName] });
    navigate("/bases");
  };
  const openNotification = () => {
    api["info"]({
      message: "Refund",
      description: t("refundSupportMessage", {
        time_to_auto: timeToAuto,
        time_to_support: timeToSupport,
      }),
      duration: 5000,
    });
  };
  const openNotificationError = (textError) => {
    api["error"]({
      message: "Error",
      description: textError,
      duration: 5000,
    });
  };
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isRatePopupOpen, setIsRatePopupOpen] = useState(false);
  const [choosedCard, setChoosedCard] = useState({});

  useEffect(() => {
    if (data && data.history) {
      const filtered = filterData(data.history, filters);
      setFilteredData(filtered);
    }
  }, [filters, data]);

  if (isLoading || !data) {
    return <Loader />;
  }

  const handleOpenFullInfo = (card) => {
    setChoosedCard(card);
    setIsPopupOpen(true);
  };
  const handleOpenRate = (card) => {
    setChoosedCard(card);
    setIsRatePopupOpen(true);
  };

  const reversedData = Array.isArray(filteredData)
    ? [...filteredData].reverse()
    : [];

  const open = async (id, isNonref) => {
    setLoadingForButton(`open-${id}`, true);
    const res = await fetchOpen(key, id);

    if (res.ok === true) {
      queryClient.invalidateQueries("orders");
      isNonref === true && openNotification();
    } else {
      openNotificationError(res.error);
    }
    setLoadingForButton(`open-${id}`, false);
  };

  const refund = async (id) => {
    setLoadingForButton(`refund-${id}`, true);
    notificationInfo("Info", t("cardCheckMessage"));
    let res = await fetchRefund(key, id);
    if (res.ok === true) {
      queryClient.invalidateQueries("orders");
    } else {
      openNotificationError(res.error);
    }
    setLoadingForButton(`refund-${id}`, false);
  };

  const check = async (id) => {
    setLoadingForButton(`check-${id}`, true);
    let res = await fetchCheck(key, id);
    if (res.ok === true) {
      queryClient.invalidateQueries("orders");
    }
    setLoadingForButton(`check-${id}`, false);
  };

  // const redirectToPreorderPage = () => {};

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    // console.log(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const exportToCsv = async (ids) => {
    setLoadingForButton(`csv`, true);
    let res = await fetchExportIds(key, ids);
    if (!res || res.status !== 200) {
      console.error("Failed to download CSV:", res.statusText);
    } else {
      const blob = await res.data;
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "export.csv";
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    }
    setLoadingForButton(`csv`, false);
    setSelectedRowKeys([]);
  };
  const deleteItems = async (ids) => {
    setLoadingForButton(`delete`, true);
    let res = await fetchDeleteHistItem(key, ids);
    if (res.ok === true) {
      queryClient.invalidateQueries("orders");
    } else {
    }
    setLoadingForButton(`delete`, false);
    setSelectedRowKeys([]);
  };
  const updateTable = () => {
    queryClient.invalidateQueries("orders");
  };

  const isRated = data?.history.some(
    (item) =>
      item.billing_information_rate === "-" &&
      item.money_balance_rate === "-" &&
      item.price_rate === "-"
  );

  return (
    <div className="ordersPage__cont">
      {contextHolder}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <h1>{t("History")}</h1>
        <div style={{ display: "flex", gap: "10px" }}>
          <CustomBlueButton
            label={t("Update table")}
            height={"37px"}
            borderRadius={"10px"}
            onClick={() => updateTable()}
            // disabled={selectedRowKeys.length == 0}
          />
          <CustomBlueButton
            label={t("Export csv")}
            isLoading={isLoadingBtn[`csv`]}
            height={"37px"}
            borderRadius={"10px"}
            onClick={() => exportToCsv(selectedRowKeys)}
            disabled={selectedRowKeys.length == 0}
          />
          <CustomBlueButton
            label={t("Delete")}
            color={"lightBlue"}
            height={"37px"}
            isLoading={isLoadingBtn[`delete`]}
            borderRadius={"10px"}
            onClick={() => deleteItems(selectedRowKeys)}
            disabled={selectedRowKeys.length == 0}
          />
        </div>
      </div>
      {data?.money_on_rate > 0 && isRated === true && (
        <div className="moneyOnRateBanner">
          <CiMoneyCheck1 size={"25px"} />
          <span>{t("message_with_money", { money: data?.money_on_rate })}</span>
        </div>
      )}

      {data?.user_ref_perc != null && (
        <div className="refundRed">
          <Tooltip
            placement="bottom"
            title={t("refundMessage", {
              user_ref_perc: Number(data?.user_ref_perc).toFixed(2),
              ref_percent: Number(data?.ref_percent).toFixed(2),
            })}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <IoWarningOutline size={"25px"} className="warnIcon" />
              <span>
                {t("refundDetails", {
                  ref_percent: data?.ref_percent,
                  ref_hours: data?.ref_hours,
                })}
              </span>
            </div>
          </Tooltip>
        </div>
      )}

      <CustomTable
        rowKey={"hid"}
        data={reversedData}
        // data={filteredData}
        columns={purchasedCardsColumns(
          handleOpenFullInfo,
          handleOpenRate,
          redirectToBase,
          open,
          refund,
          check,
          isLoadingBtn
        )}
        rowSelection={rowSelection}
      />
      {isPopupOpen && (
        <HistoryFullInfoPopup
          isPopupOpen={isPopupOpen}
          setIsPopupOpen={setIsPopupOpen}
          choosedCard={choosedCard}
          key={choosedCard.hid}
        />
      )}
      {isRatePopupOpen && (
        <HistoryRatePopup
          isPopupOpen={isRatePopupOpen}
          setIsPopupOpen={setIsRatePopupOpen}
          choosedCard={choosedCard}
          key={choosedCard.hid}
        />
      )}
    </div>
    // </div>
  );
}

export default OrdersPage;
