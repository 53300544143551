import React from "react";
import CustomPopup from "./CustomPopup";
import "../styles/components/HistoryFullInfoPopup.scss";
import CardBrandIcon from "./CardBrandIcon";
import FullInfoRating from "./FullInfoRating";
import useBasesFiltersStore from "../stores/basesFiltersStore.js";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import CountryFlagIcon from "./CountryFlagIcon";
import { Divider } from "antd";

function HistoryRatePopup({ isPopupOpen, setIsPopupOpen, choosedCard }) {
  const navigate = useNavigate();
  const { setFilters: setBasesFilters } = useBasesFiltersStore();
  const [isRated, setisRated] = React.useState(false);
  // React.useEffect(() => {
  //   return () => {
  //     setisRated(false);
  //   };
  // }, [choosedCard]);
  const redirectToBase = (baseName) => {
    setBasesFilters({ bases: [baseName] });
    navigate("/bases");
  };
  return (
    <CustomPopup
      isOpened={isPopupOpen}
      label={"Rate this card"}
      setIsOpened={setIsPopupOpen}
    >
      <FullInfoRating
        isRated={isRated}
        setisRated={setisRated}
        choosedCard={choosedCard}
        billRate={choosedCard.billing_information_rate}
        moneyBalRate={choosedCard.money_balance_rate}
        priceRate={choosedCard.price_rate}
      />
    </CustomPopup>
  );
}

export default HistoryRatePopup;
