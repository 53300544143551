import axios from "axios";
import {
    API_URL
} from "../apiConf";
export const selectOptions = [{
        value: "ignore",
        label: "ignore"
    },
    {
        value: "number",
        label: "number"
    },
    {
        value: "cvv",
        label: "cvv"
    },
    {
        value: "month",
        label: "month"
    },
    {
        value: "year",
        label: "year"
    },
    {
        value: "exp",
        label: "exp"
    },
    {
        value: "holder",
        label: "holder"
    },
    {
        value: "address",
        label: "address"
    },
    {
        value: "phone",
        label: "phone"
    },
    {
        value: "email",
        label: "email"
    },
    {
        value: "state",
        label: "state"
    },
    {
        value: "city",
        label: "city"
    },
    {
        value: "zip",
        label: "zip"
    },
    {
        value: "snn",
        label: "snn"
    },
    {
        value: "dob",
        label: "dob"
    },
    {
        value: "dl",
        label: "dl"
    },
    {
        value: "ip",
        label: "ip"
    },
    {
        value: "ua",
        label: "ua"
    },
    {
        value: "password",
        label: "password"
    },
];


// export const uploadCsv = async (key, delim, base, select) => {
//     const prepareBase = (base) => {
//         const lines = base.split("\n").map(line => line.trim());
//         const preparedLines = lines.map((line, index) => {
//             return index === lines.length - 1 ? line : line + '\n';
//         }).join('');
//         return encodeURIComponent(preparedLines);
//     };

//     const preparedBase = prepareBase(base);
//     const url = `${API_URL}/api/seller/seller_upload/?key=${key}&delimiter=${encodeURIComponent(delim)}&base=${preparedBase}&select=${encodeURIComponent(select)}`;
//     const response = await axios.post(url);
//     return response.data;
// };


export const getDelim = async ({
    queryKey
}) => {
    const [key] = queryKey;
    const {
        data
    } = await axios.get(`${API_URL}/api/seller/seller_upload/?key=${key}&get_format=1`);
    return data;
};



export const uploadCsv = async (key, delim, base, select) => {
    const prepareBase = (base) => {
        const lines = base.split("\n").map(line => line.trim());
        const preparedLines = lines.map((line, index) => {
            return index === lines.length - 1 ? line : line + '\n';
        }).join('');
        return preparedLines;
    };

    const preparedBase = prepareBase(base);

    const url = `${API_URL}/api/seller/seller_upload/`;
    const data = {
        key: key,
        delimiter: delim,
        base: preparedBase,
        select: select.join()
    };

    const response = await axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response.data;
};