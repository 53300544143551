import React from "react";

export default function Info() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   xlink="http://www.w3.org/1999/xlink"
    >
      <rect width="14" height="14" fill="url(#pattern0_8_748)" />
      <defs>
        <pattern
          id="pattern0_8_748"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use href="#image0_8_748" transform="scale(0.0111111)" />
        </pattern>
        <image
          id="image0_8_748"
          width="90"
          height="90"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD6ElEQVR4nO2dSWsUQRSAHxg33K5uoBL9Ay4nFUVUcAH1oIILuF8ixBj07M0FNwL+DdeDoj8gRpSY6MGL4sE9JsaTCSR+8kgJEWZ0JN31qrvfBwNhmOXVx6S6llevRRzHcRzHcRzHcZzGACYBq4CjwCXgNtALvAYGgOHwGAjP9YbXXAzvWamf0eDXVQtgIXAKuAd8Z+IMAneBVmCBVBlgOnAQeASMkh8jwEPgADBNqgIwM/zS3hOfL8A5YI6UFWAycAboxx6NoV1jkjIBrAVekB6vgI1SdLRPBG4AP0kXja0DmCpFBFgMPKY4PAOWSpHQf8eMhmmx0WHhBikCwC7gB8VlGNgrKQOcyHlMHAttw3FJEWBnmByUhVFgj6SE9mvAEOVjGNgsKQAsK+iF738ukM3WkqeGYVHZeWo6zg6TkarQYSV5ncGMrxO4Gh76d0y0rWssFoheRmzkN2BLjTi2hj40FrrJ0BRTtK7CxWTLX2LZFjmWtliSZ0Ve6uxsIKauiPH06Zp6DNFnicvlBmK6Ejmm9hjbT58SFH01ckwfct0WC3t8selMrOv4zf48RetGqgVb/xLTdqOYHuSZEmC1Mjeoo4s6kmMO78ajC2jz8hCteRfWdIX++BrwxDoY4GQeojW5xfmTm3mkaenszPmTb5mmn4VcOKc2K7IUfazOlzhwOEvRmtXp1OZ8lqLv1PkShwwviECPG61Ld5ai32KM1I/NmjdZijbPAJV0RfdlKVq33U2RdEUPuegCivauI1LX4RfDSBdD8+Gd1I+tVMM78wmLpCv6Zqmm4JKu6Eyn4Hoi1RRJV/ShUi2TSrqil5dq4V/SFJ3twn9okJ6tNkPSFJ3tVlZokB4rNkPSFN2Sh+j5lmdUJD3R6mJu5qJDo7RKgAmSnuj7uUg2TAlLVfS+vM92W5R/IDHRHzXhMzfRRknoKYo+navk0LAZIRm7qqK/RklED43ToiJVFd0aRXJoXBPwnOrxInrVGj0KlnjBk6zRtq6LKnmcbK3cUhWum0ged0RZj++WnSfAFDPRQXazYdZ9rBW6JZICwPqCV52ph+azbJKUAHaUsDDKbkkRLY9TklI/I5oTLikTSv4UuRsZSq7Ezz9K/wwW9MK3XooEsMigpsZEeGpe0meC4+yOxGeQGtt183FyhtP1HtJDY1otZYKxhag2iyXWGvSFzeZ41WRiw9h6tjbynYHgz6FQ92ypCoxti+3XKgE5T3T0s+/rHl+lSs/XQqsE6AF24Fa4G8VEGQif1ZJbSkDRYSz9bDlwBLig2UCagxxuBdI/7vYg/eG57vAafe3h8F6/PYjjOI7jOI7jOI40yC8w//Rf6+5qZAAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  );
}
