import React from "react";

export default function Statistic() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <rect width="16" height="16" fill="url(#pattern0_8_742)" />
      <defs>
        <pattern
          id="pattern0_8_742"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use href="#image0_8_742" transform="scale(0.0111111)" />
        </pattern>
        <image
          id="image0_8_742"
          width="90"
          height="90"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEAklEQVR4nO2czYtWVRzHf2OTUDFCg6iI1KhQQYjkSxuzDBMXCi7EltFCGIosBV8GBBcudFIQ09JpZQXhyk0JKhqhEFHqPxC6m/Fl4WtNiWWfOM5BfLm/M/NMz7n3nHt/H3g2D+f5ne/93DvznHuec4+IYRiGYRiGYRiGYRgpA0yoOkOtAZ4BPgdu+td+915JfU8DPgGOAkPAX/416N/7GJgqdQDYy5PsidxnN7APuMPouDafAc9LzgBXCg7ucsT+lgOXaB13xb8juaIdVaS+PgT+Yfz8DfRKjpQlGlhHe/jXnTDJjTJEA+96Qe3C1VotORFbNDAH+CMg7R7wLbAEeM6/3gYOj3JyfgdelVyIKRroBM4FZF0DlgY+vwy4Hvj8WeApyYHIovsCkm4AC8dQ4zV/QjQ2SpNFAy8Bfyrl3fvzW6j1ur+RKWIYmC0NFn0qcBV+NI567g5S47g0UTQjX2wax4COcdTsAL4P1H1TGij6x8C/jBf+R90Z/l9FESelSaKBRYGrbnsb8u4M1F8sDRL9g1LyKjCpDXm7lPkZxwlpgmhGhmIaG9qYeVOgn7nSANEHlHLupqOrjZkn+bnzIvZLnUUz8gOCuwkpYkeE3LuVvtwJeFZqLPp9pdRdYHqE3DP8tGkR70mNRf+klDoSJ/n9Pr9T+jwjdRQNvKLVAVZEzL5K6dPN+r0sNRS9TSlz2c3gRcze6fsoYqvUUPR5pczOeMlH/VI8J3USDfQEJuijj2mBBdoxADOlRqI3KCUuxk3+SIYLSob1UiPRZ8oaOwcy7FIynJY6iAamBJYPzIuf/kGOhYHfI6dJHa5oY4yY6JIw0SVhokvCRJeEiU5INDAR+NQvtx0C+t17gZpZt69StAv2OP2Bmlm3r1L0pVYWq5N5+ypFuz+3xxkM1My6fZWi+1uZAiXz9lWKnujDDrXw5dOfa/vKRBttwESXhIkuCRNdEia6JEx0gqIDD//sk8QAvlCy9uUg+hulea8khn8cuoivcxCtLZR5QxIDeEvJejZp0W7jlMATsN2SGMBkJetwJZvAtCB6ltJ0UBIl8AhGT8qiV+b23Aj68zQrUha9RWm6RxLF73JTxKaURX+lNF0rieJGQ0rmQymLdjsJFLFEEsVtDaRk/iVl0beUpi9Korhlu0rmm0mKDgyV7qa8X4Z/IsBlrH5IOkbR2mrN3yRx3DptJfv8FEW7PZGy3L4BOKlkX5OiaG1od0ASB/hSyb45RdEDyYxHWyRwkRyUMgFu01xu+H1Z4+/FCvxc9dEmwECV87ZNYjj6jJ5fXKLd9TWF4VKmTv0+zr/SXA5Gl/yQ7KeBD/wuBW47yiZw3c/wlbIxuWEYhmEYhmEYhmEYhiTKf9PSN22Sz8BRAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
}
