import "../styles/components/CustomBlueButton.scss";

function CustomRedBtn({
  label,
  onClick,
  height,
  width,
  color,
  fontSize,
  borderRadius = 15,
  icon = "",
  type = "button",
}) {
  return (
    <button
      type={type}
      onClick={onClick}
      className={
        color === "red"
          ? "CustomBlueButton redbtn"
          : "CustomBlueButton blue"
      }
      style={{
        width: width,
        height: height,
        fontSize: fontSize,
        borderRadius: borderRadius,
      }}
    >
      {icon != "" ? <img src={icon} style={{ marginRight: "10px" }} /> : <></>}
      {label}
    </button>
  );
}

export default CustomRedBtn;
