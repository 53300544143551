import React from "react";
import { API_URL } from "../apiConf";
import axios from "axios";
import useAuthStore from "../stores/userStore.js";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Loader from "../components/Loader";
import MultiSelect from "../components/MultiSelect";
import CustomBlueButton from "../components/CustomBlueButton";
import CustomTable from "../components/CustomTable/CustomTable";
import invitesColumns from "../components/CustomTable/invitesColumns";
import "../styles/pages/InvitePage.scss";
import { useTranslation } from "react-i18next";

const fetchData = async (queryKey) => {
  const { data } = await axios.get(
    `${API_URL}/api/seller/invites/?key=${queryKey}`
  );
  return data;
};

const fetchCreateCode = async (key, type) => {
  const { data } = await axios.post(
    `${API_URL}/api/seller/invites/?key=${key}&inv_type=${type}`
  );
  return data;
};
const fetchRemoveCode = async (key, id) => {
  const { data } = await axios.post(
    `${API_URL}/api/seller/invites/?key=${key}&cancel=${id}`
  );
  return data;
};

function InvitePage() {
  const [isLoadingBtn, setIsLoadingBtn] = React.useState(false);

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { key } = useAuthStore((state) => ({ key: state.key }));
  const [type, setType] = React.useState(undefined);

  const { data, isLoading } = useQuery({
    queryKey: ["fetchInvite"],
    queryFn: () => fetchData(key),
  });

  if (isLoading) {
    return <Loader />;
  }

  const createCode = async (type) => {
    setIsLoadingBtn(true);
    const res = await fetchCreateCode(key, type);
    if (res.ok === true) {
      queryClient.invalidateQueries("fetchInvite");
    }
    setType(undefined);
    setIsLoadingBtn(false);
  };
  const removeCode = async (id) => {
    const res = await fetchRemoveCode(key, id);
    if (res.ok === true) {
      queryClient.invalidateQueries("fetchInvite");
    }
  };

  return (
    <div className="invite-page_cont">
      <h1>{t("Invite codes")}</h1>
      <div className="create-code_cont">
        <MultiSelect
          placeholder={t("Select type")}
          value={type}
          onChange={(option) => setType(option)}
          isMulti={false}
          options={[
            { value: 1, label: "One-time" },
            { value: 0, label: "Unlimited" },
          ]}
        />
        <CustomBlueButton
          disabled={type === undefined}
          isLoading={isLoadingBtn}
          label={t("Create code")}
          width={"117px"}
          onClick={() => createCode(type)}
          height={"37px"}
          borderRadius={"10px"}
        />
      </div>
      <CustomTable data={data?.invites} columns={invitesColumns(removeCode)} />
    </div>
  );
}

export default InvitePage;
