import { Checkbox } from "antd";
import "../styles/components/CustomCheckBox.scss";
function CustomCheckBox({ onChange, label, labelColor = "white", checked }) {
  return (
    <div className="CustomCheckBox">
      <span style={{ color: labelColor, marginRight: "10px" }}>{label}</span>
      <Checkbox onChange={onChange} className={"Checkbox"} checked={checked} />
    </div>
  );
}

export default CustomCheckBox;
