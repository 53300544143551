import React, { useState } from "react";
import CustomBlueButton from "../components/CustomBlueButton";
import "../styles/pages/ProfilePage.scss";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useAuthStore from "../stores/userStore.js";
import { fetchProfileInfo } from "../utils/profileUtils";
import dayjs from "dayjs";
import ChangePasswordPopup from "../components/ChangePasswordPopup";
import ChangeCredsPopup from "../components/ChangeCredsPopup";
import promoColumns from "../components/CustomTable/promoColumns";
import CustomTable from "../components/CustomTable/CustomTable";
import { Button, notification } from "antd";
import Loader from "../components/Loader.jsx";
import ChangePasswordComponent from "../components/ChangePasswordComponent.jsx";
import GiftCodesComponent from "../components/GiftCodesComponent.jsx";
import giftsProfileColumns from "../components/CustomTable/giftsProfileColumns.jsx";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ProfilePage() {
  const [isLoadingBtn, setIsLoadingBtn] = useState({});
  const setLoadingForButton = (buttonId, isLoading) => {
    setIsLoadingBtn((prev) => ({
      ...prev,
      [buttonId]: isLoading,
    }));
  };

  const { t } = useTranslation();
  const [credsPopup, setCredsPopup] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const { origin, port } = window.location;
  const [refLink, setRefLink] = useState("");

  const openNotificationWithIcon = (type, message, desc, duration) => {
    api[type]({
      message: message,
      description: desc,
      duration: duration,
    });
  };

  const { key, balance } = useAuthStore((state) => ({
    key: state.key,
    balance: state.balance,
  }));
  const { data, isLoading } = useQuery({
    queryKey: ["call", key],
    queryFn: fetchProfileInfo,
  });

  const userInfo = data?.user_info;
  const regDate = dayjs(userInfo?.reg_date).format("YYYY-MM-DD HH:mm:ss");

  React.useEffect(() => {
    if (userInfo?.balance != balance) {
      useAuthStore
        .getState()
        .setUser({ balance: userInfo?.balance.toFixed(2) });
    }
    setRefLink(origin + "/?ref=" + userInfo?.ref_code);
  }, [data]);
  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(refLink);
      openNotificationWithIcon("success", "Info", "Link copied", 2000);
    } catch (error) {
      openNotificationWithIcon("error", "Info", "Link not copied", 2000);
    }
  };

  if (isLoading || !data) {
    return <Loader />;
  }

  return (
    <div className="ProfilePage__cont">
      {contextHolder}
      <h1>{t("Profile")}</h1>
      <div className="profileInfo_cont">
        <div className="left-side">
          <div className="info-box">
            <div className="box-item">
              <span className="box-item_bold">Login</span>
              <span className="box-item_bold">{userInfo?.login}</span>
            </div>
            <div className="box-item">
              <span>{t("Trust score")}</span>
              <span>{userInfo?.trust_score}</span>
            </div>

            <div className="box-item">
              <span>{t("Refund (pcs.)")}</span>
              <span>{userInfo?.refund_pcs}</span>
            </div>

            <div className="box-item">
              <span>{t("Total (pcs.)")}</span>
              <span>{userInfo?.total_pcs}</span>
            </div>
            <div className="box-item">
              <span>{t("Register date")}</span>
              <span>{regDate}</span>
            </div>
            <div className="box-item">
              <span>{t("Total deposit")}</span>
              <span>${userInfo?.total_deposit.toFixed(2)}</span>
            </div>
            <div className="box-item">
              <span>{t("Total spent")}</span>
              <span>${userInfo?.total_spent.toFixed(2)}</span>
            </div>

            <div className="box-item">
              <span>{t("Total refund")}</span>
              <span>${userInfo?.refund.toFixed(2)}</span>
            </div>

            <div className="box-item">
              <span>{t("User status")}</span>
              <span>{userInfo?.discount_info?.status} (-{userInfo?.discount_info?.discount}%) </span>
            </div>

          
          </div>
          {/* //////////// */}

          <ChangePasswordComponent
            openNotification={openNotificationWithIcon}
          />
        </div>
        <div className="right-side">
          <div className="info-box">
            <div className="box-item">
              <span className="box-item_bold">
                {t("Refferal system")} ({userInfo?.ref_perc}%):
              </span>
              <span></span>
            </div>
            <div className="box-item">
              <span>{t("Your Refferal Code")}:</span>
              <span className="box-item_bold">{userInfo?.refcode}</span>
            </div>
            <div className="box-item">
              <span>{t("Total referals")}</span>
              <span>{userInfo?.referrals}</span>
            </div>
            <div className="box-item">
              <span>{t("Total profit")}</span>
              <span>${userInfo?.ref_balance}</span>
            </div>
            <div className="box-item" style={{ marginTop: "10px" }}>
              <span className="refLink">{refLink}</span>
              <CustomBlueButton
                label={t("Copy link")}
                borderRadius={"10px"}
                height={"37px"}
                onClick={() => handleCopyToClipboard()}
              />
            </div>
          </div>
          <GiftCodesComponent
            openNotification={openNotificationWithIcon}
            isLoadingBtn={isLoadingBtn}
            setLoadingForButton={setLoadingForButton}
          />
          <div className="info-box tableBox">
            <div className="box-item">
              <span className="box-item_bold">{t("Active Gift Codes")}</span>
            </div>
            <CustomTable
              data={data?.gifts}
              columns={giftsProfileColumns}
              // pagination={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;
