import React from "react";
import CustomTextarea from "./CustomTextarea";
import CustomBlueButton from "./CustomBlueButton";
import { checkZips } from "../utils/toolsUtils";
import useAuthStore from "../stores/userStore.js";

const CheckZip = () => {
  const [zips, setZips] = React.useState("");
  const [checkRes, setCheckRes] = React.useState([]);
  const { key } = useAuthStore((state) => ({ key: state.key }));
  const handleInputChange = (e) => {
    const value = e.target.value.replace(/\n/g, "");
    let formattedValue = "";

    for (let i = 0; i < value.length; i += 6) {
      if (i + 6 < value.length) {
        formattedValue += value.slice(i, i + 6) + "\n";
      } else {
        formattedValue += value.slice(i);
      }
    }

    setZips(formattedValue);
  };

  const handleCheckZips = async () => {
    const binArray = zips.split("\n").filter((bin) => bin.trim() !== "");
    if (binArray.length > 100) {
      alert("You can only enter up to 100 bins.");
      return;
    }
    const zipString = binArray.join(",");

    const res = await checkZips(zipString, key);

    if (res.ok === true) {
      setCheckRes(res.res);
    }
  };

  return (
    <div
      style={{
        marginTop: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <CustomTextarea
        placeholder={"Enter Zips"}
        width={"100%"}
        value={zips}
        onChange={handleInputChange}
      />
      <CustomBlueButton
        disabled={zips.length === 0}
        label={"Check Zips (-0.1$)"}
        width={"140px"}
        height={"35px"}
        borderRadius={"10px"}
        onClick={handleCheckZips}
      />
      {checkRes.length > 0 ? (
        checkRes.map((item, index) => <p key={index}>{item}</p>)
      ) : (
        <></>
      )}
    </div>
  );
};

export default CheckZip;
