import React from "react";
import { Button, ConfigProvider, Divider, Dropdown, Space } from "antd";
import Cart from "../assets/icons/Cart";
import { Link } from "react-router-dom";
import useCartStore from "../stores/cartStore";
import "../styles/components/MiniCart.scss";
import CustomBlueButton from "./CustomBlueButton";
import { useTranslation } from "react-i18next";

function MiniCart() {
  const {t} = useTranslation()


  const items = [];
  const { cart } = useCartStore();
  const totalSum = cart.reduce((acc, card) => acc + card.price, 0);
  cart.map((item, index) =>
    items.push({
      key: item.id,
      label: (
        <div className="miniCart_item">
          <span>{item.bin}</span> <span>${item.price.toFixed(2)}</span>
        </div>
      ),
    })
  );

  const menuStyle = {
    boxShadow: "none",
  };
  return (
    <div className="miniCart_container">
      {!cart.length ? (
        <Dropdown
          menu={{
            selectable: false,
            items,
          }}
          placement="bottomRight"
          arrow={{
            pointAtCenter: true,
          }}
          dropdownRender={(menu) => (
            <div className="miniCart_cont isEmpty">
              <h2>{t("Cart is empty")}</h2>
            </div>
          )}
        >
          <Link to={"/cart"}>
            <Cart />
          </Link>
        </Dropdown>
      ) : (
        <Dropdown
          menu={{
            selectable: false,
            items,
          }}
          placement="bottomRight"
          arrow={{
            pointAtCenter: true,
          }}
          dropdownRender={(menu) => (
            <div className="miniCart_cont">
              <h2>Shopping Cart</h2>
              {React.cloneElement(menu, {
                style: menuStyle,
              })}

              <div className="miniCart_footer">
                <div>
                  Total:{" "}
                  <span
                    style={{
                      color: "#2590EE",
                      fontWeight: "700",
                    }}
                  >
                    ${totalSum.toFixed(2)}
                  </span>
                </div>
                <Link className="miniCart_link" to={"/cart"}>
                  To cart
                </Link>
              </div>
            </div>
          )}
        >
          <Link to={"/cart"}>
            <Cart />
            <span className="cartAmount">
              {cart.length > 0 && `${cart.length}`}
            </span>
          </Link>
        </Dropdown>
      )}
    </div>
  );
}

export default MiniCart;
