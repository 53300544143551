import React from "react";
const toolsColumns = [
  {
    title: "CC",
    dataIndex: "cc",
    key: "cc",
  },

  {
    title: "Create date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Month",
    dataIndex: "date_m",
    key: "date_m",
  },
  {
    title: "Year",
    dataIndex: "date_y",
    key: "date_y",
  },
  {
    title: "Cvv",
    dataIndex: "cvv",
    key: "cvv",
  },
  {
    title: "Result",
    dataIndex: "result",
    key: "result",
  },
];

export default toolsColumns;
