import axios from "axios";
import {
    API_URL
} from "../apiConf";
export const fetchBases = async ({
    queryKey
}) => {
    const [key] = queryKey;
    const {
        data
    } = await axios.get(`${API_URL}/api/bases_list/?key=${key}`);
    return data;
};
import dayjs from 'dayjs';
export const filterData = (data, filters) => {
    let filteredData = data;
    console.log('filteredData', filteredData)
    const from = filters.updateFrom ? dayjs(filters.updateFrom, "YYYY-MM-DD HH:mm:ss") : null;
    const to = filters.updateTo ? dayjs(filters.updateTo, "YYYY-MM-DD HH:mm:ss") : null;
    console.log(from, to)
    // Date filtering
    if (from && to) {
        filteredData = filteredData.filter((card) => {
            const cardDate = dayjs(card.upload_date, "YYYY-MM-DD HH:mm:ss");
            return cardDate.isAfter(from) && cardDate.isBefore(to);
        });
    }

    if (filters.countries.length > 0) {
        filteredData = filteredData.filter((card) =>
            card.country.includes(filters.countries)
            // filters.countries.includes(card.country)
        );
    }

    if (filters.sellers.length > 0) {
        filteredData = filteredData.filter((card) =>
            filters.sellers.includes(card.seller)
        );
    }
    if (filters.bases.length > 0) {
        filteredData = filteredData.filter((card) =>
            filters.bases.includes(card.basename)
        );
    }
    if (filters.refund.length > 0) {
        filteredData = filteredData.filter((card) =>
            filters.refund.includes(card.ref.toString())
        );
    }


    // if (filters.priceFrom || filters.priceTo) {
    //     const minPrice = parseFloat(filters.priceFrom);
    //     const maxPrice = parseFloat(filters.priceTo);

    //     filteredData = filteredData.filter((card) => {
    //         const price = parseFloat(card.price);
    //         return (
    //             (isNaN(minPrice) || price >= minPrice) &&
    //             (isNaN(maxPrice) || price <= maxPrice)
    //         );
    //     });
    // }

    if (filters.cvv) {
        filteredData = filteredData.filter((card) => card.info.includes('CVV'));
    }
    if (filters.address == true) {
        filteredData = filteredData.filter((card) => card.info.includes('ADRESS'));
    }
    if (filters.snn == true) {
        filteredData = filteredData.filter((card) => card.info.includes('SNN'));
    }
    if (filters.email == true) {
        filteredData = filteredData.filter((card) => card.info.includes('EMAIL'));
    }
    if (filters.password == true) {
        filteredData = filteredData.filter((card) => card.info.includes('PASS'));
    }
    if (filters.dob == true) {
        filteredData = filteredData.filter((card) => card.info.includes('DOB'));
    }
    if (filters.ua == true) {
        filteredData = filteredData.filter((card) => card.info.includes('UA'));
    }
    if (filters.dl == true) {
        filteredData = filteredData.filter((card) => card.info.includes('DL'));
    }
    if (filters.ip == true) {
        filteredData = filteredData.filter((card) => card.info.includes('IP'));
    }
    if (filters.holder == true) {
        filteredData = filteredData.filter((card) => card.info.includes('HOLDER'));
    }
    if (filters.phone == true) {
        filteredData = filteredData.filter((card) => card.info.includes('PHONE'));
    }
    // if (filters.cvv) {

    //     filteredData = filteredData.filter((card) => card.list.includes('cvv'));
    // }


    return filteredData;
};


///// bases for seller 

export const fetchSellerBases = async ({
    queryKey
}) => {
    const [key] = queryKey;
    const {
        data
    } = await axios.get(`${API_URL}/api/seller/bases_list_user/?key=${key}`);
    return data;
};