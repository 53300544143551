import Flag from "react-world-flags";
import { countryCodes } from "../utils/ordersUtils";
import { Tooltip } from "antd";
function getCountryCode(countryName) {
  if (countryName == null) {
    return "Unknown";
  } else {
    return countryCodes[countryName.toUpperCase()] || "Unknown";
  }
}

function CountryFlagIcon({
  countryName,
  countryCodeParam = "",
  showCountryCode = false,
  showToolTipCode = true,
}) {
  if (countryCodeParam != "") {
    return (
      <Tooltip placement="top" title={countryCodeParam}>
        <Flag code={countryCodeParam} height="20px" width="30px" />
      </Tooltip>
    );
  }
  const countryCode = getCountryCode(countryName);

  return showCountryCode ? (
    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
      <Flag code={countryCode} height="20px" width="30px" />{" "}
      {showCountryCode && <span>{countryCode}</span>}
    </div>
  ) : showToolTipCode ? (
    <Tooltip placement="top" title={countryCode}>
      <Flag code={countryCode} height="20px" width="30px" />
    </Tooltip>
  ) : (
    <Flag code={countryCode} height="20px" width="30px" />
  );
}
export default CountryFlagIcon;
