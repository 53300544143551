import {
    create
} from 'zustand';
import {
    persist
} from 'zustand/middleware';
import {
    cartCheck
} from '../utils/cartUtils';

const useCartStore = create(persist(
    (set, get) => ({
        cart: [],

        addToCart: async (inputIds, key) => {
            const {
                cart
            } = get();
            const ids = Array.isArray(inputIds) ? inputIds : [inputIds];
            const cartIds = cart.map(item => item.id)
            const newIds = ids.filter(id => !cartIds.includes(id))
            if (newIds.length > 0) {
                const newCartItems = await cartCheck(newIds, key);
                set({
                    cart: [...cart, ...newCartItems.card_list]
                })
            }

        },

        removeFromCart: (id) => {
            const {
                cart
            } = get();
            set({
                cart: cart.filter(item => item.id !== id)
            });
        },

        clearCart: () => {
            set({
                cart: []
            });
        },
    }), {
        name: "cart-storage",
        getStorage: () => localStorage,
    }
));

export default useCartStore;