import axios from "axios";
import {
  API_URL
} from "../apiConf";




export const fetchOrdersList = async ({
  queryKey
}) => {
  const [key] = queryKey;
  const {
    data
  } = await axios.get(`${API_URL}/api/history_card/?key=${key}`);
  return data;
};





export const fetchPreordersList = async ({
  queryKey
}) => {
  const [key] = queryKey;
  const {
    data
  } = await axios.get(`${API_URL}/api/preorder/?key=${key}`);
  return data;
};






export const fetchRefund = async (
  key, id
) => {
  const {
    data
  } = await axios.get(`${API_URL}/api/history_card/?key=${key}&ref=${id}`);
  return data;
};



export const fetchCheck = async (
  key, id
) => {
  const {
    data
  } = await axios.get(`${API_URL}/api/history_card/?key=${key}&check=${id}`);
  return data;
};

import dayjs from "dayjs";

export const filterData = (data, filters) => {
  let filteredData = data;
  // console.log("filteredData", filteredData);
  const from = filters.updateFrom ?
    dayjs(filters.updateFrom, "YYYY-MM-DD HH:mm:ss") :
    null;
  const to = filters.updateTo ?
    dayjs(filters.updateTo, "YYYY-MM-DD HH:mm:ss") :
    null;

  // Date filtering
  if (from && to) {
    filteredData = filteredData.filter((card) => {
      const cardDate = dayjs(card.card_info.create_date, "YYYY-MM-DD HH:mm:ss");
      return cardDate.isAfter(from) && cardDate.isBefore(to);
    });
  }

  if (filters.bins.length > 0) {
    filteredData = filteredData.filter(
      (card) => card.card_info.bin.includes(filters.bins)
      // filters.bins.includes(card.card_info.bin)
    );
  }

  if (filters.sellers.length > 0) {
    filteredData = filteredData.filter((card) =>
      filters.sellers.includes(card.card_info.seller)
    );
  }
  if (filters.countries.length > 0) {
    filteredData = filteredData.filter((card) =>
      filters.countries.includes(card.card_info.binlist_country)
      // filters.countries.includes(card.country)
    );
  }
  if (filters.refund.length > 0) {
    filteredData = filteredData.filter((card) =>
      filters.refund.includes(card.type)
    );
  }
  if (filters.bases.length > 0) {
    filteredData = filteredData.filter((card) =>
      filters.bases.includes(card.card_info.list.base_name)
    );
  }

  return filteredData;
};

export const createUniqueOrdersOptions = (array, key) => {
  const uniqueItems = new Set();
  const options = [];

  array?.forEach((item) => {
    const value = item.card_info[key];
    if (value && !uniqueItems.has(value)) {
      uniqueItems.add(value);
      options.push({
        value,
        label: value,
      });
    }
  });

  return options;
};
export const createUniqueOrdersBaseOptions = (array, key) => {
  const uniqueItems = new Set();
  const options = [];

  array?.forEach((item) => {
    const value = item.card_info.list[key];
    if (value && !uniqueItems.has(value)) {
      uniqueItems.add(value);
      options.push({
        value,
        label: value,
      });
    }
  });

  return options;
};
export const createUniqueOrdersCountryOptions = (array, key) => {
  const uniqueItems = new Set();
  const options = [];

  array?.forEach((item) => {
    const value = item.card_info[key];
    if (value && !uniqueItems.has(value)) {
      uniqueItems.add(value);
      options.push({
        value,
        label: value,
      });
    }
  });

  return options;
};

export const fetchRatings = async (rates, key, id) => {
  const {
    data
  } = await axios.get(
    `${API_URL}/api/post_rate/?key=${key}&id=${id}&billing_information_rate=${rates.getBillRate}&price_rate=${rates.getMoneyBalRate}&money_balance_rate=${rates.getPriceRate}`
  );
  return data;
};
export const fetchOpen = async (key, id) => {
  const {
    data
  } = await axios.get(
    `${API_URL}/api/history_card/?key=${key}&open=${id}`
  );
  return data;
};




export const fetchExportIds = async (key, ids) => {
  const params = {
    ids: ids,
  };

  try {
    const response = await axios({
      method: 'post',
      url: `${API_URL}/api/history_card/?key=${key}`,
      data: params,
      responseType: 'blob'
    });
    return response;
  } catch (error) {
    console.error("Failed to fetch export ids:", error);
    return null;
  }
};

export const fetchDeleteHistItem = async (key, ids) => {
  const params = {
    delete: ids,
  }
  const {
    data
  } = await axios.post(
    `${API_URL}/api/history_card/?key=${key}`, params
  );
  return data;
};
































export const countryCodes = {
  FRANCE: "FR",
  "UNITED STATES": "US",
  "UNITED KINGDOM": "GB",
  "RUSSIAN FEDERATION": "RU",
  SWEDEN: "SE",
  EGYPT: "EG",
  SPAIN: "ES",
  GERMANY: "DE",
  AUSTRALIA: "AU",
  BANGLADESH: "BD",
  ECUADOR: "EC",
  ITALY: "IT",
  "KOREA, REPUBLIC OF": "KR",
  URUGUAY: "UY",
  BRAZIL: "BR",
  INDONESIA: "ID",
  JAPAN: "JP",
  UKRAINE: "UA",
  GUATEMALA: "GT",
  ARGENTINA: "AR",
  "COSTA RICA": "CR",
  INDIA: "IN",
  GREECE: "GR",
  TURKEY: "TR",
  BULGARIA: "BG",
  POLAND: "PL",
  NAMIBIA: "NA",
  DENMARK: "DK",
  THAILAND: "TH",
  CANADA: "CA",
  NETHERLANDS: "NL",
  ALBANIA: "AL",
  ROMANIA: "RO",
  "HONG KONG": "HK",
  PHILIPPINES: "PH",
  FINLAND: "FI",
  CHINA: "CN",
  SINGAPORE: "SG",
  CHILE: "CL",
  PORTUGAL: "PT",
  NORWAY: "NO",
  AUSTRIA: "AT",
  "SOUTH AFRICA": "ZA",
  BARBADOS: "BB",
  MALTA: "MT",
  MALAYSIA: "MY",
  "PALESTINIAN TERRITORY, OCCUPIED": "PS",
  MOROCCO: "MA",
  MEXICO: "MX",
  "CZECH REPUBLIC": "CZ",
  "DOMINICAN REPUBLIC": "DO",
  BELGIUM: "BE",
  TUNISIA: "TN",
  LUXEMBOURG: "LU",
  SLOVAKIA: "SK",
  UGANDA: "UG",
  "NEW ZEALAND": "NZ",
  COLOMBIA: "CO",
  SLOVENIA: "SI",
  ISRAEL: "IL",
  "VIET NAM": "VN",
  "VENEZUELA, BOLIVARIAN REPUBLIC OF": "VE",
  PAKISTAN: "PK",
  JAMAICA: "JM",
  IRELAND: "IE",
  ALGERIA: "DZ",
  "UNITED ARAB EMIRATES": "AE",
  GEORGIA: "GE",
  NIGERIA: "NG",
  ZAMBIA: "ZM",
  "TAIWAN, PROVINCE OF CHINA": "TW",
  ESTONIA: "EE",
  PANAMA: "PA",
  BELARUS: "BY",
  "SAUDI ARABIA": "SA",
  "MOLDOVA, REPUBLIC OF": "MD",
  ZIMBABWE: "ZW",
  LATVIA: "LV",
  "BOSNIA AND HERZEGOVINA": "BA",
  PERU: "PE",
  SUDAN: "SD",
  "LAO PEOPLE'S DEMOCRATIC REPUBLIC": "LA",
  "IRAN, ISLAMIC REPUBLIC OF": "IR",
  SERBIA: "RS",
  PARAGUAY: "PY",
  SWITZERLAND: "CH",
  LEBANON: "LB",
  KENYA: "KE",
  BAHRAIN: "BH",
  "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF": "MK",
  OMAN: "OM",
  QATAR: "QA",
  "SRI LANKA": "LK",
  CURACAO: "NL",
  UZBEKISTAN: "UZ",
  CAMEROON: "CM",
  "BRUNEI DARUSSALAM": "BN",
  GHANA: "GH",
  "COTE D'IVOIRE": "CI",
  "GUINEA-BISSAU": "GW",
  AZERBAIJAN: "AZ",
  "CONGO, THE DEMOCRATIC REPUBLIC OF THE": "CD",
  "TANZANIA, UNITED REPUBLIC OF": "TZ",
  "SYRIAN ARAB REPUBLIC": "SY",
  MONTENEGRO: "ME",
  KYRGYZSTAN: "KG",
  KAZAKHSTAN: "KZ",
  "TURKS AND CAICOS ISLANDS": "TC",
  BELIZE: "BZ",
  RWANDA: "RW",
  IRAQ: "IQ",
  "PUERTO RICO": "PR",
  CROATIA: "HR",
  CAMBODIA: "KH",
  NEPAL: "NP",
  ARMENIA: "AM",
  KUWAIT: "KW",
  DJIBOUTI: "DJ",
  HONDURAS: "HN",
  JORDAN: "JO",
  MAURITANIA: "MR",
  NICARAGUA: "NI",
  MALI: "ML",
  "TIMOR-LESTE": "TL",
  "VIRGIN ISLANDS, U.S.": "VI",
  HUNGARY: "HU",
  MACAO: "MO",
  "CAPE VERDE": "CV",
  "ANTIGUA AND BARBUDA": "AG",
  "CAYMAN ISLANDS": "KY",
  "BOLIVIA, PLURINATIONAL STATE OF": "BO",
  LITHUANIA: "LT",
  "EL SALVADOR": "SV",
  ANDORRA: "AD",
  ANGOLA: "AO",
  COMOROS: "KM",
  CYPRUS: "CY",
  TAJIKISTAN: "TJ",
  SENEGAL: "SN",
  "SIERRA LEONE": "SL",
  GAMBIA: "GM",
  GUINEA: "GN",
  LIBERIA: "LR",
  MONGOLIA: "MN",
  MONTSERRAT: "MS",
  BERMUDA: "BM",
  AFGHANISTAN: "AF",
  ICELAND: "IS",

  MAURITIUS: "MU",
  "TRINIDAD AND TOBAGO": "TT",
  "SAINT VINCENT AND THE GRENADINES": "VC",
  "ISLE OF MAN": "IM",
  "KOSOVO, REPUBLIC OF": "XK",
  GUAM: "GU",
  SURINAME: "SR",
  TURKMENISTAN: "TM",
  ARUBA: "AW",
  SAMOA: "WS",
  TONGA: "TO",
  TOGO: "TG",
  VANUATU: "VU",
  MOZAMBIQUE: "MZ",
  VIETNAM: "VN",
  MALDIVES: "MV",
  "BURKINA FASO": "BF",
  BENIN: "BJ",
  BOTSWANA: "BW",
  GIBRALTAR: "GI",
  GUYANA: "GY",
  DOMINICA: "DM",
  GABON: "GA",
  "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF": "KP",
  GRENADA: "GD",
  "SAINT LUCIA": "LC",
  CONGO: "CG",
  HAITI: "HT",
  MADAGASCAR: "MG",
  SEYCHELLES: "SC",
  "SAINT KITTS AND NEVIS": "KN",
  MYANMAR: "MM",
  BAHAMAS: "BS",
  MALAWI: "MW",
  "HOLY SEE (VATICAN CITY STATE)": "VA",
  SWAZILAND: "SZ",
  LESOTHO: "LS",
  "VIRGIN ISLANDS, BRITISH": "VG",
  CHAD: "TD",
  "CENTRAL AFRICAN REPUBLIC": "CF",
  BURUNDI: "BI",
  "LIBYAN ARAB JAMAHIRIYA": "LY",
  NIGER: "NG",
  ANGUILLA: "AI",
  YEMEN: "YE",
  LIECHTENSTEIN: "LI",
  "AMERICAN SAMOA": "AS",
  ETHIOPIA: "ET",
  FIJI: "FJ",
  "SOLOMON ISLANDS": "SB",
  "COOK ISLANDS": "CK",
  "EQUATORIAL GUINEA": "GQ",
  "SINT MAARTEN (DUTCH PART)": "SX",
  "PAPUA NEW GUINEA": "PG",
  KIRIBATI: "KI",
  BHUTAN: "BT",
  "SOUTH SUDAN": "SS",
  "NORTHERN MARIANA ISLANDS": "MP",
  "MICRONESIA, FEDERATED STATES OF": "FM",
  PALAU: "PW",
  "MARSHALL ISLANDS": "MH",
  "SAN MARINO": "SM",
  SOMALIA: "SO",
  "TAIWAN PROVINCE OF CHINA": "TW",
  JERSEY: "JE",
};