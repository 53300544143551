function CardBrandIcon({ brand }) {
  if (brand === "MASTERCARD") {
    return (
      <img
        src="/assets/icons/icon-mastercard.svg"
        style={{ height: "30px", marginRight: "5px" }}
      />
    );
  } else if (brand === "VISA") {
    return (
      <img
        src="/assets/icons/icon-visa.svg"
        style={{ width: "38px", marginRight: "5px" }}
      />
    );
  } else {
    return <span>{brand}</span>;
  }
}

export default CardBrandIcon;
