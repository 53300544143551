import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import useAuthStore from "../stores/userStore.js";
import useCartStore from "../stores/cartStore.js";
import useCardsFiltersStore from "../stores/buyCardsFiltersStore.js";
import {
  fetchCardsList,
  createUniqueOptions,
  filterData,
} from "../utils/filterUtils.js";
import { TbShoppingCartX } from "react-icons/tb";
import buyCardsColumns from "../components/CustomTable/buyCardsColumns";
import BuyCardsFilters from "../components/BuyCardsFilters.jsx";
import CustomTable from "../components/CustomTable/CustomTable";
import "../styles/pages/BuyCardsPage.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import BuyPopup from "../components/BuyPopup.jsx";
import useBuyPopupStore from "../stores/buyPopupStore.js";
import { cartCheck } from "../utils/cartUtils.js";
import useBasesFiltersStore from "../stores/basesFiltersStore.js";
import Loader from "../components/Loader.jsx";

function BuyCardsPage() {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filteredData, setFilteredData] = useState(undefined);
  const { isOpened, setIsOpened, setCardsForBuy } = useBuyPopupStore();
  const { filters, setFilters } = useCardsFiltersStore();
  const { setFilters: setBasesFilters } = useBasesFiltersStore();
  const { addToCart, cart, clearCart, removeFromCart } = useCartStore();

  const { key } = useAuthStore((state) => ({ key: state.key }));
  const { base } = useParams();

  useEffect(() => {
    if (base !== undefined) {
      setFilters({ bases: [base] });
    }
  }, []);

  const { data, isLoading } = useQuery({
    queryKey: [key],
    queryFn: fetchCardsList,
  });

  useEffect(() => {
    if (data && data.card_list) {
      const filtered = filterData(data.card_list, filters);
      setFilteredData(filtered);
    }
  }, [filters, data]);
  // --------------------filttttters
  const handleFilterChange = (key, value) => {
    setFilters({ [key]: value });
  };

  const handleMultiSelectChange = (key) => (selectedOptions) => {
    const values = selectedOptions.map((option) => option);
    // const values = selectedOptions.map((option) => option.value);
    handleFilterChange(key, values);
  };
  const handleOneSelectChange = (key) => (selectedOption) => {
    const value = selectedOption;
    handleFilterChange(key, value);
  };
  const handleInputChange = (key) => (event) => {
    handleFilterChange(key, event.target.value);
  };

  // --------------------filttttters

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const bankOptions = data
    ? createUniqueOptions(data.card_list, "binlist_bank")
    : [];
  const typeOptions = data
    ? createUniqueOptions(data.card_list, "binlist_type")
    : [];
  const vendorOptions = data
    ? createUniqueOptions(data.card_list, "binlist_brand")
    : [];
  const levelOptions = data
    ? createUniqueOptions(data.card_list, "binlist_level")
    : [];
  const baseOptions = data
    ? createUniqueOptions(data.card_list, "basename")
    : [];
  const sellerOptions = data
    ? createUniqueOptions(data.card_list, "seller")
    : [];
  const countryOptions = data
    ? createUniqueOptions(data.card_list, "binlist_country")
    : [];
  const cityOptions = data ? createUniqueOptions(data.card_list, "city") : [];
  const stateOptions = data ? createUniqueOptions(data.card_list, "state") : [];
  const cvvOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const refOptions = [
    { value: "", label: "All" },
    { value: "nonref", label: "No" },
    { value: "ref", label: "Yes" },
    // { value: "avs", label: "AVS" },
  ];
  if (isLoading || !data || filteredData === null) {
    return <Loader />;
  }

  const add = (itemId) => {
    console.log(cart, itemId);
    addToCart(itemId, key);
  };
  const addSelectedCards = () => {
    console.log(rowSelection.selectedRowKeys);
    addToCart(rowSelection.selectedRowKeys, key);
    setSelectedRowKeys([]);
  };
  const cartTotal = cart.reduce((total, element) => total + element.price, 0);

  const fastBuy = async (inputIds) => {
    const ids = Array.isArray(inputIds) ? inputIds : [inputIds];
    const newCartItems = await cartCheck(ids, key);
    console.log("newCartItems", newCartItems);
    setCardsForBuy(newCartItems.card_list);
    setIsOpened(true);
    // console.log("fastBuy", ids);
    // setIds(ids);
  };
  const deleteFromCart = (id) => {
    const itemToDel = cart.findIndex((item) => item.id === id);
    removeFromCart(id);
    cart.splice(itemToDel, 1);
    return cart;
  };

  const redirectToBase = (baseName) => {
    setBasesFilters({ bases: [baseName] });
    navigate("/bases");
  };

  return (
    <div className="Buy_cards_page">
      <h1>Сards</h1>
      <BuyCardsFilters
        cardsList={data?.card_list}
        filterData={filterData}
        handleOneSelectChange={handleOneSelectChange}
        bankOptions={bankOptions}
        typeOptions={typeOptions}
        vendorOptions={vendorOptions}
        levelOptions={levelOptions}
        baseOptions={baseOptions}
        sellerOptions={sellerOptions}
        countryOptions={countryOptions}
        cityOptions={cityOptions}
        cvvOptions={cvvOptions}
        stateOptions={stateOptions}
        refOptions={refOptions}
        filters={filters}
        setFilteredData={setFilteredData}
        handleInputChange={handleInputChange}
        handleMultiSelectChange={handleMultiSelectChange}
      />
      <div className="buycards_table">
        <div className="cardsTableHeader">
          <div className="buycards-header-buttons">
            <button
              onClick={() => addSelectedCards()}
              className="addToCart"
              disabled={selectedRowKeys.length == 0}
            >
              <img src="/assets/icons/icon-cart-white.svg" />
              Add to cart {selectedRowKeys.length}
            </button>
            <button
              className="fastBuy"
              disabled={selectedRowKeys.length == 0}
              onClick={() => fastBuy(selectedRowKeys)}
            >
              <img src="/assets/icons/icon-lightning.svg" />
              Fast buy {selectedRowKeys.length}
            </button>

            <button onClick={() => clearCart()} className={"clearBtn"}>
              <TbShoppingCartX size={"20px"} color={"white"} />
            </button>
          </div>
        </div>
        <CustomTable
          columns={buyCardsColumns(
            add,
            cart,
            fastBuy,
            deleteFromCart,
            redirectToBase
          )}
          data={filteredData}
          rowSelection={rowSelection}
        />
      </div>
      <BuyPopup />
    </div>
  );
}

export default BuyCardsPage;
