import styles from "../styles/pages/FaqPage.module.scss";
import ReadMoreArea from "@foxeian/react-read-more";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import useAuthStore from "../stores/userStore.js";
import { API_URL } from "../apiConf";
import Loader from "../components/Loader.jsx";

const fetchFAQs = async ({ queryKey }) => {
  const [key] = queryKey;
  const { data } = await axios.get(`${API_URL}/api/get_faq/?key=${key}`);
  return data;
};

function FaqPage() {
  const { key } = useAuthStore((state) => ({ key: state.key }));
  const { data, isLoading } = useQuery({
    queryKey: [key],
    queryFn: fetchFAQs,
  });

  const items = data?.faq_list?.map((faq, index) => ({
    key: index,
    label: faq.question,
    children: faq.answer,
  }));
  if (isLoading) {
    return <Loader />;
  }

  if (data) {
    return (
      <main className={styles.page}>
        <h1>FAQ</h1>
        <div className={styles.container}>
          {items?.map((item) => (
            <div className={styles.faq_item} key={item.id}>
              <h2>{item.label}</h2>
              <ReadMoreArea
                className={styles.text}
                lettersLimit={200} // limit of letters (100 letters)
              >
                {item.children}
              </ReadMoreArea>
            </div>
          ))}
        </div>
      </main>
    );
  }
}

export default FaqPage;
