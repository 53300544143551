import React from "react";
import useBasesFiltersStore from "../stores/basesFiltersStore.js";
import CustomBlueButton from "../components/CustomBlueButton";
import CustomCheckBox from "../components/CustomCheckBox";
import DatePicker from "../components/DatePicker";
import MultiSelect from "./MultiSelect.jsx";
import SortString from "./SortString.jsx";
import { useTranslation } from "react-i18next";
function BasesFilters({
  countryOptions,
  sellerOptions,
  basesOptions,
  handleMultiSelectChange,
  handleOneSelectChange,
  setFilteredData,
  handleSort,
  sortConfig,
  refOptions,
}) {
  const { t } = useTranslation();
  const { filters, setFilters } = useBasesFiltersStore();
  const [resetCounter, setResetCounter] = React.useState(0);
  const handleCheckboxChange = (key) => (event) => {
    setFilters({ ...filters, [key]: event.target.checked });
  };
  const resetFilters = () => {
    setFilters({
      updateFrom: "",
      updateTo: "",
      countries: [],
      sellers: [],
      bases: [],
      refund: [],
      cvv: false,
      address: false,
      phone: false,
      holder: false,
      snn: false,
      dob: false,
      dl: false,
      email: false,
      ip: false,
      ua: false,
      password: false,
    });
    setFilteredData(undefined);
    setResetCounter((prev) => prev + 1);
  };
  return (
    <div className="bases-filters_cont">
      <div className="bases_filters">
        {/* <DatePicker
          key={`multi-select-date-${resetCounter}`}
          onDateChange={(dates) =>
            setFilters({ updateFrom: dates[0], updateTo: dates[1] })
          }
        /> */}
        <MultiSelect
          key={`multi-select-Country-${resetCounter}`}
          placeholder={t("Country")}
          // onChange={() => {}}
          options={countryOptions}
          onChange={handleMultiSelectChange("countries")}
        />
        <MultiSelect
          key={`multi-select-base-${resetCounter}`}
          placeholder={t("Base")}
          options={basesOptions}
          onChange={handleMultiSelectChange("bases")}
          value={filters.bases.map((base) => ({
            value: base,
            label: base,
          }))}
        />
        <MultiSelect
          key={`multi-select-seller-${resetCounter}`}
          placeholder={t("Seller")}
          value={filters.sellers.map((seller) => ({
            value: seller,
            label: seller,
          }))}
          options={sellerOptions}
          onChange={handleMultiSelectChange("sellers")}
        />
        <MultiSelect
          key={`multi-select-refund-${resetCounter}`}
          placeholder={t("Refund")}
          options={refOptions}
          isClearable={false}
          isMulti={false}
          onChange={handleOneSelectChange("refund")}
        />
      </div>
      <div className="bases_filters phone_bases_filters">
        {/* <DatePicker
          key={`multi-select-date-${resetCounter}`}
          onDateChange={(dates) =>
            setFilters({ updateFrom: dates[0], updateTo: dates[1] })
          }
        /> */}
        <div className="phoneFiltersItem">
          <MultiSelect
            key={`multi-select-Country-${resetCounter}`}
            placeholder={t("Country")}
            // onChange={() => {}}
            options={countryOptions}
            onChange={handleMultiSelectChange("countries")}
          />
          <MultiSelect
            key={`multi-select-base-${resetCounter}`}
            placeholder={t("Base")}
            options={basesOptions}
            onChange={handleMultiSelectChange("bases")}
            value={filters.bases.map((base) => ({
              value: base,
              label: base,
            }))}
          />
        </div>

        <div className="phoneFiltersItem">
          <MultiSelect
            key={`multi-select-seller-${resetCounter}`}
            placeholder={t("Seller")}
            value={filters.sellers.map((seller) => ({
              value: seller,
              label: seller,
            }))}
            options={sellerOptions}
            onChange={handleMultiSelectChange("sellers")}
          />
          <MultiSelect
            key={`multi-select-refund-${resetCounter}`}
            placeholder={t("Refund")}
            options={refOptions}
            onChange={handleMultiSelectChange("refund")}
          />
        </div>
      </div>

      <div className="checkBox_cont">
        <CustomCheckBox
          label="Address"
          checked={filters.address}
          onChange={handleCheckboxChange("address")}
        />
        <CustomCheckBox
          label="CVV"
          checked={filters.cvv}
          onChange={handleCheckboxChange("cvv")}
        />
        <CustomCheckBox
          label="SNN"
          checked={filters.snn}
          onChange={handleCheckboxChange("snn")}
        />
        <CustomCheckBox
          label="Email"
          checked={filters.email}
          onChange={handleCheckboxChange("email")}
        />

        <CustomCheckBox
          label={"Password"}
          checked={filters.password}
          onChange={handleCheckboxChange("password")}
        />
        <CustomCheckBox
          label={"DOB"}
          checked={filters.dob}
          onChange={handleCheckboxChange("dob")}
        />
        <CustomCheckBox
          label={"UA"}
          checked={filters.ua}
          onChange={handleCheckboxChange("ua")}
        />
        <CustomCheckBox
          label={"DL"}
          checked={filters.dl}
          onChange={handleCheckboxChange("dl")}
        />
        <CustomCheckBox
          label={"IP"}
          checked={filters.ip}
          onChange={handleCheckboxChange("ip")}
        />
        <CustomCheckBox
          label={"Holder"}
          checked={filters.holder}
          onChange={handleCheckboxChange("holder")}
        />
        <CustomCheckBox
          label={"Phone"}
          checked={filters.phone}
          onChange={handleCheckboxChange("phone")}
        />
      </div>
      <div className="bases-page_btns_sorts">
        <div className="btns_cont">
          <CustomBlueButton
            label={t("Reset")}
            onClick={resetFilters}
            height={"37px"}
            borderRadius={"10px"}
            color="lightBlue"
            fontSize={"16px"}
          />
          {/* <CustomBlueButton
            fontSize={"16px"}
            label={"Search"}
            onClick={() => {}}
            height={"57px"}
            width={"178px"}
            color="blue"
          /> */}
        </div>

        <div className="sorts_cont">
          <SortString
            label={"rate"}
            text={t("Rate")}
            sortOrder={sortConfig.key === "rate" ? sortConfig.direction : null}
            onSort={handleSort}
          />
          <SortString
            label={"sold_progress"}
            text={t("Sold")}
            sortOrder={
              sortConfig.key === "sold_progress" ? sortConfig.direction : null
            }
            onSort={handleSort}
          />
          <SortString
            label={"valid"}
            text={t("Valid")}
            sortOrder={sortConfig.key === "valid" ? sortConfig.direction : null}
            onSort={handleSort}
          />
          <SortString
            label={"price"}
            text={t("Price")}
            sortOrder={sortConfig.key === "price" ? sortConfig.direction : null}
            onSort={handleSort}
          />
        </div>
      </div>
    </div>
  );
}

export default BasesFilters;
