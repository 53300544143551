import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuthStore from "../../stores/userStore.js";
import axios from "axios";
import { API_URL } from "../../apiConf";
import Loader from "../Loader.jsx";

const PrivateRoute = ({ children, roleForRoute = "all" }) => {
  const { key, currentRole } = useAuthStore((state) => ({
    key: state.key,
    currentRole: state.currentRole,
  }));
  const location = useLocation();
  const [status, setStatus] = useState("checking");

  useEffect(() => {
    let intervalId;
    if (!key) {
      setStatus("unauthenticated");
      return;
    }

    const verifyKey = async () => {
      try {
        const responce = await axios.post(`${API_URL}/api/check/`, { key });
        const { balance, alerts, seller_balance, messages, seller_msg } =
          responce.data;
        setStatus("authenticated");
        useAuthStore.getState().setUser({
          balance,
          alerts,
          seller_balance,
          messages,
          seller_msg,
        });
      } catch (error) {
        console.error("key error:", error);
        setStatus("unauthenticated");
        localStorage.removeItem("user");
      }
    };

    verifyKey();

    intervalId = setInterval(verifyKey, 60000);
    return () => clearInterval(intervalId);
  }, [key]);

  if (status === "checking") {
    return <Loader />;
  }

  if (status === "unauthenticated") {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (roleForRoute !== currentRole && roleForRoute !== "all") {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  return children;
};

export default PrivateRoute;
