import axios from "axios";
import {
    API_URL
} from "../apiConf";


export const fetchChats = async ({
    queryKey
}) => {
    const [key, currentRole] = queryKey;

    if (currentRole === 'seller') {
        const {
            data
        } = await axios.get(`${API_URL}/api/seller/seller_support/?key=${key}`);
        return data;
    } else {
        const {
            data
        } = await axios.get(`${API_URL}/api/support/?key=${key}`);
        return data;
    }

};

export const getChat = async ({
        queryKey
    }


) => {
    const [key, id, currentRole] = queryKey;
    if (currentRole === 'seller') {
        const {
            data
        } = await axios.get(`${API_URL}/api/seller/seller_chat/?key=${key}&id=${id}`);
        return data;
    } else {
        const {
            data
        } = await axios.get(`${API_URL}/api/chat/?key=${key}&id=${id}`);
        return data;
    }

};

export const sendMessage = async (message, key, id, role) => {
    const encodedMessage = encodeURIComponent(message);

    if (role === 'seller') {
        const {
            data
        } = await axios.post(
            `${API_URL}/api/seller/seller_chat/?key=${key}&id=${id}&new_message=${encodedMessage}`
        );
        return data;
    } else {
        const {
            data
        } = await axios.post(
            `${API_URL}/api/chat/?key=${key}&id=${id}&new_message=${encodedMessage}`
        );
        return data;
    }
};


export const closeTicket = async (key, id) => {
    const {
        data
    } = await axios.post(`${API_URL}/api/support_close/?key=${key}&close=${id}`);
    return data;

}


export const getTicketInfo = async (
    key, role
) => {

    if (role === 'seller') {
        const {
            data
        } = await axios.post(`${API_URL}/api/seller/seller_ticket/?key=${key}`);
        return data;
    } else {
        const {
            data
        } = await axios.post(`${API_URL}/api/ticket/?key=${key}`);
        return data;
    }

}






export const createTicket = async (info, key, role) => {
    const newTicketDescr = encodeURIComponent(info.description);

    if (role === 'seller') {
        const url = `${API_URL}/api/seller/seller_ticket/?key=${key}&category=${info.category}&subject=${info.subject}&description=${newTicketDescr}`;
        const {
            data
        } = await axios.post(url);
        return data;
    } else {
        const url = `${API_URL}/api/ticket/?key=${key}&category=${info.category}&subject=${info.subject}&description=${newTicketDescr}` + (info.orderId ? `&order_id=${info.orderId}` : '');
        const {
            data
        } = await axios.post(url);
        return data;
    }

}
export const fetchArchive = async (key, role, id) => {
    // if (role === 'seller') {
    //     const {
    //         data
    //     } = await axios.post(`${API_URL}/api/seller/seller_support/?key=${key}&archive=${id}`);
    //     return data;
    // } else {
    //     const url = `${API_URL}/api/support/?key=${key}&archive=${id}`
    //     const {
    //         data
    //     } = await axios.post(url);
    //     return data;
    // }
    const url = `${API_URL}/api/support/?key=${key}&archive=${id}`
    const {
        data
    } = await axios.post(url);
    return data;
}






export const createUniqueOptions = (array) => {
    const uniqueItems = new Set();
    const options = [];

    array?.forEach((item) => {
        const value = item;
        if (value && !uniqueItems.has(value)) {
            uniqueItems.add(value);
            options.push({
                value,
                label: value,
            });
        }
    });

    return options;
};
export const createUniqueOptionsOrders = (array) => {
    const uniqueItems = new Set();
    const options = [];

    array?.forEach((item) => {
        const value = item.order_id;
        if (value && !uniqueItems.has(value)) {
            uniqueItems.add(value);
            const label = `Order: ${item.order_id}, Card: ${item.card_number}, Base: ${item.base_name}`;
            options.push({
                value,
                label,
            });
        }
    });

    return options;
};