import React, { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "../apiConf";
import useAuthStore from "../stores/userStore.js";
import CustomBlueButton from "../components/CustomBlueButton";
import CustomTextarea from "../components/CustomTextarea";
import CustomTable from "../components/CustomTable/CustomTable";
import sellersPageColumns from "../components/CustomTable/sellersPageColumns";
import "../styles/pages/SellersPage.scss";
import Loader from "../components/Loader.jsx";
import { useTranslation } from "react-i18next";

const fetchSellersList = async ({ queryKey }) => {
  const [key] = queryKey;
  const { data } = await axios.get(`${API_URL}/api/seller_list/?key=${key}`);
  return data;
};

function SellersPage() {
  const { t } = useTranslation();
  const { key } = useAuthStore((state) => ({ key: state.key }));
  const { data, isLoading } = useQuery({
    queryKey: [key, "sellersPage"],
    queryFn: fetchSellersList,
  });
  const queryClient = useQueryClient();
  const [searchText, setSearchText] = useState("");

  if (isLoading || !data) {
    return <Loader />;
  }

  const filteredData = data.seller_list?.filter((item) =>
    item.username.toLowerCase().includes(searchText.toLowerCase())
  );

  const subUnSub = async (id) => {
    const { data } = await axios.get(`${API_URL}/api/sub/?key=${key}&id=${id}`);
    if (data.ok === true) {
      queryClient.invalidateQueries(["sellersPage"]);
    }
    return data;
  };

  return (
    <div className="sellers-page">
      <div className="SellersPage__cont">
        <h1>{t("Sellers")}</h1>
        {/* <div
          className="underH"
          style={{ justifyContent: "flex-start", gap: "20px" }}
        > */}
        {/* <CustomTextarea
          width="270px"
          height="85px"
          label="Seller"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Enter seller name"
        /> */}
        {/* </div> */}
        <div className="sellers_content_table">
          <CustomTable
            columns={sellersPageColumns(subUnSub)}
            data={filteredData}
          />
        </div>
      </div>
    </div>
  );
}

export default SellersPage;
