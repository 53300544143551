import React, { useRef, useState } from "react";

import { createPreorder } from "../utils/preorderUtils";
import CustomBlueButton from "./CustomBlueButton";
import CustomTextarea from "./CustomTextarea";
import MultiSelect from "./MultiSelect";
import StringWithIcon from "./StringWithIcon";
import useAuthStore from "../stores/userStore.js";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useCardsFiltersStore from "../stores/buyCardsFiltersStore";

function PreorderSelection({ ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { filters, setFilters } = useCardsFiltersStore();
  const queryClient = useQueryClient();
  const { key } = useAuthStore((state) => ({ key: state.key }));
  const sellerNames = Array.from(
    new Set(
      props.sellers?.map((seller) => ({
        username: seller.username,
        id: seller.id,
      }))
    )
  );
  const sellerOptions = sellerNames.map((seller) => ({
    value: seller.id,
    label: seller.username,
    id: seller.id,
  }));

  const sellersForSelect = [
    {
      value: 0,
      label: "All",
      id: 0,
    },
    ...sellerOptions,
  ];

  const [newPreorder, setNewPreorder] = React.useState({
    seller: sellersForSelect[0].id,
    bin: "",
    price: "",
    maxAmount: "1",
  });
  const multiSelectRef = useRef(null);

  const redirectToCards = (sellerName, bin) => {
    sellerName == null
      ? setFilters({ bins: bin })
      : setFilters({ sellers: [sellerName], bins: bin });
    navigate("/cards");
  };

  const addPreorder = async (newPreorder) => {
    setIsLoading(true);
    try {
      const data = await createPreorder(newPreorder, key);
      if (data.ok === true) {
        setIsLoading(false);
        queryClient.invalidateQueries("preorderPage");
        setNewPreorder({
          seller: sellersForSelect[0],
          bin: "",
          price: "",
          maxAmount: "1",
        });
        props.verifyKey();
      } else if (data.ok === false && data.redirect === true) {
        setIsLoading(false);
        redirectToCards(data.seller_name, data.bin);
        setNewPreorder({
          seller: sellersForSelect[0],
          bin: "",
          price: "",
          maxAmount: "1",
        });
      } else {
        setIsLoading(false);
        props.openNotification("Preorder error", data.error);
      }
      console.log(data);
    } catch (error) {
      console.error("add preorder error:", error);
    }
  };
  const handleOneSelectChange = () => (selectedOption) => {
    console.log(selectedOption);

    setNewPreorder({ ...newPreorder, seller: selectedOption });
  };

  return (
    <div className="preorder__selection">
      <div className="select__cont">
        <div className="select__cont-item">
          <MultiSelect
            defaultValue={newPreorder.seller}
            value={newPreorder.seller}
            ref={multiSelectRef}
            key={"multiSeletc-preorder-seller"}
            placeholder={t("Seller")}
            options={sellersForSelect}
            isClearable={false}
            isMulti={false}
            onChange={handleOneSelectChange()}
          />
          <CustomTextarea
            height={"40px"}
            width={"100%"}
            // width={"270px"}
            label={"Bin"}
            placeholder={"Bin"}
            value={newPreorder.bin}
            onChange={(e) =>
              setNewPreorder({ ...newPreorder, bin: e.target.value })
            }
          />
        </div>
        <div className="select__cont-item">
          <CustomTextarea
            height={"40px"}
            width={"100%"}
            // width={"270px"}
            label={t("Price")}
            placeholder={t("Price")}
            value={newPreorder.price}
            onChange={(e) =>
              setNewPreorder({ ...newPreorder, price: e.target.value })
            }
          />
          <CustomTextarea
            height={"40px"}
            width={"100%"}
            // width={"270px"}
            label={t("Amount")}
            placeholder={"1"}
            value={newPreorder.maxAmount}
            onChange={(e) =>
              setNewPreorder({ ...newPreorder, maxAmount: e.target.value })
            }
          />
        </div>
      </div>
      <div className="select__cont phoneSelectCont">
        <div className="phoneSelectCont_item">
          <MultiSelect
            ref={multiSelectRef}
            key={"multiSeletc-preorder-seller"}
            placeholder={t("Seller")}
            options={sellerOptions}
            isMulti={false}
            onChange={handleOneSelectChange()}
          />
          <CustomTextarea
            height={"40px"}
            width={"100%"}
            // width={"270px"}
            label={"Bin"}
            placeholder={"Bin"}
            value={newPreorder.bin}
            onChange={(e) =>
              setNewPreorder({ ...newPreorder, bin: e.target.value })
            }
          />
        </div>
        <div className="phoneSelectCont_item">
          <CustomTextarea
            height={"40px"}
            width={"100%"}
            // width={"270px"}
            label={t("Price")}
            placeholder={t("Price")}
            value={newPreorder.price}
            onChange={(e) =>
              setNewPreorder({ ...newPreorder, price: e.target.value })
            }
          />
          <CustomTextarea
            height={"40px"}
            width={"100%"}
            // width={"270px"}
            label={t("Amount")}
            placeholder={"1"}
            value={newPreorder.maxAmount}
            onChange={(e) =>
              setNewPreorder({ ...newPreorder, maxAmount: e.target.value })
            }
          />
        </div>
      </div>
      <div className="preorder_bottom">
        <div className="btns_cont" style={{ display: "flex" }}>
          <CustomBlueButton
            label={t("Add preorder")}
            onClick={() => addPreorder(newPreorder)}
            height={"40px"}
            width={"157px"}
            borderRadius={"10px"}
            isLoading={isLoading}
            color="blue"
            fontSize={"16px"}
          />
        </div>
      </div>
      <div className="preorder_bottom phonePreorder_bottom">
        <div className="btns_cont" style={{ display: "flex" }}>
          <CustomBlueButton
            label={t("Add preorder")}
            isLoading={isLoading}
            onClick={() => addPreorder(newPreorder)}
            height={"40px"}
            // width={"170px"}
            color="lightBlue"
            fontSize={"16px"}
          />
        </div>
      </div>
    </div>
  );
}

export default PreorderSelection;
