import { Progress, Flex } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import { filterItems } from "../BasesViewSeller";

const sellerBasesColumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (value, record) => (
      <span style={{ color: "#2a6dff" }}>{value}</span>
    ),
  },
  {
    title: "BASE NAME",
    dataIndex: "basename",
    key: "basename",
    render: (value, record) => (
      <Link
        to={`/base-info/${record.id}`}
        style={{ textDecoration: "underline", color: "rgba(37, 144, 238, 1)" }}
      >
        {value}
      </Link>
    ),
  },
  {
    title: "Upload Date",
    dataIndex: "upload_date",
    key: "upload_date",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text) => {
      const item = filterItems.find((item) => item.value == text);

      return <p>{item?.label}</p>;
    },
  },
  {
    title: "Ref",
    dataIndex: "ref",
    key: "ref",
  },
  {
    title: "Total",
    dataIndex: "count_card",
    key: "count_card",
  },
  {
    title: "Sold",
    dataIndex: "sold",
    key: "sold",
  },
  {
    title: "Refund",
    dataIndex: "refund",
    key: "refund",
  },
  {
    title: "Valid",
    dataIndex: "valid",
    key: "valid",
    width: "150px",

    render: (value) => (
      <Progress
        percent={value}
        percentPosition={{ align: "center", type: "inner" }}
        size={[140, 20]}
      />
    ),
  },
  {
    title: "Sold progress",
    dataIndex: "sold_progress",
    key: "sold_progress",
    width: "150px",

    render: (value) => (
      <Progress
        style={{ color: "#fff" }}
        percent={value}
        percentPosition={{ align: "center", type: "inner" }}
        size={[140, 20]}
        strokeColor="rgba(59, 209, 74, 1)"
      />
    ),
  },
  {
    title: "Profit ($)",
    dataIndex: "profit",
    key: "profit",
  },

  {
    title: "%",
    dataIndex: "percent",
    key: "percent",
  },
];

export default sellerBasesColumns;
