import React, { useState } from "react";
import CustomTextarea from "./CustomTextarea";
import CustomBlueButton from "./CustomBlueButton";
import { checkBins } from "../utils/toolsUtils";
import useAuthStore from "../stores/userStore.js";
import { useTranslation } from "react-i18next";
import { notification } from "antd";

const CheckBin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (text, errorText) => {
    api["error"]({
      message: text,
      description: errorText,
      placement: "top",
      duration: 5000,
    });
  };
  const [bins, setBins] = React.useState("");
  const [checkRes, setCheckRes] = React.useState([]);
  const { key } = useAuthStore((state) => ({ key: state.key }));
  const handleInputChange = (e) => {
    const value = e.target.value.replace(/\n/g, "");
    let formattedValue = "";

    for (let i = 0; i < value.length; i += 6) {
      if (i + 6 < value.length) {
        formattedValue += value.slice(i, i + 6) + "\n";
      } else {
        formattedValue += value.slice(i);
      }
    }

    setBins(formattedValue);
  };

  const handleCheckBins = async () => {
    setIsLoading(true);
    const binArray = bins.split("\n").filter((bin) => bin.trim() !== "");
    if (binArray.length > 100) {
      alert("You can only enter up to 100 bins.");
      return;
    }
    const binString = binArray.join(",");

    const res = await checkBins(binString, key);

    if (res.ok === true) {
      setIsLoading(false);
      setCheckRes(res.res);
    } else if (res.ok === false) {
      openNotification("Bins error", res.error);
      setIsLoading(false);
    }
  };

  return (
    <div className="bins_page">
      {contextHolder}
      <h1>BINs</h1>
      <div className="bins_cont">
        <CustomTextarea
          label={"BIN:"}
          placeholder={"Enter Bins"}
          width={"100%"}
          value={bins}
          // height={"37px"}
          onChange={handleInputChange}
        />
        <div className="bins_botPart">
          <span>-0.01$</span>
          <CustomBlueButton
            isLoading={isLoading}
            disabled={bins.length === 0}
            label={t("Submit")}
            // color={"blue"}
            width={"90px"}
            height={"37px"}
            borderRadius={"10px"}
            onClick={handleCheckBins}
          />
        </div>

        {checkRes.length > 0 ? (
          checkRes.map((item, index) => <p key={index}>{item.short}</p>)
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CheckBin;
