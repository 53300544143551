import React from "react";
import useAuthStore from "../stores/userStore.js";
import { getChat, sendMessage, closeTicket } from "../services/supportService";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import SupportChat from "../components/SupportChat.jsx";
import "../styles/pages/SupportPage.scss";
import "../styles/pages/ChatPage.scss";
import Loader from "../components/Loader.jsx";

function ChatPage() {
  let { id } = useParams();

  const { key, currentRole } = useAuthStore((state) => ({
    key: state.key,
    currentRole: state.currentRole,
  }));
  const { data, isLoading } = useQuery({
    queryKey: [key, id, currentRole, "getChat"],
    queryFn: getChat,
  });
  if (isLoading || !data) {
    return <Loader />;
  }
  return (
    <div className="ChatPage">
      <h1>Support Chat</h1>
      <SupportChat chat={data} id={id} />
    </div>
  );
}

export default ChatPage;
