import { create } from "zustand";
import { persist } from "zustand/middleware";

const useUserStore = create(
  persist(
    (set, get) => ({
      username: null,
      role: null,
      key: null,
      balance: null,
      seller_balance: null,
      alerts: null,
      currentRole: "buyer",
      messages: false,
      seller_msg: false,
      setUser: (newValues) => {
        const currentState = get();
        set({
          ...currentState,
          ...newValues,
        });
      },
      changeBalance: (summary) => {
        const currentState = get();
        set({
          ...currentState,
          balance: (currentState.balance - summary).toFixed(2),
        });
      },
      changeCurrentRole: (newCurrentRole) => {
        const currentState = get();
        set({
          ...currentState,
          currentRole: newCurrentRole,
        });
      },
      logout: () => {
        set({
          username: null,
          role: null,
          key: null,
          balance: null,
          seller_balance: null,
          alerts: null,
          currentRole: "buyer",
          messages: false,
          seller_msg: false,
        });
      },
    }),
    {
      name: "user",
      getStorage: () => localStorage,
    }
  )
);

export default useUserStore;
