import React from "react";

export default function Bases() {
  return (
    <svg
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.28067 0.00866576C5.20805 0.014593 4.97037 0.0339301 4.7525 0.0515945C2.41119 0.241532 0.534064 0.971056 0.0971469 1.86085L0.0136075 2.03098V3.17536V4.31973L0.0994943 4.49579C0.477226 5.27012 1.97466 5.93002 3.98958 6.2101C4.80599 6.32359 5.13228 6.34334 6.1903 6.34334C7.24785 6.34334 7.57332 6.32365 8.39102 6.21021C10.4155 5.92937 11.8984 5.27397 12.2835 4.48986L12.367 4.31973V3.16069V2.00164L12.2822 1.84025C12.1826 1.65082 11.8509 1.31205 11.6116 1.15545C10.7927 0.619381 9.47616 0.242647 7.78949 0.0616885C7.40531 0.020491 5.6025 -0.0176255 5.28067 0.00866576ZM0.000315157 6.93325C-0.00124002 8.72408 -0.00534809 8.68526 0.21804 8.99228C0.801818 9.79454 2.6425 10.4314 4.91388 10.6169C5.48029 10.6632 6.90031 10.6632 7.46672 10.6169C10.0664 10.4046 12.0591 9.60602 12.3387 8.66455C12.4 8.45806 12.3999 5.28606 12.3385 5.45267C12.2767 5.62074 12.1497 5.80387 11.9799 5.9696C11.381 6.55461 10.0871 7.0319 8.46438 7.2665C7.66009 7.38276 7.37285 7.39957 6.1903 7.39957C5.00775 7.39957 4.72051 7.38276 3.91622 7.2665C2.37689 7.04396 1.15775 6.613 0.490489 6.05558C0.308387 5.90343 0.125844 5.66449 0.056859 5.48799C0.00175291 5.34694 0.00166493 5.34906 0.000315157 6.93325ZM0.00709335 11.3474L0.0136075 12.9759L0.0971469 13.146C0.534709 14.0372 2.25588 14.7088 4.7525 14.9627C5.24112 15.0124 7.13949 15.0124 7.62811 14.9627C9.41319 14.7812 10.7692 14.4029 11.6116 13.8514C11.8509 13.6948 12.1826 13.3561 12.2822 13.1666L12.367 13.0053L12.3748 11.362L12.3825 9.71884L12.2786 9.92409C12.0272 10.4208 11.3788 10.85 10.3721 11.1864C9.55876 11.4581 8.75229 11.6131 7.52541 11.7335C6.95158 11.7899 5.32741 11.7813 4.70848 11.7188C3.64448 11.6112 2.78687 11.4435 2.04449 11.1979C1.00176 10.8529 0.31807 10.4007 0.0862313 9.90276L0.000579211 9.71884L0.00709335 11.3474Z"
        fill="white"
      />
    </svg>
  );
}
