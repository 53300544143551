import styles from "../styles/pages/PayoutsPageSeller.module.scss";
import React from "react";
import useAuthStore from "../stores/userStore.js";
import { useQuery } from "@tanstack/react-query";
import { getPayoutsSeller } from "../utils/payoutsSellerUtils";
import CredItem from "../components/CredItem";
import CustomTable from "../components/CustomTable/CustomTable";
import payoutsSellerColumns from "../components/CustomTable/payoutsSellerColumns";
import CustomBlueButton from "../components/CustomBlueButton";
import PayoutSellerPopup from "../components/PayoutSellerPopup";
import Container from "../components/Container/Container.jsx";
import MultiSelect from "../components/MultiSelect.jsx";
import Input from "antd/es/input/Input.js";
import { useQueryClient } from "@tanstack/react-query";
import { sendNewPayoutSeller } from "../utils/payoutsSellerUtils";
import { useTranslation } from "react-i18next";
import { notification } from "antd";
import { createUniqueOptions } from "../services/supportService";

function PayoutsPageSeller() {
  const [isLoadingBtn, setIsLoadingBtn] = React.useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();

  const openNotificationError = (textError) => {
    api["error"]({
      message: "Error",
      description: textError,
      duration: 5,
    });
  };

  const [newPayout, setNewPayout] = React.useState({
    coin: "",
    wallet: "",
    sum: 0,
  });

  const isValidForm =
    newPayout.coin !== "" && newPayout.wallet !== "" && newPayout.sum !== 0;

  const selectOptions = [
    { value: "BTC", label: "BTC" },
    { value: "LTC", label: "LTC" },
    { value: "USDT(TRC-20)", label: "USDT(TRC-20)" },
  ];
  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setNewPayout({ ...newPayout, coin: selectedOption });
    } else {
      setNewPayout({ ...newPayout, coin: "" });
    }
  };

  const createPayout = async () => {
    setIsLoadingBtn(true);
    const res = await sendNewPayoutSeller(newPayout, key);
    if (res.ok === true) {
      queryClient.invalidateQueries("payoutsSeller");
      console.log("ok");
    } else openNotificationError(res.error);

    setIsLoadingBtn(false);
  };

  const { key } = useAuthStore((state) => ({ key: state.key }));
  const { data, isLoading } = useQuery({
    queryKey: [key, "payoutsSeller"],
    queryFn: getPayoutsSeller,
  });

  const selectOptions2 = data ? createUniqueOptions(data.curs) : [];

  if (data) {
    return (
      <main className={styles.page}>
        <h1>{t("Withdrawal")}</h1>
        {contextHolder}
        <Container style={{ marginBottom: "30px" }}>
          <div className={styles.inputs}>
            <div className={styles.block}>
              <MultiSelect
                onChange={handleSelectChange}
                isMulti={false}
                placeholder={t("Choose Currency")}
                options={selectOptions2}
                className={styles.block}
              />
            </div>
            <div className={styles.block}>
              <h2>{t("Crypto address")}:</h2>
              <Input
                required
                height={"39px"}
                onChange={(e) =>
                  setNewPayout({ ...newPayout, wallet: e.target.value })
                }
                className="inpPayoutsSeller"
              />
            </div>
            <div className={styles.block}>
              <h2>{t("Amount")} ($):</h2>
              <Input
                required
                height={38}
                className="inpPayoutsSeller"
                type={"number"}
                onChange={(e) =>
                  setNewPayout({ ...newPayout, sum: e.target.value })
                }
              />
            </div>
            <div className={styles.send_btn}>
              <CustomBlueButton
                isLoading={isLoadingBtn}
                disabled={!isValidForm}
                color={"blue"}
                width={"200px"}
                height={"40px"}
                borderRadius={"10px"}
                onClick={() => createPayout()}
                fontSize={"15px"}
                label={t("Create request")}
              />
            </div>
          </div>
        </Container>
        <CustomTable
          data={data?.history}
          columns={payoutsSellerColumns}
          pageSize={10}
        />
      </main>
    );
  }

  // <div className="payoutsPage_cont">
  //   <h1>Payouts</h1>
  //   <div className="payouts_creds">
  //     <CredItem
  //       icon={"/assets/icons/icon-profile.svg"}
  //       title={"Shop Name"}
  //       value={data?.shopname}
  //     />
  //     <CredItem
  //       icon={"/assets/icons/icon-piechart.svg"}
  //       title={"Seller Rate"}
  //       value={`${data?.rate} %`}
  //     />
  //     <CredItem
  //       icon={"/assets/icons/icon-card.svg"}
  //       title={"Payouts"}
  //       value={`${data?.total_withdrawal} $`}
  //     />
  //     <CredItem
  //       icon={"/assets/icons/icon-dollar-circle.svg"}
  //       title={"Balance"}
  //       textColor={"#199F2E"}
  //       value={`${data?.balance} $`}
  //     />
  //   </div>
  //   <CustomBlueButton
  //     icon={"/assets/icons/icon-createPayout.svg"}
  //     color={"blue"}
  //     height="65px"
  //     width={"270px"}
  //     onClick={() => setIsOpened(true)}
  //     label={"Create payout"}
  //     fontSize={"17px"}
  //   />

  //   <CustomTable
  //     data={data?.history}
  //     columns={payoutsSellerColumns}
  //     pageSize={10}
  //   />
  //   <PayoutSellerPopup isOpened={isOpened} setIsOpened={setIsOpened} />
  // </div>
}

export default PayoutsPageSeller;
