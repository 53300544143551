import React from "react";
import Select from "react-select";

function MultiSelectEb({
  placeholder,
  options,
  isMulti = true,
  onChange,
  value,
  defaultValue,
  isDisabled = false,
  isClearable = true,
}) {
  // Функция для обработки изменения значения селекта
  const handleChange = (selectedOption) => {
    if (selectedOption === null) {
      // Обрабатываем случай, когда выбор очищен
      onChange(isMulti ? [] : "");
    } else if (isMulti) {
      // Обработка выбора значений для мультивыбора
      const processedValue = selectedOption.map((option) => option.value);
      onChange(processedValue);
    } else {
      // Обработка выбора значения для одиночного выбора
      onChange(selectedOption ? selectedOption.value : "");
    }
  };

  return (
    <Select
      isDisabled={isDisabled}
      defaultValue={defaultValue}
      isClearable={isClearable}
      value={value}
      placeholder={placeholder}
      isMulti={isMulti}
      name={placeholder} // Это поле устанавливает имя для элемента формы
      options={options}
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={handleChange}
    />
  );
}

export default MultiSelectEb;
