import axios from "axios";
import {
    API_URL
} from "../apiConf";


export const getSellersList = async ({
    queryKey
}) => {
    const [key] = queryKey;
    const {
        data
    } = await axios.get(`${API_URL}/api/seller_list/?key=${key}`);
    return data;
};


export const createPreorder = async (
    newPreorder,
    key
) => {
    const seller = newPreorder.seller === '' ? 0 : newPreorder.seller
    const {
        data
    } = await axios.post(`${API_URL}/api/preorder/?key=${key}&sellers=${seller}
    &bin=${newPreorder.bin}&price=${newPreorder.price}&count=${newPreorder.maxAmount}`);
    return data;
};

export const deletePreorder = async (
    key,
    preorder
) => {
    const {
        data
    } = await axios.post(`${API_URL}/api/preorder/?key=${key}&delete=${preorder}`);
    return data;
};
export const editPricePreorder = async (
    key,
    preorder,
    price
) => {
    const {
        data
    } = await axios.post(`${API_URL}/api/preorder/?key=${key}&edit=${preorder}&price=${price}`);
    return data;
};