import React from "react";

export default function Seller() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <rect width="15" height="15" fill="url(#pattern0_8_752)" />
      <defs>
        <pattern
          id="pattern0_8_752"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use href="#image0_8_752" transform="scale(0.0111111)" />
        </pattern>
        <image
          id="image0_8_752"
          width="90"
          height="90"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAABz0lEQVR4nO3cQU4CMQCFYVYqNxAw8UgeRNGd4zn0TqIr4RruDGxYPdPYBZox0FLeUOb/Ejcah/rTTJt0cDAAAAAAAKSRdCHpQdK7pJX+F372Jule0nniy/SbpCtJC6Wbh9/tevw1zeRFRuTN2MzsbeLtYl/TrS/Ud/q5J+9r1vXfcfQkLQuEXg5OePXvm1Wx3c4eq3/fzLN3OwVW/76ZZ83sQqt/30y7Wv37ZtbV6t83y5zQyEBoE0KbENqE0CaENiG0CaFNCG1CaBNCmxDahNAmhDYhtAmhTQhtQugTCr2W9ChpHL+a+L1aFBm/I3TTco0w2FoUGb8j9KjlGpeqx6jE+A8euuAb1olDdyB0RGiTakKHlbrlGhPVY1xi/I7Qbav2k+rRlBi/I/Q6bodq3kc3XeyjefYu3VdOaJ4mNT1NGj4ygDR3OaHP41Ps2M2HpLPk0BufYSH2bpEnWZH/zOxpuP+wQP4SNguv4XaRPZMBAMdN0i3/jiF505C1jw4+JT1LupF0LWmYfKETJWkYm4Q2L7EVp+AuOe8YMhDahNAmhDYhtAmhTQhtQugjDs3hbDoOZ004nDXhcNaAw1kDDmcPiMNZAAAAAAAAABjs7BuZpsqApcZtfAAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  );
}
