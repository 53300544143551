import React, { useState } from "react";
import CustomPopup from "../components/CustomPopup";
import { useForm } from "react-hook-form";
import "../styles/components/ChangePasswordPopup.scss";
import CustomBlueButton from "./CustomBlueButton";
import { changePassword } from "../utils/profileUtils";
import useAuthStore from "../stores/userStore.js";
import { useTranslation } from "react-i18next";

function ChangePasswordComponent({
  openNotification,
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false)
  const { key } = useAuthStore((state) => ({ key: state.key }));
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (formData) => {
    setIsLoading(true)
    const {repeat, ...rest} = formData
    const response = await changePassword(rest, key);
    if (response.data.ok === true) {
      reset();

      openNotification(
        "success",
        "Info",
        "Password changed successfully",
        5000
      );
      setIsLoading(false)

    } else {
      //   setIsError(true);
      //   setafterMessage(response.data.error);
      openNotification("error", "Error", response.data.error, 5000);
      setIsLoading(false)
      reset();

    }
  };

  const newPassword = watch("new", "");

  return (
    <div>
      {/* {showMessage === false ? ( */}
      <form className="info-box" onSubmit={handleSubmit(onSubmit)}>
        <div className="box-item">
          <span className="box-item_bold">{t("Personal info")}</span>
          <span className="box-item_bold">
            <CustomBlueButton
              isLoading={isLoading}
              label={t("Edit")}
              color={"lightBlue"}
              height={"26px"}
              type="submit"
            />
          </span>
        </div>
        <div
          className={
            errors.old ? "password_cont errorInp_cont" : "password_cont"
          }
        >
          <div className="password_cont-leftSide">
            <span>{t("Current password")}</span>
          </div>
          <input
            type="password"
            minLength={"8"}
            {...register("old", {
              required: "This field is required",
            })}
          />
        </div>
        <div
          className={
            errors.new ? "password_cont errorInp_cont" : "password_cont"
          }
        >
          <div className="password_cont-leftSide">
            {/* <img src="/assets/icons/icon-eye-crossed.svg" alt="" /> */}
            <span>{t("New password")}</span>
          </div>
          <input
            type="password"
            minLength={"8"}
            {...register("new", {
              required: "This field is required",
            })}
          />
        </div>

        <div
          className={
            errors.repeat ? "password_cont errorInp_cont" : "password_cont"
          }
        >
          <div className="password_cont-leftSide">
            {/* <img src="/assets/icons/icon-eye-crossed.svg" alt="" /> */}
            <span>
              {t("Repeat new password")}
            </span>
          </div>
          <input
            type="password"
            minLength={"8"}
            {...register("repeat", {
              validate: (value) =>
                value === newPassword || t("The passwords do not match"),
            })}
          />
        </div>

        {errors.repeat && <p style={{color:'red'}}>{errors.repeat.message}</p>}
      </form>
    </div>
  );
}

export default ChangePasswordComponent;
