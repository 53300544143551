import React from "react";
const payoutsSellerColumns = [
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
  },

  {
    title: "TXID",
    dataIndex: "tx",
    key: "tx",

    render: (text) => (
      <span className="adress_pay" title={text}>
        {text}
      </span>
    ),
  },
  {
    title: "Currency",
    dataIndex: "coin",
    key: "coin",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },

  {
    title: "Created",
    dataIndex: "date",
    key: "date",
  },
];

export default payoutsSellerColumns;
