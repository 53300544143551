import {
    create
} from 'zustand';

const useOrdersPageStore = create((set) => ({
    activeHistory: true,
    setActiveHistory: (newValue) => set({
        activeHistory: newValue
    }),
}));

export default useOrdersPageStore;