import "../styles/pages/ToolsPage.scss";
import CustomTable from "../components/CustomTable/CustomTable.jsx";
import React from "react";
import toolsColumns from "../components/CustomTable/toolsColumns";
import { getCheckTable } from "../utils/toolsUtils";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useAuthStore from "../stores/userStore.js";
import CheckCardForm from "../components/CheckCardForm";
import CustomTextarea from "../components/CustomTextarea.jsx";
import CheckBin from "../components/CheckBin.jsx";
import CheckZip from "../components/CheckZip.jsx";

function ToolsPage() {
  const queryClient = useQueryClient();
  const [nav, setNav] = React.useState("card");
  const { key } = useAuthStore((state) => ({ key: state.key }));
  const { data: checkTableData, isLoading } = useQuery({
    queryKey: ["tableCheck", key],
    queryFn: getCheckTable,
  });

  const reversedData = Array.isArray(checkTableData?.data)
    ? [...checkTableData.data].reverse()
    : [];

  const updateTable = () => {
    queryClient.invalidateQueries({ queryKey: ["tableCheck", key] });
  };
  const RenderTools = () => {
    switch (nav) {
      case "card":
        return (
          <>
            <CheckCardForm updateTable={updateTable} />
            <div className="checkTable_header">
              {/* <p>Check Table</p> */}
              <button
                type="button"
                className="refetchBtn"
                onClick={() => updateTable()}
              >
                <img src="/assets/icons/icon-refresh.svg" alt="" />
                Update table
              </button>
            </div>
            <CustomTable
              columns={toolsColumns}
              data={reversedData}
              // pageSize={5}
              // pagination={false}
            />
          </>
        );
      // case "table":
      //   return (

      //   );
      case "bin":
        return <CheckBin />;
      case "zip":
        return <CheckZip />;
    }
  };
  return (
    <div className="toolsPage_cont">
      <h1>Tools</h1>
      <nav>
        <span
          className={nav === "card" ? "activeSpan" : ""}
          onClick={() => setNav("card")}
        >
          Check Card
        </span>
        {/* <span
          className={nav === "table" ? "activeSpan" : ""}
          onClick={() => setNav("table")}
        >
          Check Table
        </span> */}
        <span
          className={nav === "bin" ? "activeSpan" : ""}
          onClick={() => setNav("bin")}
        >
          Check Bin
        </span>
        <span
          className={nav === "zip" ? "activeSpan" : ""}
          onClick={() => setNav("zip")}
        >
          Check Zip
        </span>
      </nav>
      <RenderTools />
    </div>
  );
}

export default ToolsPage;
