import React from "react";
import { Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import CountryFlagIcon from "../components/CountryFlagIcon.jsx";
const items = [
  {
    key: "en",
    label: (
      <CountryFlagIcon showToolTipCode={false} countryName={"united states"} />
    ),
  },
  {
    key: "ru",
    label: (
      <CountryFlagIcon
        showToolTipCode={false}
        countryName={"russian federation"}
      />
    ),
  },
  {
    key: "zh",
    label: <CountryFlagIcon showToolTipCode={false} countryName={"china"} />,
  },
];

const ReturnFlag = (currentLang) => {
  switch (currentLang) {
    case "en":
      return (
        <CountryFlagIcon
          showToolTipCode={false}
          countryName={"united states"}
        />
      );
    case "ru":
      return (
        <CountryFlagIcon
          showToolTipCode={false}
          countryName={"russian federation"}
        />
      );
    case "zh":
      return <CountryFlagIcon showToolTipCode={false} countryName={"china"} />;
  }
};

function LanguageChanger() {
  const { t } = useTranslation();

  const handleMenuClick = ({ key }) => {
    i18n.changeLanguage(key);
  };

  const currentLang = i18n.language || "en";

  return (
    <Dropdown
      menu={{
        items: items.map((item) => ({
          ...item,
          onClick: handleMenuClick,
        })),
      }}
      placement="bottom"
      arrow={{
        pointAtCenter: true,
      }}
    >
      <Space>{ReturnFlag(currentLang)}</Space>
    </Dropdown>
  );
}

export default LanguageChanger;
