import React from "react";
const giftsProfileColumns = [
  {
    title: "CODE",
    dataIndex: "code",
    key: "code",
  },

  {
    title: "AMOUNT",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "STATUS",
    dataIndex: "isUsed",
    key: "isUsed",
    render: (_) => (_ === true ? <p>Used</p> : <p>Not used</p>),
  },
];

export default giftsProfileColumns;
