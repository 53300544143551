import React from "react";
import CardBrandIcon from "../CardBrandIcon";
import { LuAlertCircle, LuCheckCircle2 } from "react-icons/lu";
import LittleStarRating from "../LittleStarRating";
import CountryFlagIcon from "../CountryFlagIcon";
import { Link } from "react-router-dom";
import { TbShoppingCartX } from "react-icons/tb";
import { Tooltip } from "antd";
import { BsInfoCircle } from "react-icons/bs";
import { FaMinus, FaCheck } from "react-icons/fa";
const generateTooltipItems = (record) => {
  const items = [
    { label: "DOB", key: "dob" },
    { label: "SSN", key: "ssn" },
    { label: "EMAIL", key: "email" },
    { label: "PHONE", key: "phone" },
  ];

  return (
    <ul>
      {items.map((item) => (
        <li key={item.key}>
          {item.label}:{" "}
          {record.list.includes(item.key) ? (
            <span style={{ color: "rgb(25, 159, 46)" }}>YES</span>
          ) : (
            <span style={{ color: "#d11c1c" }}>NO</span>
          )}
        </li>
      ))}
    </ul>
  );
};

const buyCardsColumns = (
  add,
  cart,
  fastBuy,
  deleteFromCart,
  redirectToBase
) => [
  {
    title: "DB",
    dataIndex: "base",
    key: "base",
    render: (_, record) => (
      <div className="sellerCellOrders">
        <span
          style={{ color: "#2590EE", cursor: "pointer" }}
          className="baseName"
          onClick={() => redirectToBase(record.basename)}
        >
          {record.basename}
        </span>
      </div>
    ),
  },
  {
    title: "DB TYPE",
    dataIndex: "basename_type",
    key: "basename_type",
    render: (_, record) => (
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        {record.basename_type === "nonref" ? (
          <div
            title="No refund"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span>Nonref</span> <FaMinus color={"red"} />
          </div>
        ) : (
          <div
            title="refundable"
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
          >
            <span>Ref</span> <FaCheck color={"green"} />
          </div>
        )}
      </div>
    ),
  },
  {
    title: "SELLER",
    dataIndex: "seller",
    key: "seller",
    render: (value, record) => (
      <span className="sellerCellOrders " style={{ cursor: "pointer" }}>
        <Link
          to={`/sellers/seller-info/${record.seller_id}/${value}`}
          style={{
            color: `${record.topseller ? "#BF00FF" : "#2590EE"}`,
            fontWeight: `${record.topseller && "600"}`,
          }}
        >
          {value}
        </Link>
      </span>
    ),
  },

  {
    title: "BIN",
    dataIndex: "bin",
    key: "bin",
  },
  {
    title: "EXP",
    dataIndex: "exp",
    key: "exp",
    render: (_, record) => <p>{record.exp}</p>,
  },
  {
    title: "CVV",
    dataIndex: "cvv",
    key: "cvv",
    render: (_, record) => (
      <p>
        {record.list.includes("cvv") ? (
          <FaCheck fill="rgb(25, 159, 46)" />
        ) : (
          <FaMinus fill="#d11c1c" />
        )}
      </p>
    ),
  },
  {
    title: "TYPE",
    dataIndex: "Type",
    key: "Type",
    render: (_, record) => (
      <>
        <p>{record.binlist_type}</p>
      </>
    ),
  },
  {
    title: "VENDOR",
    dataIndex: "brand",
    key: "brand",
    render: (_, record) => (
      // <span>
      <CardBrandIcon brand={record.binlist_brand} />
      // </span>
    ),
  },
  {
    title: "LVL",
    dataIndex: "level",
    key: "level",
    render: (_, record) => (
      <>
        <p>{record.binlist_level}</p>
      </>
    ),
  },
  {
    title: "BANK",
    dataIndex: "bank",
    key: "bank",
    width: "13%",
    render: (_, record) => (
      <span style={{ maxWidth: "250px" }}>{record.binlist_bank}</span>
    ),
  },
  {
    title: "COUNTRY",
    dataIndex: "country",
    key: "country",
    render: (value, record) => (
      // <span>
      <CountryFlagIcon
        countryName={record.binlist_country}
        showCountryCode={true}
      />
      // </span>
    ),
  },
  {
    title: "HOLDER",
    dataIndex: "holder",
    key: "holder",
  },
  {
    title: "ADDR",
    dataIndex: "address",
    key: "address",
    render: (_, record) => (
      <p>
        {_ ? <FaCheck fill="rgb(25, 159, 46)" /> : <FaMinus fill="#d11c1c" />}
      </p>
    ),
  },
  {
    title: "STATE",
    dataIndex: "state",
    key: "state",
    render: (value, record) => <span>{value ? value : "-"}</span>,
  },
  {
    title: "CITY",
    dataIndex: "city",
    key: "city",
    render: (value, record) => <span>{value ? value : "-"}</span>,
  },
  {
    title: "ZIP",
    dataIndex: "zip",
    key: "zip",
    render: (_, record) => <span>{record.zip}</span>,
  },
  {
    title: "Info",
    dataIndex: "info",
    key: "info",
    render: (_, record) => (
      <Tooltip placement="top" title={record.list}>
        {/* <Tooltip placement="top" title={generateTooltipItems(record)}> */}
        <p
          className="infoColumn"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "2px",
            textDecoration: "underline",
          }}
        >
          {/* <BsInfoCircle size={"18px"} fill={"rgb(25, 159, 46)"} />  */}
          Info
        </p>
      </Tooltip>
    ),
  },

  {
    title: "REF",
    dataIndex: "ref",
    key: "ref",
    render: (_, record) => (
      <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
        {record.basename_type === "nonref" ? (
          <FaMinus fill="#d11c1c" />
        ) : (
          <FaCheck fill="rgb(25, 159, 46)" />
        )}
      </div>
    ),
  },
  {
    title: "PRICE",
    dataIndex: "price",
    key: "price",
    sorter: (a, b) => a.price - b.price,
    defaultSortOrder: "descend",
    render: (_, record) => (
      <div className="priceColumn">
        <span className="oldPrice">
          {record.old_price && `${record.old_price}$`}
        </span>
        <span className="priceSpan">{record.price}$</span>
      </div>
    ),
  },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",

    render: (value, record) =>
      cart.some((item) => item.id === record.id) ? (
        <div className="byucards_table_actions">
          <Link to={"/cart"} className="goToCart">
            In cart
          </Link>
          <button
            className="removeFromCart_btn"
            onClick={() => deleteFromCart(record.id)}
          >
            {/* <TbShoppingCartX size={"20px"} color={"red"} /> */}
            Remove
          </button>
        </div>
      ) : (
        <div className="byucards_table_actions">
          <button className="addToCart" onClick={() => add(record.id)}>
            {/* <img src="/assets/icons/icon-cart-white.svg" /> */}
            To cart
          </button>
          <button className="fastBuy" onClick={() => fastBuy(record.id)}>
            {/* <img src="/assets/icons/icon-lightning.svg" /> */}
            Fast buy
          </button>
        </div>
      ),
  },
];

export default buyCardsColumns;
