import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import CustomTable from "../components/CustomTable/CustomTable";
import "../styles/pages/CartPage.scss";
import cartColumns from "../components/CustomTable/cartColumns";
import CartSummary from "../components/CartSummary";
import useCartStore from "../stores/cartStore.js";
import useAuthStore from "../stores/userStore.js";
import { TbShoppingCartX } from "react-icons/tb";
import BuyPopup from "../components/BuyPopup";
import VoucherPopup from "../components/VoucherPopup";
import useBasesFiltersStore from "../stores/basesFiltersStore.js";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { notification } from "antd";

function CartPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setFilters: setBasesFilters } = useBasesFiltersStore();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { key } = useAuthStore((state) => ({ key: state.key }));
  const { cart, removeFromCart } = useCartStore();

  const [isVoucherPopupOpen, setIsVoucherPopupOpen] = useState(false);
  const [inputVoucher, setInputVoucher] = React.useState("");
  const [voucherValue, setVoucherValue] = React.useState({
    value: "",
    label: "",
  });
  const [inputVoucherId, setInputVoucherId] = React.useState("");
  const [voucherPrice, setvoucherPrice] = React.useState(0);

  const [voucherInfo, setVoucherInfo] = useState({
    id: "",
    value: "",
  });

  const [cartToBuy, setCartToBuy] = useState([]);

  const deleteFromCart = (id) => {
    const itemToDel = cart.findIndex((item) => item.id === id);
    removeFromCart(id);
    cart.splice(itemToDel, 1);
    return cart;
  };
  const clearCart = () => {
    cart.forEach((element) => {
      removeFromCart(element.id);
    });
    // cart = [];
    return cart;
  };

  const subTotal =
    cartToBuy.length !== 0
      ? cartToBuy.reduce((total, element) => total + element.price, 0)
      : cart.reduce((total, element) => total + element.price, 0);
  const amount = cartToBuy.length !== 0 ? cartToBuy.length : cart.length || 0;

  const redirectToBase = (baseName) => {
    setBasesFilters({ bases: [baseName] });
    navigate("/bases");
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (text, errorText) => {
    api["error"]({
      message: text,
      description: errorText,
      placement: "top",
      duration: 5000,
    });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    const selectedItems = newSelectedRowKeys.map((newItem) => {
      return cart.find((item) => item.id === newItem);
    });
    setCartToBuy(selectedItems);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  React.useEffect(() => {
    console.log(cartToBuy);
  }, [cartToBuy]);

  return (
    <div className="cartPage_cont">
      {contextHolder}
      <div className="cart_header">
        <h1>{t("Shopping cart")}</h1>
        <button onClick={() => clearCart()}>
          <TbShoppingCartX size={"20px"} color={"red"} />
          {t("Clear cart")}
        </button>
      </div>

      <CustomTable
        rowSelection={rowSelection}
        columns={cartColumns(deleteFromCart, redirectToBase)}
        data={cart}
      />
      {cart.length > 0 && (
        <CartSummary
          voucherInfo={voucherInfo}
          voucherValue={voucherValue}
          voucherPrice={voucherPrice}
          inputVoucher={inputVoucher}
          cart={cartToBuy.length !== 0 ? cartToBuy : cart}
          amount={amount}
          subTotal={subTotal}
          lvlDisc={0}
          openNotification={openNotification}
          refDiscount={0}
          setIsVoucherPopupOpen={setIsVoucherPopupOpen}
        />
      )}
      {/* <BuyPopup />
      <VoucherPopup
        voucherInfo={voucherInfo}
        setVoucherInfo={setVoucherInfo}
        isOpened={isVoucherPopupOpen}
        setIsOpened={setIsVoucherPopupOpen}
        inputVoucher={inputVoucher}
        setInputVoucher={setInputVoucher}
        voucherValue={voucherValue}
        setVoucherValue={setVoucherValue}
        inputVoucherId={inputVoucherId}
        setInputVoucherId={setInputVoucherId}
        voucherPrice={voucherPrice}
        setvoucherPrice={setvoucherPrice}
      /> */}
    </div>
  );
}

export default CartPage;
