import React, { useState } from "react";
import CustomPopup from "./CustomPopup.jsx";
import { useForm } from "react-hook-form";
import "../styles/components/ChangePasswordPopup.scss";
import CustomBlueButton from "./CustomBlueButton.jsx";
import { editBase } from "../utils/baseInfoPageUtils.js";
import useAuthStore from "../stores/userStore.js";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

function ChangeSalePopup({ balancePopup, addBalance, id }) {
  const [afterMessage, setafterMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [isError, setIsError] = useState(false);
  const { key } = useAuthStore((state) => ({ key: state.key }));
  const useQueryClientProfile = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (formData) => {
    console.log(formData);
    const response = await editBase(key, id, formData);
    if (response.ok === true) {
      reset();
      setafterMessage("Replenish balance successfully");
      setShowMessage(true);
      // profile
      useQueryClientProfile.invalidateQueries(["baseinfo"]);

      setTimeout(() => {
        setShowMessage(false);
        setafterMessage("");
        addBalance(false);
      }, 5000);
    } else {
      setIsError(true);
      setafterMessage(response.error);
      reset();
    }
  };

  return (
    <CustomPopup
      label={"Replenish Sale"}
      isOpened={balancePopup}
      setIsOpened={addBalance}
    >
      {showMessage === false ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={
              errors.old ? "password_cont errorInp_cont" : "password_cont"
            }
          >
            <div className="password_cont-leftSide">
              <img src="/assets/icons/icon-wallet-green.svg" />
              <span style={{ color: "rgba(25, 159, 46, 1)" }}>Sale</span>
            </div>
            <input
              {...register("sale", {
                required: "This field is required",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Only numbers are allowed",
                },
              })}
            />
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "20px" }}
          >
            <CustomBlueButton
              color={"lightBlue"}
              width={"125px"}
              height={"40px"}
              borderRadius={"10px"}
              onClick={() => {
                addBalance(false);
              }}
              fontSize={"15px"}
              label={"Cansel"}
            />
            <CustomBlueButton
              color={"blue"}
              width={"125px"}
              height={"40px"}
              borderRadius={"10px"}
              type="submit"
              fontSize={"15px"}
              label={"Change"}
            />
          </div>
          <p className="errorChange">{isError ? afterMessage : ""}</p>
        </form>
      ) : (
        <p className="succsessfullChange">{afterMessage}</p>
      )}
    </CustomPopup>
  );
}

export default ChangeSalePopup;
