import axios from "axios";
import { API_URL } from "../apiConf";

export const cartCheck = async (cart, key) => {
  // const [key, cart] = queryKey;

  let url = `${API_URL}/api/card_cart/?key=${key}`;
  cart.forEach((id) => {
    url += `&ids=${id}`;
  });

  const response = await axios.get(url, null);

  return response.data;
};
export const buy = async (ids, voucher, key) => {
  // const [key, cart] = queryKey;

  let url = `${API_URL}/api/buy/?key=${key}&vouch=${voucher}`;
  ids.forEach((id) => {
    url += `&ids=${id}`;
  });

  const response = await axios.get(url, null);

  return response.data;
};
export const checkVoucher = async (voucher, key) => {
  // const [key, cart] = queryKey;

  let url = `${API_URL}/api/promoinfo/?key=${key}&vouch=${voucher}`;
  const response = await axios.get(url, null);

  return response.data;
};
