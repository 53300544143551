import "../styles/pages/CardCheckPage.scss";
// import "../styles/pages/ToolsPage.scss";
import React from "react";
import toolsColumns from "../components/CustomTable/toolsColumns";
import CheckCardForm from "../components/CheckCardForm";
import useAuthStore from "../stores/userStore.js";
import CustomTable from "../components/CustomTable/CustomTable.jsx";
import { getCheckTable } from "../utils/toolsUtils";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { notification } from "antd";

function CardCheckPage() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { key } = useAuthStore((state) => ({ key: state.key }));
  const { data: checkTableData, isLoading } = useQuery({
    queryKey: ["tableCheck", key],
    queryFn: getCheckTable,
  });
  const reversedData = Array.isArray(checkTableData?.data)
    ? [...checkTableData.data].reverse()
    : [];

  const updateTable = () => {
    queryClient.invalidateQueries({ queryKey: ["tableCheck", key] });
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (text, errorText) => {
    api["error"]({
      message: text,
      description: errorText,
      placement: "top",
      duration: 5000,
    });
  };

  return (
    <div className="cardCheck_page">
      {contextHolder}
      <h1>{t("Card Checker")}</h1>
      <CheckCardForm
        updateTable={updateTable}
        price={checkTableData?.price}
        openNotification={openNotification}
      />
      <div className="checkTable_header">
        {/* <p>Check Table</p> */}
        <button
          type="button"
          className="refetchBtn"
          onClick={() => updateTable()}
        >
          <img src="/assets/icons/icon-refresh.svg" alt="" />
          {t("Update table")}
        </button>
      </div>
      <CustomTable
        columns={toolsColumns}
        data={reversedData}
        // pageSize={5}
        // pagination={false}
      />
    </div>
  );
}

export default CardCheckPage;
