import axios from "axios";
import {
  API_URL
} from "../apiConf";
export const fetchCardsList = async ({
  queryKey
}) => {
  const [key] = queryKey;
  const {
    data
  } = await axios.get(`${API_URL}/api/card_list/?key=${key}`);
  return data;
};

// function to get options from data
export const createUniqueOptions = (array, key) => {
  const uniqueItems = new Set();
  const options = [];

  array?.forEach((item) => {
    const value = item[key];
    if (value && !uniqueItems.has(value)) {
      uniqueItems.add(value);
      options.push({
        value,
        label: value,
      });
    }
  });

  return options;
};

export const filterData = (data, filters) => {
  let filteredData = data;
  // console.log('filteredData', filteredData)

  if (filters.bins) {
    const binsArray = filters.bins.match(/\d{6}/g) || [];
    if (binsArray.length > 0) {
      filteredData = filteredData.filter((card) =>
        binsArray.some(bin => card.bin?.includes(bin))
      );
    }
  }

  if (filters.zips) {
    // Заменяем все двойные пробелы и переносы строк на один пробел
    const cleanedZips = filters.zips.replace(/\s{2,}|\n/g, ' ');

    // Разделяем строку на массив значений по пробелам
    const zipsArray = cleanedZips.trim().split(' ');

    if (zipsArray.length > 0) {
      filteredData = filteredData.filter((card) =>
        zipsArray.some(zip => card.zip?.includes(zip))
      );
    }
  }


  if (filters.banks.length > 0) {
    filteredData = filteredData.filter((card) =>
      filters.banks.includes(card.binlist_bank)
    );
  }
  if (filters.types.length > 0) {
    filteredData = filteredData.filter((card) =>
      filters.types.includes(card.binlist_type)
    );
  }
  if (filters.countries.length > 0) {
    filteredData = filteredData.filter((card) =>
      filters.countries.includes(card.binlist_country)
    );
  }
  if (filters.states.length > 0) {
    filteredData = filteredData.filter((card) =>
      filters.states.includes(card.state)
    );
  }
  if (filters.sellers.length > 0) {
    filteredData = filteredData.filter((card) =>
      filters.sellers.includes(card.seller)
    );
  }
  if (filters.bases.length > 0) {
    filteredData = filteredData.filter((card) =>
      filters.bases.includes(card.basename)
    );
  }
  if (filters.vendors.length > 0) {
    filteredData = filteredData.filter((card) =>
      filters.vendors.includes(card.binlist_brand)
    );
  }
  if (filters.levels.length > 0) {
    filteredData = filteredData.filter((card) =>
      filters.levels.includes(card.binlist_level)
    );
  }
  if (filters.cities.length > 0) {
    filteredData = filteredData.filter((card) =>
      filters.cities.includes(card.city)
    );
  }

  if (filters.priceFrom || filters.priceTo) {
    const minPrice = parseFloat(filters.priceFrom);
    const maxPrice = parseFloat(filters.priceTo);

    filteredData = filteredData.filter((card) => {
      const price = parseFloat(card.price);
      return (
        (isNaN(minPrice) || price >= minPrice) &&
        (isNaN(maxPrice) || price <= maxPrice)
      );
    });
  }
  if (filters.baseValidRange) {
    const minValid = filters.baseValidRange[0];
    const maxValid = filters.baseValidRange[1];

    filteredData = filteredData.filter((card) => {
      const valid = parseFloat(card.basename_perc);
      return (
        (isNaN(minValid) || valid >= minValid) &&
        (isNaN(maxValid) || valid <= maxValid)
      );
    });
  }

  if (filters.refund.length > 0) {
    filteredData = filteredData.filter((card) =>
      filters.refund === card.basename_type
    );
  }

  if (filters.cvv == true) {
    filteredData = filteredData.filter((card) => card.list.includes("cvv"));
  } else if (filters.cvv == false) {
    filteredData = filteredData.filter((card) => !card.list.includes("cvv"));
  }



  if (filters.address == true) {
    filteredData = filteredData.filter((card) => card.address === true);
    // filteredData = filteredData.filter((card) => card.list.includes("address"));
  }
  if (filters.snn == true) {
    filteredData = filteredData.filter((card) => card.list.includes("snn"));
  }
  if (filters.email == true) {
    filteredData = filteredData.filter((card) => card.list.includes("email"));
  }
  if (filters.password == true) {
    filteredData = filteredData.filter((card) =>
      card.list.includes("password")
    );
  }
  if (filters.dob == true) {
    filteredData = filteredData.filter((card) => card.list.includes("dob"));
  }
  if (filters.ua == true) {
    filteredData = filteredData.filter((card) => card.list.includes("ua"));
  }
  if (filters.dl == true) {
    filteredData = filteredData.filter((card) => card.list.includes("dl"));
  }
  if (filters.ip == true) {
    filteredData = filteredData.filter((card) => card.list.includes("ip"));
  }
  if (filters.holder == true) {
    filteredData = filteredData.filter((card) => card.list.includes("holder"));
  }
  if (filters.phone == true) {
    filteredData = filteredData.filter((card) => card.list.includes("phone"));
  }
  // if (filters.cvv) {

  //     filteredData = filteredData.filter((card) => card.list.includes('cvv'));
  // }
  // if (filters.refund.length > 0) {
  //     filteredData = filteredData.filter((card) =>
  //         filters.refund.includes(card.refund)
  //     ); }
  console.log(filters)
  return filteredData;
};