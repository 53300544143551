import React from "react";
import CustomPopup from "./CustomPopup";
import "../styles/components/HistoryFullInfoPopup.scss";
import CustomTextarea from "./CustomTextarea";
import CustomBlueButton from "./CustomBlueButton";
import { editPricePreorder } from "../utils/preorderUtils";
import useAuthStore from "../stores/userStore.js";

function ChangePreorderPrice({
  isPopupOpen,
  setIsPopupOpen,
  preorder,
  setIsOpen,
  openNotification,
}) {
  const { key } = useAuthStore((state) => ({ key: state.key }));
  const [newPriceValue, setNewPriceValue] = React.useState("");
  const handleEditPrice = async () => {
    console.log(key, preorder, newPriceValue);
    const res = await editPricePreorder(key, preorder, newPriceValue);
    if (res.ok === false) {
      openNotification("Edit preorder price error", res.error);
    }
  };
  return (
    <CustomPopup
      isOpened={isPopupOpen}
      label={"Change price"}
      setIsOpened={setIsPopupOpen}
    >
      <CustomTextarea
        height={"60px"}
        placeholder={"Enter new price"}
        width={"350px"}
        value={newPriceValue}
        onChange={(e) => setNewPriceValue(e.target.value)}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          marginTop: "10px",
        }}
      >
        <CustomBlueButton
          color={"lightBlue"}
          width={"125px"}
          height={"40px"}
          borderRadius={"10px"}
          onClick={() => {
            // handleEditPrice();
            setIsOpen(false);
          }}
          fontSize={"15px"}
          label={"Cansel"}
        />
        <CustomBlueButton
          color={"blue"}
          width={"125px"}
          height={"40px"}
          borderRadius={"10px"}
          onClick={() => {
            handleEditPrice();
            setIsOpen(false);
          }}
          fontSize={"15px"}
          label={"Change"}
        />
      </div>
    </CustomPopup>
  );
}

export default ChangePreorderPrice;
