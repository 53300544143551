import axios from "axios";
import {
    API_URL
} from "../apiConf";

export const getPayoutsSeller = async ({
    queryKey
}) => {
    const [key] = queryKey;
    const {
        data
    } = await axios.get(`${API_URL}/api/seller/withdrawal/?key=${key}&get_format=1`);
    return data;
};

export const sendNewPayoutSeller = async (newPayout, key) => {
    const {
        data
    } = await axios.get(`${API_URL}/api/seller/withdrawal/?key=${key}&coin=${newPayout.coin}&address=${newPayout.wallet}&amount=${newPayout.sum}`);
    return data;
};

export const fetchGiftCode = async (giftCode, key) => {
    const {
        data
    } = await axios.get(`${API_URL}/api/giftcode/?key=${key}&gift=${giftCode}`);
    return data;
};