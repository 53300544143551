import React, { useState, useEffect, useRef } from "react";
import CustomBlueButton from "../components/CustomBlueButton";
import { sendMessage, closeTicket } from "../services/supportService";
import MessageComponent from "./MessageComponent";
import useAuthStore from "../stores/userStore.js";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

function SupportChat({ chat, id }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [messageText, setMessageText] = useState("");
  // const [chatInfo, setChatInfo] = useState([]);

  const { key, currentRole } = useAuthStore((state) => ({
    key: state.key,
    currentRole: state.currentRole,
  }));
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  async function deleteTicket(id) {
    try {
      const data = await closeTicket(key, id);
      navigate("/support");
    } catch (error) {
      console.error("Failed to fetch chat data:", error);
    }
  }

  const send = async (text, key, id, currentRole) => {
    const response = await sendMessage(text, key, id, currentRole);
    if (response.ok === true) {
      console.log("Response messages:", response.msgs);
      queryClient.invalidateQueries(["getChat"]);
    }

    setMessageText("");
    scrollToBottom();
  };

  return (
    <div className="chat_cont">
      <div className="chat_header">
        <div className="chat_header_info">
          <p>{chat?.chat_info?.title}</p>
          <span className="chat_header_info-category">
            {chat?.chat_info?.category}
          </span>{" "}
          <span>
            {chat?.chat_info?.category === "Order issues" &&
              `(Order: ${chat?.chat_info?.product.order_id}, Card: ${chat?.chat_info?.product.card_number}, Base: ${chat?.chat_info?.product.base_name})`}
          </span>
        </div>
        {chat?.msgs[chat?.msgs.length - 1]?.text !== t("Ticket closed.") && (
          <CustomBlueButton
            color={"blue"}
            // height="30px"
            width={"130px"}
            onClick={() => deleteTicket(id)}
            label={t("Close Ticket")}
            fontSize={"17px"}
            borderRadius={"10px"}
          />
        )}
      </div>
      <div className="chat_window">
        <div className="messages_layout">
          {chat?.msgs.map((message, index) => (
            <MessageComponent
              key={index}
              user={message.user_type}
              text={message.text}
              date={message.senddate}
              isNotSystem={message.is_not_system}
            />
          ))}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <div className="chat_footer">
        <textarea
          type="text"
          placeholder={t("Enter")}
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
        />
        <CustomBlueButton
          color={"blue"}
          height="40px"
          width={"120px"}
          onClick={() => send(messageText, key, id, currentRole)}
          label={t("Send")}
          borderRadius={"10px"}
          fontSize={"16px"}
        />
      </div>
    </div>
  );
}

export default SupportChat;
