import "../styles/components/CustomBlueButton.scss";
import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Spin } from "antd";

function CustomBlueButton({
  fontWeight,
  disabled = false,
  label,
  onClick,
  height,
  width,
  color,
  fontSize,
  borderRadius = 15,
  icon = "",
  type = "button",
  value,
  style,
  isLoading,
}) {
  return (
    <button
      value={value}
      disabled={isLoading ? true : disabled}
      type={type}
      onClick={onClick}
      className={
        color === "lightBlue"
          ? "CustomBlueButton lightBlue"
          : "CustomBlueButton blue"
      }
      style={{
        fontWeight: fontWeight,
        width: width,
        height: height,
        fontSize: fontSize,
        borderRadius: borderRadius,
        style,
      }}
    >
      {isLoading ? (
        <Spin
          indicator={<LoadingOutlined spin />}
          className="loaderInBtn"
          // style={{ color: "white" }}
        />
      ) : icon != "" ? (
        <>
          <img src={icon} style={{ marginRight: "10px" }} />
          {label}
        </>
      ) : (
        <>{label}</>
      )}
      {/* {icon != "" ? <img src={icon} style={{ marginRight: "10px" }} /> : <></>} */}
      {/* {label} */}
    </button>
  );
}

export default CustomBlueButton;
