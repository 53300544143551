import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import useAuthStore from "../stores/userStore.js";
import { fetchSellerBases } from "../utils/basesUtils";
import sellerBasesColumns from "./CustomTable/sellerBasesColumns.jsx";
import CustomTable from "./CustomTable/CustomTable.jsx";

import styles from "./../styles/components/BasesViewSeller.module.scss";
import Loader from "./Loader.jsx";
import MultiSelect from "./MultiSelect.jsx";
import { Divider } from "antd";
import { useTranslation } from "react-i18next";

export const filterItems = [
  {
    value: "all",
    label: "All",
  },
  {
    value: 0,
    label: "New",
    
  },
  {
    value: 1,
    label: "Active",
  },
  {
    value: 2,
    label: "Sold",
  },
  {
    value: 3,
    label: "Verif",
  },
  {
    value: 4,
    label: "On Check",
  },
  {
    value: 5,
    label: "Ban",
  },
  {
    value: 6,
    label: "Bad",
  },
];

function BasesViewSeller() {
  const { t } = useTranslation();
  const { key } = useAuthStore((state) => ({
    key: state.key,
    role: state.role,
  }));
  const { data, isLoading } = useQuery({
    queryKey: [key],
    queryFn: fetchSellerBases,
  });

  const [filterValue, setFilterValue] = React.useState("all");

  if (isLoading) {
    return <Loader />;
  }

  if (data) {
    const newBases =
      filterValue !== "all"
        ? data?.bases_list?.filter((item) => item.status == filterValue)
        : data?.bases_list;

    console.log(filterValue);
    return (
      <div className={styles.page}>
        <h1> {t("Bases")}</h1>

        <MultiSelect
          options={filterItems}
          isMulti={false}
          onChange={(option) => setFilterValue(option)}
        />
        <Divider />
        <CustomTable data={newBases} columns={sellerBasesColumns} />
      </div>
    );
  }
}

export default BasesViewSeller;
