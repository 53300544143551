import "../styles/components/CustomTextarea.scss";

function CustomTextarea({
  label,
  value,
  onChange,
  placeholder,
  width,
  height,
}) {
  return (
    // <div>
    <div className="custom_textarea" style={{ width: width }}>
      {label ? <span className="label_textarea">{label}</span> : ""}

      <textarea
        style={{ height: height }}
        value={value}
        onChange={onChange}
        // rows={rows}
        // cols={cols}
        placeholder={placeholder}
      />
    </div>
    // </div>
  );
}

export default CustomTextarea;
