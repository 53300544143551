import Header from "../Header";
import CustomDrawer from "../Drawer";
import React from "react";
import Header2 from "../Header2";
import Header3 from "../Header/Header";
import SellerHeader from "../Header/SellerHeader";
import useAuthStore from "./../../stores/userStore";

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { currentRole } = useAuthStore((state) => ({
    currentRole: state.currentRole,
  }));

  return (
    <div className="wrap">
      <CustomDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="container">
        {currentRole === "seller" && <SellerHeader setIsOpen={setIsOpen} />}
        {currentRole === "buyer" && <Header3 setIsOpen={setIsOpen} />}

        <div className="content">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
