import {
    create
} from 'zustand';

const useBuyPopupStore = create((set) => ({
    cardsForBuy: [],
    setCardsForBuy: (newCardsForBuy) => set({
        cardsForBuy: newCardsForBuy
    }),
    isOpened: false,
    setIsOpened: () => set((state) => ({
        isOpened: !state.isOpened
    })),
}))


export default useBuyPopupStore;