import { TiPin } from "react-icons/ti";
import styles from "../styles/pages/NewsPage.module.scss";
import { API_URL } from "../apiConf";
import NewItemContent from "../components/NewItemContent";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import useAuthStore from "../stores/userStore.js";

const fetchData = async (queryKey) => {
  const { data } = await axios.get(`${API_URL}/api/news_list/?key=${queryKey}`);
  return data;
};
function OnlyNewsPage() {
  const { t } = useTranslation();
  const { key } = useAuthStore((state) => ({ key: state.key }));

  const { data, isLoading } = useQuery({
    queryKey: ["fetchNews"],
    queryFn: () => fetchData(key),
  });

  return (
    <main className={styles.page}>
      <h1>{t("News")}</h1>
      <div className={styles.container2}>
        {data.news_list.map((item) => (
          <div className={styles.news_item}>
            {item.pin && (
              <div style={{ position: "absolute", top: "2px", right: "2px" }}>
                <TiPin size={25} />
              </div>
            )}

            <h2>{item.title}</h2>
            <NewItemContent text={item.content} />
            <p className={styles.date}>{item.date}</p>
          </div>
        ))}
      </div>
    </main>
  );
}

export default OnlyNewsPage;
