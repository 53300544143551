import React from "react";
import Flag from "react-world-flags";
import { TbShoppingCartX } from "react-icons/tb";
import CountryFlagIcon from "../CountryFlagIcon";
import CardBrandIcon from "../CardBrandIcon";
import { Link } from "react-router-dom";
import LittleStarRating from "../LittleStarRating";
import { Tooltip } from "antd";
import dayjs from "dayjs";
import { FaMinus, FaCheck } from "react-icons/fa";
import { BsInfoCircle } from "react-icons/bs";
import { LuAlertCircle, LuCheckCircle2 } from "react-icons/lu";
import CustomBlueButton from "../CustomBlueButton";

const cartColumns = (deleteFromCart, redirectToBase) => [
  {
    title: "BIN",
    dataIndex: "bin",
    key: "bin",
  },
  {
    title: "EXP",
    dataIndex: "exp",
    key: "exp",
    render: (_, record) => <p>{record.exp}</p>,
  },
  {
    title: "CVV",
    dataIndex: "cvv",
    key: "cvv",
    render: (_, record) => (
      <p>
        {record.list.includes("cvv") ? (
          <FaCheck fill="rgb(25, 159, 46)" />
        ) : (
          <FaMinus fill="#d11c1c" />
        )}
      </p>
    ),
  },
  {
    title: "TYPE",
    dataIndex: "Type",
    key: "Type",
    render: (_, record) => (
      <>
        <p>{record.binlist_type}</p>
      </>
    ),
  },
  {
    title: "VENDOR",
    dataIndex: "brand",
    key: "brand",
    render: (_, record) => (
      // <span>
      <CardBrandIcon brand={record.binlist_brand} />
      // </span>
    ),
  },
  {
    title: "LVL",
    dataIndex: "level",
    key: "level",
    render: (_, record) => (
      <>
        <p>{record.binlist_level}</p>
      </>
    ),
  },
  {
    title: "BANK",
    dataIndex: "bank",
    key: "bank",
    width: "13%",
    render: (_, record) => (
      <span style={{ maxWidth: "250px" }}>{record.binlist_bank}</span>
    ),
  },
  {
    title: "COUNTRY",
    dataIndex: "country",
    key: "country",
    render: (value, record) => (
      // <span>
      <CountryFlagIcon
        countryName={record.binlist_country}
        showCountryCode={true}
      />
      // </span>
    ),
  },
  {
    title: "HOLDER",
    dataIndex: "holder",
    key: "holder",
  },
  {
    title: "ADDR",
    dataIndex: "address",
    key: "address",
    render: (_, record) => (
      <p>
        {_ ? <FaCheck fill="rgb(25, 159, 46)" /> : <FaMinus fill="#d11c1c" />}
      </p>
    ),
  },
  {
    title: "STATE",
    dataIndex: "state",
    key: "state",
    render: (value, record) => <span>{value ? value : "-"}</span>,
  },
  {
    title: "CITY",
    dataIndex: "city",
    key: "city",
    render: (value, record) => <span>{value ? value : "-"}</span>,
  },
  {
    title: "ZIP",
    dataIndex: "zip",
    key: "zip",
    render: (_, record) => <span>{record.zip}</span>,
  },
  {
    title: "Info",
    dataIndex: "info",
    key: "info",
    render: (_, record) => (
      <Tooltip placement="top" title={record.list}>
        {/* <Tooltip placement="top" title={generateTooltipItems(record)}> */}
        <p
          className="infoColumn"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "2px",
            textDecoration: "underline",
          }}
        >
          {/* <BsInfoCircle size={"18px"} fill={"rgb(25, 159, 46)"} />  */}
          Info
        </p>
      </Tooltip>
    ),
  },

  {
    title: "REF",
    dataIndex: "ref",
    key: "ref",
    render: (_, record) => (
      <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
        {record.basename_type === "nonref" ? (
          <FaMinus fill="#d11c1c" />
        ) : (
          <FaCheck fill="rgb(25, 159, 46)" />
        )}
      </div>
    ),
  },

  {
    title: "PRICE",
    dataIndex: "price",
    key: "price",
    sorter: (a, b) => a.price - b.price,
    render: (_, record) => (
      <div className="priceColumn">
        <span className="oldPrice">
          {record.old_price && `${record.old_price}$`}
        </span>
        <span className="priceSpan">{record.price}$</span>
      </div>
    ),
  },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
    width: "5%",
    render: (value, record) => (
      <CustomBlueButton
        label={"Remove"}
        onClick={() => deleteFromCart(record.id)}
        height={"30px"}
        width={"100%"}
        borderRadius={"15px"}
        color="lightBlue"
        fontSize={"15px"}
      />
    ),
  },
];

export default cartColumns;
