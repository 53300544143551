import BasesViewBuyer from "../components/BasesViewBuyer.jsx";
import BasesViewSeller from "../components/BasesViewSeller.jsx";
import useAuthStore from "../stores/userStore.js";

import "../styles/pages/BasesPage.scss";

function BasesPage() {
  const { key, role, currentRole } = useAuthStore((state) => ({
    key: state.key,
    role: state.role,
    currentRole: state.currentRole,
  }));


  switch (currentRole) {
    case "buyer":
      return <BasesViewBuyer key={1} />;
    case "seller":
      return <BasesViewSeller key={2}/>;
    default:
      return <div>role?</div>;
  }
}

export default BasesPage;
