import React from "react";
import { Link } from "react-router-dom";
import CustomBlueButton from "../CustomBlueButton";
const preorderColumns = (
  key,
  onPreorderDelete,
  openChangePricePopup,
  redirectToPreorderPage
) => [
  {
    title: "SELLER",
    dataIndex: "seller",
    key: "seller",
    // render: (value, record) => (
    //   <span className="sellerCellOrders " style={{ cursor: "pointer" }}>
    //     <Link
    //       to={`/sellers/seller-info/${record.seller_id}/${value}`}
    //       style={{ color: "#2590EE" }}
    //     >
    //       {value}
    //     </Link>
    //   </span>
    // ),
  },
  {
    title: "BIN",
    dataIndex: "bin",
    key: "bin",
  },

  {
    title: "YOUR PRICE",
    dataIndex: "bid",
    key: "bid",
  },
  {
    title: "TOP PRICE",
    dataIndex: "top_bid",
    key: "top_bid",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "POSITION",
    dataIndex: "position",
    key: "position",
  },
  {
    title: "DATE",
    dataIndex: "date",
    key: "date",
  },

  {
    title: "",
    align: "right",
    dataIndex: "",
    key: "",
    render: (value, record) => (
      <div className="table_red-action-btns">
        {record.status === "Waiting..." && (
          <CustomBlueButton
            label={"Remove"}
            onClick={() => onPreorderDelete(key, record.order)}
            height={"30px"}
            width={"85px"}
            borderRadius={"15px"}
            color="lightBlue"
            fontSize={"15px"}
          />
        )}
      </div>
    ),
  },
];

export default preorderColumns;
