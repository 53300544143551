import CredItem from "../components/CredItem";
import CustomBlueButton from "../components/CustomBlueButton";
import CustomTable from "../components/CustomTable/CustomTable";
import styles from "../styles/pages/PayoutsPage.module.scss";
import payoutsColumns from "../components/CustomTable/payoutsColumns";
import React, { useState } from "react";
import { API_URL } from "../apiConf";
import axios from "axios";
import useAuthStore from "../stores/userStore.js";
import { useQuery } from "@tanstack/react-query";
import CustomPopup from "../components/CustomPopup";
import QRCode from "qrcode.react";
import GiftCodePopup from "../components/GiftCodePopup.jsx";
import Loader from "../components/Loader.jsx";
import Container from "../components/Container/Container.jsx";
import { useTranslation } from "react-i18next";

const fetchDepositData = async ({ queryKey }) => {
  const key = queryKey[1];
  const { data } = await axios.get(`${API_URL}/api/deposit/?key=${key}`);
  return data;
};

function PayoutsPage() {
  const { t } = useTranslation();
  const { key } = useAuthStore((state) => ({ key: state.key }));
  const { data, isLoading, error } = useQuery({
    queryKey: ["data", key],
    queryFn: fetchDepositData,
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <main className={styles.page}>
      <h1>{t("Deposit")}</h1>
      <Container>
        <div className={styles.qr_items}>
          <div className={styles.qr_block}>
            <QRCode value={data?.btc} />
            <span>
              <span style={{ color: "#199f2e" }}>BTC:</span> {data?.btc}
            </span>
          </div>
          <div className={styles.qr_block}>
            <QRCode value={data?.ltc} />
            <span style={{ textWrap: "wrap" }}>
              <span style={{ color: "#199f2e" }}>LTC:</span> {data?.ltc}
            </span>
          </div>

          <div className={styles.qr_block}>
            <QRCode value={data?.usdttrc20} />
            <span>
              <span style={{ color: "#199f2e" }}>TRC20:</span> {data?.usdttrc20}
            </span>
          </div>
        </div>
      </Container>
      <p style={{ color: "red", marginTop: "30px" }}>
        {data?.min_dep != undefined &&
          `${t("Minimal deposit amount")}: ${data?.min_dep.btc} BTC, ${
            data?.min_dep.ltc
          } LTC, ${data?.min_dep.usdt} USDT. `}
      </p>
      <h1 style={{ marginTop: "20px" }}>{t("History")}</h1>
      <CustomTable columns={payoutsColumns} data={data?.history} />
    </main>
  );

  // return (
  //   <div className="payoutsPage__cont">
  //     <h1>Deposit</h1>
  //     <div className="payouts_creds_cont">
  //       <div className="payouts_creds_cont2">
  //         <CredItem
  //           icon={"/assets/icons/icon-dollar-circle.svg"}
  //           title={"Balance"}
  //           textColor={"#199F2E"}
  //           value={`$${data?.sum_usd ?? "Loading..."}`}
  //         />
  //       </div>
  //       <CustomBlueButton
  //         icon={"/assets/icons/icon-createPayout.svg"}
  //         color={"lightBlue"}
  //         height="83px"
  //         width={"270px"}
  //         onClick={() => setGiftPopupIsOpen(true)}
  //         label={"Gift code"}
  //         fontSize={"17px"}
  //       />
  //       <CustomBlueButton
  //         icon={"/assets/icons/icon-createPayout.svg"}
  //         color={"blue"}
  //         height="83px"
  //         width={"270px"}
  //         onClick={() => setPopupIsOpen(true)}
  //         label={"Create deposit"}
  //         fontSize={"17px"}
  //       />
  //     </div>
  //     <CustomPopup
  //       isOpened={popupIsOpen}
  //       setIsOpened={setPopupIsOpen}
  //       label={"Create Deposit"}
  //     >
  //       <div className="qrcodes">
  //         <div className="qrItem">
  //           <QRCode value={data?.btc} />
  //           <span>
  //             <span style={{ color: "#199f2e" }}>BTC:</span> {data?.btc}
  //           </span>
  //         </div>
  //         <div className="qrItem">
  //           <QRCode value={data?.ltc} />
  //           <span>
  //             <span style={{ color: "#199f2e" }}>LTC:</span> {data?.ltc}
  //           </span>
  //         </div>
  //         <div className="qrItem">
  //           <QRCode value={data?.usdttrc20} />
  //           <span>
  //             <span style={{ color: "#199f2e" }}>USD/TRC20:</span>{" "}
  //             {data?.usdttrc20}
  //           </span>
  //         </div>
  //       </div>
  //       <span style={{ color: "red", marginTop: "10px" }}>
  //         The amount is created after 2 confirmations
  //       </span>
  //     </CustomPopup>
  //     <div style={{ marginTop: "20px" }}>
  //       <CustomTable columns={payoutsColumns} data={data?.history} />
  //     </div>
  //     <GiftCodePopup
  //       isOpened={giftPopupIsOpen}
  //       setIsOpened={setGiftPopupIsOpen}
  //     />
  //   </div>
  // );
}

export default PayoutsPage;
