import React from "react";
import LittleStarRating from "../LittleStarRating";
import { Link } from "react-router-dom";
const sellersPageColumns = (subUnSub) => [
  {
    title: "SELLER",
    dataIndex: "username",
    key: "username",
    render: (value, record) => (
      <Link to={`/sellers/seller-info/${record.id}/${value}`}>{value}</Link>
    ),
  },

  {
    title: "TOTAL",
    dataIndex: "total",
    key: "total",
    align: "center",
    sorter: (a, b) => a.insale - b.insale,
  },
  {
    title: "NEW",
    dataIndex: "new",
    key: "new",
    align: "center",
    sorter: (a, b) => a.insale - b.insale,
  },
  {
    title: "IN SALE",
    dataIndex: "insale",
    key: "insale",
    align: "center",
    sorter: (a, b) => a.insale - b.insale,
  },
  {
    title: "SOLD",
    dataIndex: "sold",
    key: "sold",
    align: "center",
    sorter: (a, b) => a.sold - b.sold,
  },
  {
    title: "VALID",
    dataIndex: "valid",
    key: "valid",
    align: "center",
    sorter: (a, b) => a.valid - b.valid,
    render: (text) => <span className="validSpan_green"> {text} </span>,
  },
  // {
  //   title: "",
  //   dataIndex: "sub",
  //   key: "sub",

  //   render: (value, record) => (
  //     <div className="subs_column">
  //       {value ? (
  //         <img
  //           src="/assets/icons/icon-heart.svg"
  //           onClick={() => subUnSub(record.id)}
  //         />
  //       ) : (
  //         <img
  //           src="/assets/icons/icon-blue-heart.svg"
  //           onClick={() => subUnSub(record.id)}
  //         />
  //       )}
  //       <span style={{ color: value ? "#D11C1C" : "#307BC4" }}>
  //         {record.sub_count}
  //       </span>
  //     </div>
  //   ),
  // },
];

export default sellersPageColumns;
