import styles from "../styles/pages/StatisticPage.module.scss";
import React from "react";
import axios from "axios";
import useAuthStore from "../stores/userStore.js";
import { API_URL } from "../apiConf";
import Container from "../components/Container/Container.jsx";
import { Link } from "react-router-dom";
import BasesCard from "../components/BasesCard.jsx";
import { useQuery } from "@tanstack/react-query";
import NewItemContent from "../components/NewItemContent.jsx";
import { startOfSecond } from "date-fns";
import CustomBlueButton from "../components/CustomBlueButton.jsx";
import { Flex } from "antd";
import CustomTable from "../components/CustomTable/CustomTable.jsx";
import { statisticColumns } from "../components/CustomTable/statisticPageColumns.jsx";
import Loader from "../components/Loader.jsx";
import { useTranslation } from "react-i18next";

const fetchData = async (queryKey) => {
  const { data } = await axios.get(`${API_URL}/api/stat/?key=${queryKey}`);
  return data;
};

export default function StatisticBuyerView() {
  const { t } = useTranslation();
  const [sortValue, setSortValue] = React.useState("alltime");
  const { key } = useAuthStore((state) => ({ key: state.key }));

  const { data, isLoading } = useQuery({
    queryKey: ["fetchStats"],
    queryFn: () => fetchData(key),
  });
  const currentValue = data?.stat[sortValue];

  const handleChange = (e) => {
    setSortValue(e.target.value);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (data) {
    return (
      <>
        <div>
          <h1>{t("Top Bases")}</h1>
          <div className={styles.buttons}>
            <CustomBlueButton
              onClick={handleChange}
              value={"day"}
              label={t("Daily")}
              fontSize={18}
            />
            <CustomBlueButton
              onClick={handleChange}
              value={"week"}
              label={t("Weekly")}
              fontSize={18}
            />
            <CustomBlueButton
              onClick={handleChange}
              value={"month"}
              label={t("Monthly")}
              fontSize={18}
            />
            <CustomBlueButton
              onClick={handleChange}
              value={"alltime"}
              label={t("All time")}
              fontSize={18}
            />
          </div>
        </div>
        <div className={styles.grid} style={{ gap: "10px" }}>
          {currentValue?.top_bases?.map((base) => (
            <BasesCard
              key={base.basename}
              rate={base.rate}
              name={base.basename}
              date={base.upload_date}
              seller={base.seller}
              sellerId={base.sellerId}
              sale={base.sale}
              soldProgress={base.sold_progress}
              validProgress={base.valid}
              price={base.price}
              info={base.info}
              total={base.count_card}
              country={base.country}
              status={base.ref}
            />
          ))}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link className={styles.link} to={"/bases"}>
            {t("View more bases")}
          </Link>
        </div>

        <h1>{t("Top BINs")}</h1>
        <CustomTable columns={statisticColumns} data={currentValue?.top_bins} />

        <h1 style={{ marginTop: "20px" }}> {t("Top Sellers")}</h1>
        <div className={styles.grid}>
          {currentValue.top_sellers?.map((item) => (
            <Container
              key={item.id}
              style={{
                border: "1px solid rgba(37, 144, 238, 1)",
                justifyContent: "center",
              }}
            >
              <p className={styles.name}>{item.username}</p>
              <div className={styles.stat_item}>
                {t("Products")}:{" "}
                <span className={styles.stat_value}>{item.rate}</span>
              </div>

              <div className={styles.stat_item}>
                {t("Total Sold")}:{" "}
                <span className={styles.stat_value}>{item.sold}</span>
              </div>
              <div style={{ marginTop: "20px" }} className={styles.stat_item}>
                {t("Credit Score")}:{" "}
                <span className={styles.stat_value}>{item.total}</span>
              </div>
            </Container>
          ))}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link className={styles.link} to={"/sellers"}>
            {t("View more sellers")}
          </Link>
        </div>
      </>
    );
  }
}
