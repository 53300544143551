import LinearProgress from "@mui/material/LinearProgress";
import "../styles/components/GreySectionRating.scss";
import { Rate } from "antd";
function GreySectionRating({
  stars,
  sumRate1,
  sumRate2,
  sumRate3,
  sumRates,
  customerRates,
}) {
  sumRate1 = sumRate1.toFixed(1);
  sumRate2 = sumRate2.toFixed(1);
  sumRate3 = sumRate3.toFixed(1);
  return (
    <div className="rating_box">
      <div className="top_part">
        <div className="fullScore">
          <h2>{sumRates.toFixed(1)}</h2>
          <span>Full score is 5 stars</span>
        </div>
        <div className="starRatings">
          <div className="stars_item">
            <div className="stars_cont">
              <img src="/assets/icons/icon-star-filled.svg" />
              <img src="/assets/icons/icon-star-filled.svg" />
              <img src="/assets/icons/icon-star-filled.svg" />
              <img src="/assets/icons/icon-star-filled.svg" />
              <img src="/assets/icons/icon-star-filled.svg" />
            </div>
            <LinearProgress
              className="progress "
              variant="determinate"
              value={stars[0]}
            />
            <span>{stars[0]}%</span>
          </div>
          <div className="stars_item">
            <div className="stars_cont">
              <img src="/assets/icons/icon-star-filled.svg" />
              <img src="/assets/icons/icon-star-filled.svg" />
              <img src="/assets/icons/icon-star-filled.svg" />
              <img src="/assets/icons/icon-star-filled.svg" />
            </div>
            <LinearProgress
              className="progress "
              variant="determinate"
              value={stars[1]}
            />
            <span>{stars[1]}%</span>
          </div>
          <div className="stars_item">
            <div className="stars_cont">
              <img src="/assets/icons/icon-star-filled.svg" />
              <img src="/assets/icons/icon-star-filled.svg" />
              <img src="/assets/icons/icon-star-filled.svg" />
            </div>
            <LinearProgress
              className="progress "
              variant="determinate"
              value={stars[2]}
            />
            <span>{stars[2]}%</span>
          </div>
          <div className="stars_item">
            <div className="stars_cont">
              <img src="/assets/icons/icon-star-filled.svg" />
              <img src="/assets/icons/icon-star-filled.svg" />
            </div>
            <LinearProgress
              className="progress"
              variant="determinate"
              value={stars[3]}
            />
            <span>{stars[3]}%</span>
          </div>
          <div className="stars_item">
            <div className="stars_cont">
              <img src="/assets/icons/icon-star-filled.svg" />
            </div>
            <LinearProgress
              className="progress "
              variant="determinate"
              value={stars[4]}
            />
            <span>{stars[4]}%</span>
          </div>
          <span className="customerRatings_span">
            Customer ratings: {customerRates}
          </span>
        </div>
      </div>
      <div className="bot_part">
        <div className="bot_part-item">
          <span>Billing information</span>
          <div className="stars_cont">
            <div style={{ display: "flex", gap: "3px" }}>
              <Rate allowHalf disabled defaultValue={sumRate1} />
            </div>
            <span className="stars_cont_label">{sumRate1}</span>
          </div>
        </div>
        <div className="bot_part-item">
          <span>Price</span>
          <div className="stars_cont">
            <div style={{ display: "flex", gap: "3px" }}>
              <Rate allowHalf disabled defaultValue={sumRate2} />
            </div>
            <span className="stars_cont_label">{sumRate2}</span>
          </div>
        </div>
        <div className="bot_part-item">
          <span>Money balance</span>
          <div className="stars_cont">
            <div style={{ display: "flex", gap: "3px" }}>
              <Rate allowHalf disabled defaultValue={sumRate3} />
            </div>
            <span className="stars_cont_label">{sumRate3}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GreySectionRating;
