import React from "react";
import CustomBlueButton from "../CustomBlueButton";
const invitesColumns = (removeCode) => [
  {
    title: "CODE",
    dataIndex: "code",
    key: "code",
  },

  {
    title: "TYPE",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "ACTIVATIONS",
    dataIndex: "activations",
    key: "activations",
  },
  {
    align: "right",

    title: "ACTION",
    dataIndex: "act",
    key: "act",
    render: (_, record) =>
      record.active ? (
        <div style={{ float: "right" }}>
          <CustomBlueButton
            label={"Remove"}
            color={"lightBlue"}
            height={"25px"}
            onClick={() => removeCode(record.id)}
          />
        </div>
      ) : (
        <span>Removed</span>
      ),
  },
];

export default invitesColumns;
