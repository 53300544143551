import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import CustomBlueButton from "../components/CustomBlueButton";
import CustomTable from "../components/CustomTable/CustomTable";

import useAuthStore from "../stores/userStore.js";
import {
  createTicket,
  fetchArchive,
  fetchChats,
} from "../services/supportService";
import "../styles/pages/SupportPage.scss";

import supportColumns from "../components/CustomTable/supportColumns";
import NewTicketPopup from "../components/NewTicketPopup";
import { useTranslation } from "react-i18next";

function SupportPage() {
  const {t} = useTranslation()
  const queryClient = useQueryClient();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const { key, currentRole } = useAuthStore((state) => ({
    key: state.key,
    currentRole: state.currentRole,
  }));

  const { data, isLoading } = useQuery({
    queryKey: [key, currentRole, "fetchChats"],
    queryFn: fetchChats,
  });

  const archiveChat = async (id) => {
    let res = await fetchArchive(key, currentRole, id);
    if (res.ok) {
      queryClient.invalidateQueries(["fetchChats"]);
    }
  };

  return (
    <div className="suppPage_cont">
      <div className="suppPage_header">
        <h1>{t("Support")}</h1>
        <CustomBlueButton
          color={"blue"}
          width={"115px"}
          onClick={() => setIsPopupOpen(true)}
          label={t("New Ticket")}
          fontSize={"16px"}
        />
      </div>
      <CustomTable data={data?.chats} columns={supportColumns(archiveChat)} />

      {/* popup ticket */}
      <NewTicketPopup
        fetchChats={fetchChats}
        isPopupOpen={isPopupOpen}
        setIsPopupOpen={setIsPopupOpen}
      />
    </div>
  );
}

export default SupportPage;
