import React from "react";
import styles from "./Header.module.scss";
import { Link } from "react-router-dom";
import { Dropdown } from "antd";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useTranslation } from "react-i18next";
import useUserStore from "../../stores/userStore";

export default function HeaderLinkItem(item) {
  const { messages, currentRole, sellerMsg } = useUserStore((state) => ({
    messages: state.messages,
    sellerMsg: state.seller_msg,
    currentRole: state.currentRole,
  }));
  const { t } = useTranslation();

  const dropMenuItems =
    item && item.dropDownItems
      ? item.dropDownItems.map((dropItem, index) => {
          return {
            key: index,
            label: <Link to={dropItem.link}>{t(dropItem.title)}</Link>,
          };
        })
      : [];

  const isMessagesUnread = (title) => {
    switch (currentRole) {
      case "buyer":
        return (
          title === "Support" &&
          messages && <span className={styles.cartAmount}>!</span>
        );
      case "seller":
        return (
          title === "Support" &&
          sellerMsg && <span className={styles.cartAmount}>!</span>
        );
    }
  };

  return (
    <div className={styles.HeaderLinkItem}>
      {dropMenuItems.length > 0 ? (
        <Dropdown menu={{ items: dropMenuItems }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: " 5px",
              color: "#fff",
            }}
          >
            {item.icon}
            <Link to={item.link}>{t(item.title)}</Link>
            <MdKeyboardArrowDown />
          </div>
        </Dropdown>
      ) : (
        <>
          {item.icon}
          <Link to={item.link}>{t(item.title)}</Link>
          {isMessagesUnread(item.title)}
        </>
      )}
    </div>
  );
}
