import {
    create
} from 'zustand';


const useCardsFiltersStore = create((set) => ({
    filters: {
        bins: "",
        banks: [],
        types: [],
        countries: [],
        states: [],
        sellers: [],
        bases: [],
        zips: "",
        vendors: [],
        levels: [],
        cities: [],
        baseValidRange: [0, 100],
        priceFrom: "",
        priceTo: "",
        cvv: undefined,
        refund: [],
        address: false,
        phone: false,
        holder: false,
        snn: false,
        dob: false,
        dl: false,
        email: false,
        ip: false,
        ua: false,
        password: false,
    },
    setFilters: (newFilters) => set((state) => {

        return {
            filters: {
                ...state.filters,
                ...newFilters
            }
        };
    }),

}))

export default useCardsFiltersStore;