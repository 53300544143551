import React from "react";
import RefundTimerButton from "./RefundTimerButton.jsx";
import CustomBlueButton from "./CustomBlueButton.jsx";

const RenderRefColumn = ({ record, open, check, refund, isLoadingBtn }) => {
  if (record.type === "nonref" && record.open === false) {
    return (
      <CustomBlueButton
        height={"30px"}
        width={"100%"}
        borderRadius={"10px"}
        label={"Open"}
        onClick={() => open(record.hid, false)}
        isLoading={isLoadingBtn[`open-${record.hid}`]}
      />
    );
  } else if (record.type === "ref" && record.open === false) {
    if (record.card_info.status === "checking") {
      return (
        <span className="statusHistoryTable">
          Status: {record.card_info.status}
        </span>
      );
    }
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "3px" }}>
        <CustomBlueButton
          isLoading={isLoadingBtn[`open-${record.hid}`]}
          height={"30px"}
          width={"100%"}
          borderRadius={"10px"}
          label={"Open"}
          onClick={() => open(record.hid, true)}
        />
        {record.can_ref && (
          <CustomBlueButton
            isLoading={isLoadingBtn[`refund-${record.hid}`]}
            height={"30px"}
            borderRadius={"10px"}
            label={"Refund"}
            color={"lightBlue"}
            onClick={() => refund(record.hid)}
          />
        )}
      </div>
    );
  } else if (
    record.type === "nonref" &&
    record.card_info.status === "not checked"
  ) {
    return (
      <CustomBlueButton
        height={"30px"}
        width={"100%"}
        borderRadius={"10px"}
        label={"Check"}
        isLoading={isLoadingBtn[`check-${record.hid}`]}
        color={"blue"}
        onClick={() => check(record.hid)}
      />
    );
  } else if (
    record.type === "ref" &&
    record.card_info.status === "not checked"
  ) {
    return (
      <RefundTimerButton
        record={record}
        isLoadingBtn={isLoadingBtn}
        initialTimer={record.timer}
        onClick={() => refund(record.hid)}
        check={() => check(record.hid)}
      />
    );
  } else {
    return (
      <span className="statusHistoryTable">
        Status: {record.card_info.status}
      </span>
    );
  }
};

export default RenderRefColumn;
